import React from "react";
import Button from "../../Button";
import history from "../../../routes/history";
import {useTranslation} from "react-i18next";

export default function TeacherEditionV3() {
    const {t} = useTranslation();

    const handleCreateGo = () => {
        history.push("/teacher/config");
    };

    return (
        <>
            <div className="border-t-2 bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6 border-floral-white-800">
                <div className="max-w-2xl px-4 py-2 mx-auto lg:max-w-7xl md:px-54 md:py-6 lg:px-8 lg:py-8">
                    <div className="flex items-center justify-end space-x-4">
                        <div className="flex items-center justify-end space-x-4">
                            <Button
                                type="button"
                                color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                size={window.innerWidth < 640 ? "base" : "xl"}
                                onClick={handleCreateGo}
                            >
                                <span>{t("Create a group order")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
