import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {useTranslation} from "react-i18next";
import history from "../../../routes/history";
import useGlobal from "../../../state/useGlobal";

export default function WelcomeTeacherScreen({ga4}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        globalActions.initTeacherEdition();
        globalActions.setTeacherState({
            is_teacher: true
        });

        history.push('/')
    }, []);

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
        </AppLayout>
    );
};
