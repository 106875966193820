import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import {useTranslation} from "react-i18next";
import Button from "../../../components/Button";
import FakeCart from "../../../components/FakeCart";
import SideBarMenu from "../../../components/AI/SideBarMenu/SideBarMenu";
import ProductModal from "../../../components/ProductModal";
import useGlobal from "../../../state/useGlobal";
import history from "../../../routes/history";
import toast from "react-hot-toast";
import FakeCartModal from "../../../components/FakeCartModal";
import {Redirect} from "react-router-dom";
import apiCRM from "../../../services/apiCRM";
import classNames from "classnames";
import TeacherEditionV3 from "../../../components/AI/TeacherEdition/TeacherEditionV3";
import CardItem from "../../../components/AI/CardItem/CardItem";
import Waiting from "../../../components/AI/Waiting/Waiting";
import Config from "../../../config";
import GO_IMG from "../../../images/calcuso-ai/group-order.png";
import mixpanel from "mixpanel-browser";

export default function ProductScreen() {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [globalState, globalActions] = useGlobal();
    const {calcusoAI, teacherEdition} = globalState;
    const {uuid, is_analyzed, is_parent_letter, is_not_found_product, group_order_school_code} = calcusoAI;
    const [cartItem, setCartItem] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(-1);
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingISBN, setIsLoadingISBN] = useState(true);
    const fakeItems = new Array(5).fill({});
    const [openEmailModal, setOpenEmailModal] = useState(false);

    useEffect(async () => {
        const calcusoAI = globalState.calcusoAI;
        const calcusoAIShare = globalState.calcusoAIShare;
        mixpanel.track_pageview({
            "page": "product",
            "uuid": calcusoAI.uuid ?? null,
            "refer_code": calcusoAIShare.refer_code ?? null
        })

        if (!uuid) {
            history.push('/');
        }

        if (is_analyzed === false) {
            setIsWaiting(true);
            analyze();
            globalActions.setIsAnalyzed(true);
        }

        if (is_analyzed === true) {
            setIsLoadingISBN(false);
            initProductScreen(calcusoAI);
            setIsLoading(false);
        }
    }, []);

    const analyze = () => {
        let numberRequestProductTag = 0;
        let startTime = Date.now();
        let openedModal = false;

        const analyzeNext = async () => {
            const response = await callAPI();
            const {status, step} = response.data;
            globalActions.setStep(step);

            const document = response.data?.apiData?.documents[0];
            if (document) {
                globalActions.addPreviousUuid(uuid, document.original_url ?? document.document_url);
            }

            const elapsedTime = (Date.now() - startTime);

            if (elapsedTime > Config.maxWaitingTime && openedModal === false) {
                openedModal = true;
                setOpenEmailModal(true);
            }

            if (status === 'pending' || step === 'text_extract') {
                setTimeout(analyzeNext, 10000);
            }

            if (status === 'processing') {
                if (step === 'is_parent_letter_detect') {
                    if (verifyParentLetter(response.data) === false) {
                        globalActions.setCalcusoAI({
                            ...calcusoAI,
                            is_parent_letter: false,
                        });
                        setShowError(true);
                    } else {
                        setTimeout(analyzeNext, 5000);
                    }
                }

                if (step === 'product_tag_detect') {
                    if (numberRequestProductTag === 0) {
                        setItems(fakeItems);
                        setTimeout(analyzeNext, 5000);
                    }

                    if (numberRequestProductTag >= 1) {
                        setIsWaiting(false);
                        setTimeout(analyzeNext, 2000);
                    }

                    numberRequestProductTag = numberRequestProductTag + 1;
                }

                if (step === 'find_isbn_book') {
                    setTimeout(analyzeNext, 2000);
                }
            }

            if (status === 'completed') {
                setIsWaiting(false);
                setIsLoading(false);
                setIsLoadingISBN(false);
                setOpenEmailModal(false);
                openedModal = true;

                let aiData = await globalActions.reStructureCalcusoAI(response.data);
                await initProductScreen(aiData);
                await handleISBN();
            }

            if (status === 'error') {
                openedModal = true;

                let aiData = await globalActions.reStructureCalcusoAI(response.data);
                if (aiData.is_not_found_product === true) {
                    setShowError(true);
                } else {
                    setIsWaiting(false);
                    setIsLoading(false);
                    setIsLoadingISBN(false);
                    setOpenEmailModal(false);

                    await initProductScreen(aiData);
                    await handleISBN();
                }
            }
        };

        analyzeNext();
    };

    const callAPI = async () => {
        return apiCRM.get('/ai/request/' + uuid);
    };

    const verifyParentLetter = (response) => {
        const {apiData} = response;
        const {documents} = apiData;
        let allIsNotParentLetter = true;

        documents.map((document) => {
            if (document.is_parent_letter !== true && document.is_parent_letter !== null) {
                allIsNotParentLetter = false;
            }
        });

        return allIsNotParentLetter;
    };

    const initProductScreen = async (aiData) => {
        const {items} = aiData;
        setItems(null);
        setItems(items);

        if (!is_analyzed) {
            items.forEach((_, index) => {
                let duration = 1000;

                setTimeout(() => {
                    if (index === 0) {
                        duration = 5000;
                    }

                    if (index > 1) {
                        setCurrentCardIndex(items?.length - 1);
                    } else {
                        setCurrentCardIndex(index);
                    }
                }, (index + 1) * duration);
            });
        } else {
            setCurrentCardIndex(items.length - 1);
        }

        initCartItems();
    };

    const initCartItems = () => {
        const {fakeCart} = globalState;
        const {items} = fakeCart;
        setCartItem(items);
    }

    const toggleCardVisibility = (itemId) => {
        const {simpleItems} = calcusoAI;

        simpleItems.map((item) => {
            if (item.item_id === itemId && item?.productIds?.length === 0) {
                globalActions.setIsDisplay(itemId);
            }
        });

        items.map((item) => {
            if (item.item_id === itemId) {
                const cartProduct = cartItem[item?.main_product?.product_id];

                if (cartProduct !== undefined) {
                    globalActions.setIsDisplay(itemId);
                }
            }
        });
    };

    const displayProductCard = (index) => {
        const {simpleItems} = calcusoAI;

        if (simpleItems === undefined) {
            return true;
        }

        return simpleItems[index]?.is_display;
    };

    const handleNextStep = () => {
        const {fakeCart} = globalState;
        const {items} = fakeCart;

        if (Object.keys(items).length === 0) {
            toast.error(t('Please add at least one product to the cart'));
        } else {
            history.push('/cart/');
        }
    }

    const handleBack = () => {
        history.push('/upload/');
    }

    const handleISBN = async () => {
        await getIsbnData();
    }

    const getIsbnData = async () => {
        let numberRequest = 0;

        const getIsbn = async () => {
            const response = await apiCRM.get('/ai/request/' + uuid + '/book');
            let apiData = globalActions.handleIsbnItem(response.data);
            setItems(apiData.items);

            if (globalActions.isCallIsbnApi(response.data) || numberRequest <= 2) {
                numberRequest = numberRequest + 1;
                setTimeout(getIsbn, 2000);
            }
        };

        await getIsbn();
    }

    const handleStillWaitingAnalysis = async () => {
        setOpenEmailModal(false);
    }

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            <div class="flex flex-col h-full">
                <Helmet>
                    <title>{('Calcuso AI')}</title>
                </Helmet>
                <div className="sticky top-0 bg-cal-primary-celadon-green-E5F8F7 z-20">
                    <HeaderAI confirm={true} title={('CALCUSO AI')} subTitle={t('Easy school order')} setOpen={setOpen} isShowSmallMenu={!isWaiting}>
                    </HeaderAI>
                </div>

                <div className={classNames({
                    "absolute inset-x-0 top-1/3 md:inset-y-0": true,
                    "z-50": isWaiting,
                    "transition-opacity duration-500 opacity-0": !isWaiting,
                })}>
                    {showError || is_parent_letter === false ? (
                        <div className="flex justify-center items-center flex-1 h-full">
                            <div className="font-medium text-xl md:text-2xl text-center space-y-4 pb-32 px-6">
                                <div>
                                    😓
                                </div>
                                <div>
                                    {t("We could not recognize any products in your picture.")}
                                </div>
                                <div>
                                    {t("Please make sure you have chosen the right photo.")}
                                </div>
                                <div>
                                    {t("Make sure that you only take a photo of the parents letter and as little background as possible.")}
                                </div>
                                <Button
                                    onClick={handleBack}
                                    type="button"
                                    size={window.innerWidth < 640 ? "base" : "xl"}
                                >
                                    <span>{t("back")}</span>
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Waiting openEmailModal={openEmailModal} setOpenEmailModal={setOpenEmailModal}/>
                    )}
                </div>
                <div>
                    {uuid ? (
                        <div className={classNames({
                            "opacity-0": true,
                            "transition-opacity duration-500 opacity-100": !isWaiting,
                        })}>
                            <div className="flex flex-col h-full min-h-screen">
                                <div class="flex flex-col items-center flex-1 h-full pb-28 md:pt-10 relative">
                                    {items?.length > 0 && (
                                        <>
                                            <div class="font-bold md:text-2xl text-lg md:pb-5 pb-0">
                                                {t('Choose your products')}
                                            </div>
                                            {items.map((item, index) => (
                                                    <CardItem isLoadingISBN={isLoadingISBN} isLoading={isLoading} index={index} item={item} displayProductCard={displayProductCard} toggleCardVisibility={toggleCardVisibility} currentCardIndex={currentCardIndex} cartItem={cartItem}/>
                                                )
                                            )}
                                        </>
                                    )}

                                    {!isWaiting && is_not_found_product && !group_order_school_code && (
                                        <div class="block">
                                            <div class="font-medium text-lg text-center px-6 pt-4">
                                                {t("Couldn't find any products in your parent letter. Please try again or contact us.")}
                                            </div>
                                            <div className="pt-4 flex justify-center">
                                                <Button
                                                    type="button"
                                                    color="mx-auto bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-00B5B1 focus:bg-cal-primary-celadon-green-00B5B1 font-bold text-cal-primary-floral-white-FEFCF6 focus:ring-cal-primary-celadon-green-00B5B1"
                                                    size={window.innerWidth < 640 ? "base" : "xl"}
                                                    onClick={() => history.push('/upload')}
                                                >
                                                    <span>{t("Start again")}</span>
                                                </Button>
                                            </div>
                                        </div>
                                    )}

                                    {!isWaiting && group_order_school_code && (
                                        <div class="block">
                                            <div className="flex flex-col w-full items-center my-5">
                                                <img
                                                    src={GO_IMG}
                                                    alt="Group Order"
                                                    className="flex-shrink-0 w-1/2 h-auto"
                                                />
                                            </div>
                                            <div class="font-medium text-lg text-center px-6 pt-4">
                                                {t("You have uploaded a parent letter from the CALCUSO group order.")}
                                            </div>
                                            <div class="font-medium text-lg text-center px-6 pt-4">
                                                <span className="text-red-600">{t("Schoolcode")}: </span>
                                                {group_order_school_code}
                                            </div>
                                            <div className="pt-4 flex justify-center">
                                                <Button
                                                    type="button"
                                                    color="mx-auto bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-00B5B1 focus:bg-cal-primary-celadon-green-00B5B1 font-bold text-cal-primary-floral-white-FEFCF6 focus:ring-cal-primary-celadon-green-00B5B1"
                                                    size={window.innerWidth < 640 ? "base" : "xl"}
                                                    onClick={() => document.location.href = Config.apiGO + '/#/group-orders/' + group_order_school_code}
                                                >
                                                    <span>{t("Go to group order")}</span>
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                < /div>
                            </div>
                            <div className={classNames({
                                "fixed inset-x-0 bottom-0 opacity-0": true,
                                "transition-opacity duration-300 opacity-100": !isWaiting && items?.length > 0,
                            })}>
                                {teacherEdition?.is_teacher ? (
                                    <TeacherEditionV3/>
                                ) : (
                                    <div className="border-t-2 bg-cal-primary-floral-white-F5F0E1 lg:bg-cal-primary-floral-white-FEFCF6 border-floral-white-800">
                                        <div className="max-w-2xl px-4 py-2 mx-auto lg:max-w-7xl md:px-54 md:py-6 lg:px-8 lg:py-8">
                                            <div className="flex items-center justify-between space-x-4">
                                                <FakeCart showMobile/>
                                                <div className="flex items-center justify-end space-x-4">
                                                    <button
                                                        onClick={handleBack}
                                                        className="font-medium md:text-base text-sm cursor-pointer text-gunmetal-black-500"
                                                    >
                                                        {t("back")}
                                                    </button>
                                                    <Button
                                                        type="button"
                                                        color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                                        size={window.innerWidth < 640 ? "base" : "xl"}
                                                        onClick={handleNextStep}
                                                    >
                                                        <span>{t("next")}</span>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <ProductModal/>
                            <FakeCartModal/>
                            <SideBarMenu open={open} setOpen={setOpen}/>
                        </div>
                    ) : (
                        <Redirect to="/"/>
                    )}
                </div>
            </div>
        </AppLayout>
    );
};
