export const PRODUCT_TYPE_CONFIGURABLE = "configurable";
export const IS_GRAVIUR = "19";
export const IS_NOT_GRAVIUR = "20";
export const ATTRIBUTE_CODE_COLOR = "color";
export const ATTRIBUTE_CODE_SIZE = "a_size_variation";
export const ATTRIBUTE_WARRANTY_DURATION = "a_WarrantyDuration";
export const ATTRIBUTE_CODE_GRAVIUR = "gravur";
export const ATTRIBUTE_CODE_IS_GRAVIUR = "is_graviur";
export const IGNORE_OPTION_WITH_LABEL = ["Mit Gravur"];
// eslint-disable-next-line
export const REGEX_GRAVIUR = /^([abcdefghijklmnopqrstuvwxyz 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜäöüéèëêœàâûùîôç\,\;\.\:\-\_\$\%\&\/\(\)\=\+\*\#\']+)$/;
// eslint-disable-next-line
export const REGEX_GRAVIUR_REPLACE = /([abcdefghijklmnopqrstuvwxyz 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÜäöüéèëêœàâûùîôç\,\;\.\:\-\_\$\%\&\/\(\)\=\+\*\#\']+)/g;
export const REGEX_STREET = /^(?=.*[0-9])(?=.*[a-zA-Z])([\s\S ]+)$/;
export const OPTIONAL_ZIP_COUNTRIES = ["HK", "IE", "MO", "PA", "GB"];
export const LIMIT_CART_CALCULATOR = 2;

export const POSTCODE_REGEX = {
  DZ: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  AS: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  AR: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  AM: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  AU: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  AT: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  AZ: {
    pattern_1: { example: "1234", pattern: "^[0-9]{4}$" },
    pattern_2: { example: "123456", pattern: "^[0-9]{6}$" },
  },
  BD: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  BY: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  BE: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  BA: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  BR: {
    pattern_1: { example: "12345", pattern: "^[0-9]{5}$" },
    pattern_2: {
      example: "12345-678",
      pattern: "^[0-9]{5}\\-[0-9]{3}$",
    },
  },
  BN: {
    pattern_1: { example: "AB1234", pattern: "^[a-zA-z]{2}[0-9]{4}$" },
  },
  BG: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  CA: {
    pattern_1: {
      example: "A1B 2C3",
      pattern:
        "^[a-zA-z]{1}[0-9]{1}[a-zA-z]{1}\\s[0-9]{1}[a-zA-z]{1}[0-9]{1}$",
    },
    pattern_2: {
      example: "A1B2C3",
      pattern: "^[a-zA-z]{1}[0-9]{1}[a-zA-z]{1}[0-9]{1}[a-zA-z]{1}[0-9]{1}$",
    },
  },
  IC: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  CN: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  HR: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  CU: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  CY: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  CZ: {
    pattern_1: { example: "123 45", pattern: "^[0-9]{3}\\s[0-9]{2}$" },
  },
  DK: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  EE: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  FI: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  FR: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  GF: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  GE: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  DE: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  GR: {
    pattern_1: { example: "123 45", pattern: "^[0-9]{3}\\s[0-9]{2}$" },
  },
  GL: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  GP: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  GU: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  GG: {
    pattern_1: {
      example: "AB1 2CD",
      pattern: "^[a-zA-Z]{2}[0-9]{1}\\s[0-9]{1}[a-zA-Z]{2}$",
    },
  },
  HU: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  IS: { pattern_1: { example: "123", pattern: "^[0-9]{3}$" } },
  IN: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  ID: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  IL: { pattern_1: { example: "6687865", pattern: "^[0-9]{7}$" } },
  IT: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  JP: {
    pattern_1: { example: "123-4567", pattern: "^[0-9]{3}-[0-9]{4}$" },
    pattern_2: { example: "1234567", pattern: "^[0-9]{7}$" },
  },
  JE: {
    pattern_1: {
      example: "AB1 2CD",
      pattern: "^[a-zA-Z]{2}[0-9]{1}\\s[0-9]{1}[a-zA-Z]{2}$",
    },
  },
  KZ: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  KE: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  KR: {
    pattern_1: { example: "123-456", pattern: "^[0-9]{3}-[0-9]{3}$" },
  },
  KG: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  LV: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  LI: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  LT: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  LU: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  MK: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  MG: { pattern_1: { example: "123", pattern: "^[0-9]{3}$" } },
  MY: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  MV: {
    pattern_1: { example: "12345", pattern: "^[0-9]{5}$" },
    pattern_2: { example: "1234", pattern: "^[0-9]{4}$" },
  },
  MT: {
    pattern_1: {
      example: "ABC 1234",
      pattern: "^[a-zA-Z]{3}\\s[0-9]{4}$",
    },
    pattern_2: {
      example: "ABC 123",
      pattern: "^[a-zA-Z]{3}\\s[0-9]{3}$",
    },
    pattern_3: {
      example: "ABC 12",
      pattern: "^[a-zA-Z]{3}\\s[0-9]{2}$",
    },
  },
  MH: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  MQ: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  MX: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  MD: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  MC: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  MN: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  MA: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  NL: {
    pattern_1: {
      example: "1234 AB\\/1234AB",
      pattern: "^[1-9][0-9]{3}\\s?([a-zA-Z]{2})?$",
    },
  },
  NO: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  PK: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  PH: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  PL: {
    pattern_1: { example: "12-345", pattern: "^[0-9]{2}-[0-9]{3}$" },
  },
  PT: {
    pattern_1: { example: "1234", pattern: "^[0-9]{4}$" },
    pattern_2: { example: "1234-567", pattern: "^[0-9]{4}-[0-9]{3}$" },
  },
  PR: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  RE: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  RO: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  RU: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  MP: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  CS: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  SG: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  SK: {
    pattern_1: { example: "123 45", pattern: "^[0-9]{3}\\s[0-9]{2}$" },
  },
  SI: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  ZA: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  ES: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  XY: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  SZ: {
    pattern_1: { example: "A123", pattern: "^[a-zA-Z]{1}[0-9]{3}$" },
  },
  SE: {
    pattern_1: { example: "123 45", pattern: "^[0-9]{3}\\s[0-9]{2}$" },
  },
  CH: { pattern_1: { example: "1234", pattern: "^[0-9]{4}$" } },
  TW: {
    pattern_1: { example: "12345", pattern: "^[0-9]{5}$" },
    pattern_2: { example: "123", pattern: "^[0-9]{3}$" },
  },
  TJ: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  TH: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  TR: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  TM: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  UA: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  GB: {
    pattern_1: {
      example: "AB12 3CD",
      pattern: "^[a-zA-Z]{2}[0-9]{2}\\s?[0-9]{1}[a-zA-Z]{2}$",
    },
    pattern_2: {
      example: "A1B 2CD",
      pattern: "^[a-zA-Z]{1}[0-9]{1}[a-zA-Z]{1}\\s?[0-9]{1}[a-zA-Z]{2}$",
    },
    pattern_3: {
      example: "AB1 2CD",
      pattern: "^[a-zA-Z]{2}[0-9]{1}\\s?[0-9]{1}[a-zA-Z]{2}$",
    },
    pattern_4: {
      example: "AB1C 2DF",
      pattern: "^[a-zA-Z]{2}[0-9]{1}[a-zA-Z]{1}\\s?[0-9]{1}[a-zA-Z]{2}$",
    },
    pattern_5: {
      example: "A12 3BC",
      pattern: "^[a-zA-Z]{1}[0-9]{2}\\s?[0-9]{1}[a-zA-Z]{2}$",
    },
    pattern_6: {
      example: "A1 2BC",
      pattern: "^[a-zA-Z]{1}[0-9]{1}\\s?[0-9]{1}[a-zA-Z]{2}$",
    },
  },
  US: {
    pattern_1: {
      example: "12345-6789",
      pattern: "^[0-9]{5}\\-[0-9]{4}$",
    },
    pattern_2: { example: "12345", pattern: "^[0-9]{5}$" },
  },
  UY: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
  UZ: { pattern_1: { example: "123456", pattern: "^[0-9]{6}$" } },
  VI: { pattern_1: { example: "12345", pattern: "^[0-9]{5}$" } },
};

export const COUNTRIES = [
  { key: "AF", value: "Afghanistan" },
  { key: "AL", value: "Albanien" },
  { key: "DZ", value: "Algerien" },
  { key: "AS", value: "Amerikanisch-Samoa" },
  { key: "VI", value: "Amerikanische Jungferninseln" },
  { key: "UM", value: "Amerikanische Überseeinseln" },
  { key: "AD", value: "Andorra" },
  { key: "AO", value: "Angola" },
  { key: "AI", value: "Anguilla" },
  { key: "AQ", value: "Antarktis" },
  { key: "AG", value: "Antigua und Barbuda" },
  { key: "AR", value: "Argentinien" },
  { key: "AM", value: "Armenien" },
  { key: "AW", value: "Aruba" },
  { key: "AZ", value: "Aserbaidschan" },
  { key: "AU", value: "Australien" },
  { key: "BS", value: "Bahamas" },
  { key: "BH", value: "Bahrain" },
  { key: "BD", value: "Bangladesch" },
  { key: "BB", value: "Barbados" },
  { key: "BY", value: "Belarus" },
  { key: "BE", value: "Belgien" },
  { key: "BZ", value: "Belize" },
  { key: "BJ", value: "Benin" },
  { key: "BM", value: "Bermuda" },
  { key: "BT", value: "Bhutan" },
  { key: "BO", value: "Bolivien" },
  { key: "BA", value: "Bosnien und Herzegowina" },
  { key: "BW", value: "Botsuana" },
  { key: "BV", value: "Bouvetinsel" },
  { key: "BR", value: "Brasilien" },
  { key: "VG", value: "Britische Jungferninseln" },
  { key: "IO", value: "Britisches Territorium im " },
  { key: "BN", value: "Brunei Darussalam" },
  { key: "BG", value: "Bulgarien" },
  { key: "BF", value: "Burkina Faso" },
  { key: "BI", value: "Burundi" },
  { key: "CV", value: "Cabo Verde" },
  { key: "CL", value: "Chile" },
  { key: "CN", value: "China" },
  { key: "CK", value: "Cookinseln" },
  { key: "CR", value: "Costa Rica" },
  { key: "CI", value: "Côte d’Ivoire" },
  { key: "DE", value: "Deutschland" },
  { key: "DM", value: "Dominica" },
  { key: "DO", value: "Dominikanische Republik" },
  { key: "DJ", value: "Dschibuti" },
  { key: "DK", value: "Dänemark" },
  { key: "EC", value: "Ecuador" },
  { key: "SV", value: "El Salvador" },
  { key: "ER", value: "Eritrea" },
  { key: "EE", value: "Estland" },
  { key: "FK", value: "Falklandinseln" },
  { key: "FJ", value: "Fidschi" },
  { key: "FI", value: "Finnland" },
  { key: "FR", value: "France" },
  { key: "GF", value: "Französisch-Guayana" },
  { key: "PF", value: "Französisch-Polynesien" },
  { key: "TF", value: "Französische Süd- " },
  { key: "FO", value: "Färöer" },
  { key: "GA", value: "Gabun" },
  { key: "GM", value: "Gambia" },
  { key: "GE", value: "Georgien" },
  { key: "GH", value: "Ghana" },
  { key: "GI", value: "Gibraltar" },
  { key: "GD", value: "Grenada" },
  { key: "GR", value: "Griechenland" },
  { key: "GL", value: "Grönland" },
  { key: "GP", value: "Guadeloupe" },
  { key: "GU", value: "Guam" },
  { key: "GT", value: "Guatemala" },
  { key: "GG", value: "Guernsey" },
  { key: "GN", value: "Guinea" },
  { key: "GW", value: "Guinea-Bissau" },
  { key: "GY", value: "Guyana" },
  { key: "HT", value: "Haiti" },
  { key: "HM", value: "Heard und McDonaldinseln" },
  { key: "HN", value: "Honduras" },
  { key: "IN", value: "Indien" },
  { key: "ID", value: "Indonesien" },
  { key: "IQ", value: "Irak" },
  { key: "IR", value: "Iran" },
  { key: "IE", value: "Irland" },
  { key: "IS", value: "Island" },
  { key: "IM", value: "Isle of Man" },
  { key: "IL", value: "Israel" },
  { key: "IT", value: "Italien" },
  { key: "JM", value: "Jamaika" },
  { key: "JP", value: "Japan" },
  { key: "YE", value: "Jemen" },
  { key: "JE", value: "Jersey" },
  { key: "JO", value: "Jordanien" },
  { key: "KY", value: "Kaimaninseln" },
  { key: "KH", value: "Kambodscha" },
  { key: "CM", value: "Kamerun" },
  { key: "CA", value: "Kanada" },
  { key: "KZ", value: "Kasachstan" },
  { key: "QA", value: "Katar" },
  { key: "KE", value: "Kenia" },
  { key: "KG", value: "Kirgisistan" },
  { key: "KI", value: "Kiribati" },
  { key: "CC", value: "Kokosinseln" },
  { key: "CO", value: "Kolumbien" },
  { key: "KM", value: "Komoren" },
  { key: "CG", value: "Kongo-Brazzaville" },
  { key: "CD", value: "Kongo-Kinshasa" },
  { key: "HR", value: "Kroatien" },
  { key: "CU", value: "Kuba" },
  { key: "KW", value: "Kuwait" },
  { key: "LA", value: "Laos" },
  { key: "LS", value: "Lesotho" },
  { key: "LV", value: "Lettland" },
  { key: "LB", value: "Libanon" },
  { key: "LR", value: "Liberia" },
  { key: "LY", value: "Libyen" },
  { key: "LI", value: "Liechtenstein" },
  { key: "LT", value: "Litauen" },
  { key: "LU", value: "Luxemburg" },
  { key: "MG", value: "Madagaskar" },
  { key: "MW", value: "Malawi" },
  { key: "MY", value: "Malaysia" },
  { key: "MV", value: "Malediven" },
  { key: "ML", value: "Mali" },
  { key: "MT", value: "Malta" },
  { key: "MA", value: "Marokko" },
  { key: "MH", value: "Marshallinseln" },
  { key: "MQ", value: "Martinique" },
  { key: "MR", value: "Mauretanien" },
  { key: "MU", value: "Mauritius" },
  { key: "YT", value: "Mayotte" },
  { key: "MK", value: "Mazedonien" },
  { key: "MX", value: "Mexiko" },
  { key: "FM", value: "Mikronesien" },
  { key: "MC", value: "Monaco" },
  { key: "MN", value: "Mongolei" },
  { key: "ME", value: "Montenegro" },
  { key: "MS", value: "Montserrat" },
  { key: "MZ", value: "Mosambik" },
  { key: "MM", value: "Myanmar" },
  { key: "NA", value: "Namibia" },
  { key: "NR", value: "Nauru" },
  { key: "NP", value: "Nepal" },
  { key: "NC", value: "Neukaledonien" },
  { key: "NZ", value: "Neuseeland" },
  { key: "NI", value: "Nicaragua" },
  { key: "NL", value: "Niederlande" },
  { key: "NE", value: "Niger" },
  { key: "NG", value: "Nigeria" },
  { key: "NU", value: "Niue" },
  { key: "KP", value: "Nordkorea" },
  { key: "NF", value: "Norfolkinsel" },
  { key: "NO", value: "Norwegen" },
  { key: "MP", value: "Nördliche Marianen" },
  { key: "OM", value: "Oman" },
  { key: "PK", value: "Pakistan" },
  { key: "PW", value: "Palau" },
  { key: "PS", value: "Palästinensische Autonomiegebiete" },
  { key: "PA", value: "Panama" },
  { key: "PG", value: "Papua-Neuguinea" },
  { key: "PY", value: "Paraguay" },
  { key: "PE", value: "Peru" },
  { key: "PH", value: "Philippinen" },
  { key: "PN", value: "Pitcairninseln" },
  { key: "PL", value: "Polen" },
  { key: "PT", value: "Portugal" },
  { key: "PR", value: "Puerto Rico" },
  { key: "MD", value: "Republik Moldau" },
  { key: "RW", value: "Ruanda" },
  { key: "RO", value: "Rumänien" },
  { key: "RU", value: "Russland" },
  { key: "RE", value: "Réunion" },
  { key: "SB", value: "Salomonen" },
  { key: "ZM", value: "Sambia" },
  { key: "WS", value: "Samoa" },
  { key: "SM", value: "San Marino" },
  { key: "SA", value: "Saudi-Arabien" },
  { key: "SE", value: "Schweden" },
  { key: "CH", value: "Schweiz" },
  { key: "SN", value: "Senegal" },
  { key: "RS", value: "Serbien" },
  { key: "SC", value: "Seychellen" },
  { key: "SL", value: "Sierra Leone" },
  { key: "ZW", value: "Simbabwe" },
  { key: "SG", value: "Singapur" },
  { key: "SK", value: "Slowakei" },
  { key: "SI", value: "Slowenien" },
  { key: "SO", value: "Somalia" },
  { key: "HK", value: "Sonderverwaltungsregion Hongkong" },
  { key: "MO", value: "Sonderverwaltungsregion Macau" },
  { key: "ES", value: "Spanien" },
  { key: "SJ", value: "Spitzbergen und Jan Mayen" },
  { key: "LK", value: "Sri Lanka" },
  { key: "BL", value: "St. Barthélemy" },
  { key: "SH", value: "St. Helena" },
  { key: "KN", value: "St. Kitts und Nevis" },
  { key: "LC", value: "St. Lucia" },
  { key: "MF", value: "St. Martin" },
  { key: "PM", value: "St. Pierre und Miquelon" },
  { key: "VC", value: "St. Vincent und die Grenadinen" },
  { key: "SD", value: "Sudan" },
  { key: "SR", value: "Suriname" },
  { key: "SZ", value: "Swasiland" },
  { key: "SY", value: "Syrien" },
  { key: "ST", value: "São Tomé und Príncipe" },
  { key: "ZA", value: "Südafrika" },
  { key: "GS", value: "Südgeorgien und " },
  { key: "KR", value: "Südkorea" },
  { key: "TJ", value: "Tadschikistan" },
  { key: "TW", value: "Taiwan" },
  { key: "TZ", value: "Tansania" },
  { key: "TH", value: "Thailand" },
  { key: "TL", value: "Timor-Leste" },
  { key: "TG", value: "Togo" },
  { key: "TK", value: "Tokelau" },
  { key: "TO", value: "Tonga" },
  { key: "TT", value: "Trinidad und Tobago" },
  { key: "TD", value: "Tschad" },
  { key: "CZ", value: "Tschechien" },
  { key: "TN", value: "Tunesien" },
  { key: "TM", value: "Turkmenistan" },
  { key: "TC", value: "Turks- und Caicosinseln" },
  { key: "TV", value: "Tuvalu" },
  { key: "TR", value: "Türkei" },
  { key: "UG", value: "Uganda" },
  { key: "UA", value: "Ukraine" },
  { key: "HU", value: "Ungarn" },
  { key: "UY", value: "Uruguay" },
  { key: "UZ", value: "Usbekistan" },
  { key: "VU", value: "Vanuatu" },
  { key: "VA", value: "Vatikanstadt" },
  { key: "VE", value: "Venezuela" },
  { key: "AE", value: "Vereinigte Arabische Emirate" },
  { key: "US", value: "Vereinigte Staaten" },
  { key: "GB", value: "Vereinigtes Königreich" },
  { key: "VN", value: "Vietnam" },
  { key: "WF", value: "Wallis und Futuna" },
  { key: "CX", value: "Weihnachtsinsel" },
  { key: "EH", value: "Westsahara" },
  { key: "CF", value: "Zentralafrikanische Republik" },
  { key: "CY", value: "Zypern" },
  { key: "EG", value: "Ägypten" },
  { key: "GQ", value: "Äquatorialguinea" },
  { key: "ET", value: "Äthiopien" },
  { key: "AX", value: "Ålandinseln" },
  { key: "AT", value: "Österreich" },
];
export const REGEX_NAME = /^(?:[\p{L}\p{M},\-_'".\s\d]){1,255}$/u;

export const COUNTRIES_NL = [
  { key: "AF", value: "Afghanistan" },
  { key: "AL", value: "Albanië" },
  { key: "DZ", value: "Algerije" },
  { key: "AS", value: "Amerikaans Samoa" },
  { key: "VI", value: "Amerikaanse Maagdeneilanden" },
  { key: "UM", value: "Amerikaanse ondergeschikte afgelegen eilanden" },
  { key: "AD", value: "Andorra" },
  { key: "AO", value: "Angola" },
  { key: "AI", value: "Anguilla" },
  { key: "AQ", value: "Antarctica" },
  { key: "AG", value: "Antigua en Barbuda" },
  { key: "AR", value: "Argentinië" },
  { key: "AM", value: "Armenië" },
  { key: "AW", value: "Aruba" },
  { key: "AZ", value: "Azerbeidzjan" },
  { key: "AU", value: "Australië" },
  { key: "BS", value: "Bahama's" },
  { key: "BH", value: "Bahrein" },
  { key: "BD", value: "Bangladesh" },
  { key: "BB", value: "Barbados" },
  { key: "BY", value: "Wit-Rusland" },
  { key: "BE", value: "België" },
  { key: "BZ", value: "Belize" },
  { key: "BJ", value: "Benin" },
  { key: "BM", value: "Bermuda" },
  { key: "BT", value: "Bhutan" },
  { key: "BO", value: "Bolivia" },
  { key: "BA", value: "Bosnië en Herzegovina" },
  { key: "BW", value: "Botswana" },
  { key: "BV", value: "Bouvet Eiland" },
  { key: "BR", value: "Brazilië" },
  { key: "VG", value: "Britse Maagdeneilanden" },
  { key: "IO", value: "Brits Indische Oceaanterritorium" },
  { key: "BN", value: "Brunei" },
  { key: "BG", value: "Bulgarije" },
  { key: "BF", value: "Burkina Faso" },
  { key: "BI", value: "Burundi" },
  { key: "CV", value: "Kaapverdië" },
  { key: "CL", value: "Chili" },
  { key: "CN", value: "China" },
  { key: "CK", value: "Cookeilanden" },
  { key: "CR", value: "Costa Rica" },
  { key: "CI", value: "Ivoorkust" },
  { key: "DE", value: "Duitsland" },
  { key: "DM", value: "Dominica" },
  { key: "DO", value: "Dominicaanse Republiek" },
  { key: "DJ", value: "Djibouti" },
  { key: "DK", value: "Denemarken" },
  { key: "EC", value: "Ecuador" },
  { key: "SV", value: "El Salvador" },
  { key: "ER", value: "Eritrea" },
  { key: "EE", value: "Estland" },
  { key: "FK", value: "Falklandeilanden" },
  { key: "FJ", value: "Fiji" },
  { key: "FI", value: "Finland" },
  { key: "FR", value: "Frankrijk" },
  { key: "GF", value: "Frans-Guyana" },
  { key: "PF", value: "Frans-Polynesië" },
  { key: "TF", value: "Franse Zuidelijke Gebieden" },
  { key: "FO", value: "Faeröer" },
  { key: "GA", value: "Gabon" },
  { key: "GM", value: "Gambia" },
  { key: "GE", value: "Georgië" },
  { key: "GH", value: "Ghana" },
  { key: "GI", value: "Gibraltar" },
  { key: "GD", value: "Grenada" },
  { key: "GR", value: "Griekenland" },
  { key: "GL", value: "Groenland" },
  { key: "GP", value: "Guadeloupe" },
  { key: "GU", value: "Guam" },
  { key: "GT", value: "Guatemala" },
  { key: "GG", value: "Guernsey" },
  { key: "GN", value: "Guinee" },
  { key: "GW", value: "Guinee-Bissau" },
  { key: "GY", value: "Guyana" },
  { key: "HT", value: "Haïti" },
  { key: "HM", value: "Heard en McDonaldeilanden" },
  { key: "HN", value: "Honduras" },
  { key: "IN", value: "India" },
  { key: "ID", value: "Indonesië" },
  { key: "IQ", value: "Irak" },
  { key: "IR", value: "Iran" },
  { key: "IE", value: "Ierland" },
  { key: "IS", value: "IJsland" },
  { key: "IM", value: "Isle of Man" },
  { key: "IL", value: "Israël" },
  { key: "IT", value: "Italië" },
  { key: "JM", value: "Jamaica" },
  { key: "JP", value: "Japan" },
  { key: "YE", value: "Jemen" },
  { key: "JE", value: "Jersey" },
  { key: "JO", value: "Jordanië" },
  { key: "KY", value: "Kaaimaneilanden" },
  { key: "KH", value: "Cambodja" },
  { key: "CM", value: "Kameroen" },
  { key: "CA", value: "Canada" },
  { key: "KZ", value: "Kazachstan" },
  { key: "QA", value: "Qatar" },
  { key: "KE", value: "Kenia" },
  { key: "KG", value: "Kirgizië" },
  { key: "KI", value: "Kiribati" },
  { key: "CC", value: "Cocoseilanden" },
  { key: "CO", value: "Colombia" },
  { key: "KM", value: "Comoren" },
  { key: "CG", value: "Congo-Brazzaville" },
  { key: "CD", value: "Congo-Kinshasa" },
  { key: "HR", value: "Kroatië" },
  { key: "CU", value: "Cuba" },
  { key: "KW", value: "Koeweit" },
  { key: "LA", value: "Laos" },
  { key: "LS", value: "Lesotho" },
  { key: "LV", value: "Letland" },
  { key: "LB", value: "Libanon" },
  { key: "LR", value: "Liberia" },
  { key: "LY", value: "Libië" },
  { key: "LI", value: "Liechtenstein" },
  { key: "LT", value: "Litouwen" },
  { key: "LU", value: "Luxemburg" },
  { key: "MG", value: "Madagaskar" },
  { key: "MW", value: "Malawi" },
  { key: "MY", value: "Maleisië" },
  { key: "MV", value: "Maldiven" },
  { key: "ML", value: "Mali" },
  { key: "MT", value: "Malta" },
  { key: "MA", value: "Marokko" },
  { key: "MH", value: "Marshalleilanden" },
  { key: "MQ", value: "Martinique" },
  { key: "MR", value: "Mauritanië" },
  { key: "MU", value: "Mauritius" },
  { key: "YT", value: "Mayotte" },
  { key: "MK", value: "Noord-Macedonië" },
  { key: "MX", value: "Mexico" },
  { key: "FM", value: "Micronesië" },
  { key: "MC", value: "Monaco" },
  { key: "MN", value: "Mongolië" },
  { key: "ME", value: "Montenegro" },
  { key: "MS", value: "Montserrat" },
  { key: "MZ", value: "Mozambique" },
  { key: "MM", value: "Myanmar (Birma)" },
  { key: "NA", value: "Namibië" },
  { key: "NR", value: "Nauru" },
  { key: "NP", value: "Nepal" },
  { key: "NC", value: "Nieuw-Caledonië" },
  { key: "NZ", value: "Nieuw-Zeeland" },
  { key: "NI", value: "Nicaragua" },
  { key: "NL", value: "Nederland" },
  { key: "NE", value: "Niger" },
  { key: "NG", value: "Nigeria" },
  { key: "NU", value: "Niue" },
  { key: "KP", value: "Noord-Korea" },
  { key: "NF", value: "Norfolkeiland" },
  { key: "NO", value: "Noorwegen" },
  { key: "MP", value: "Noordelijke Marianen" },
  { key: "OM", value: "Oman" },
  { key: "PK", value: "Pakistan" },
  { key: "PW", value: "Palau" },
  { key: "PS", value: "Palestijnse gebieden" },
  { key: "PA", value: "Panama" },
  { key: "PG", value: "Papoea-Nieuw-Guinea" },
  { key: "PY", value: "Paraguay" },
  { key: "PE", value: "Peru" },
  { key: "PH", value: "Filipijnen" },
  { key: "PN", value: "Pitcairneilanden" },
  { key: "PL", value: "Polen" },
  { key: "PT", value: "Portugal" },
  { key: "PR", value: "Puerto Rico" },
  { key: "MD", value: "Moldavië" },
  { key: "RW", value: "Rwanda" },
  { key: "RO", value: "Roemenië" },
  { key: "RU", value: "Rusland" },
  { key: "RE", value: "Réunion" },
  { key: "SB", value: "Salomonseilanden" },
  { key: "ZM", value: "Zambia" },
  { key: "WS", value: "Samoa" },
  { key: "SM", value: "San Marino" },
  { key: "SA", value: "Saoedi-Arabië" },
  { key: "SE", value: "Zweden" },
  { key: "CH", value: "Zwitserland" },
  { key: "SN", value: "Senegal" },
  { key: "RS", value: "Servië" },
  { key: "SC", value: "Seychellen" },
  { key: "SL", value: "Sierra Leone" },
  { key: "ZW", value: "Zimbabwe" },
  { key: "SG", value: "Singapore" },
  { key: "SK", value: "Slowakije" },
  { key: "SI", value: "Slovenië" },
  { key: "SO", value: "Somalië" },
  { key: "HK", value: "Hongkong SAR van China" },
  { key: "MO", value: "Macau SAR van China" },
  { key: "ES", value: "Spanje" },
  { key: "SJ", value: "Svalbard en Jan Mayen" },
  { key: "LK", value: "Sri Lanka" },
  { key: "BL", value: "Saint-Barthélemy" },
  { key: "SH", value: "Sint-Helena" },
  { key: "KN", value: "Saint Kitts en Nevis" },
  { key: "LC", value: "Saint Lucia" },
  { key: "MF", value: "Saint-Martin" },
  { key: "PM", value: "Saint-Pierre en Miquelon" },
  { key: "VC", value: "Saint Vincent en de Grenadines" },
  { key: "SD", value: "Soedan" },
  { key: "SR", value: "Suriname" },
  { key: "SZ", value: "Swaziland" },
  { key: "SY", value: "Syrië" },
  { key: "ST", value: "Sao Tomé en Principe" },
  { key: "ZA", value: "Zuid-Afrika" },
  { key: "GS", value: "Zuid-Georgië en Zuidelijke Sandwicheilanden" },
  { key: "KR", value: "Zuid-Korea" },
  { key: "TJ", value: "Tadzjikistan" },
  { key: "TW", value: "Taiwan" },
  { key: "TZ", value: "Tanzania" },
  { key: "TH", value: "Thailand" },
  { key: "TL", value: "Oost-Timor" },
  { key: "TG", value: "Togo" },
  { key: "TK", value: "Tokelau" },
  { key: "TO", value: "Tonga" },
  { key: "TT", value: "Trinidad en Tobago" },
  { key: "TD", value: "Tsjaad" },
  { key: "CZ", value: "Tsjechië" },
  { key: "TN", value: "Tunesië" },
  { key: "TM", value: "Turkmenistan" },
  { key: "TC", value: "Turks- en Caicoseilanden" },
  { key: "TV", value: "Tuvalu" },
  { key: "TR", value: "Turkije" },
  { key: "UG", value: "Oeganda" },
  { key: "UA", value: "Oekraïne" },
  { key: "HU", value: "Hongarije" },
  { key: "UY", value: "Uruguay" },
  { key: "UZ", value: "Oezbekistan" },
  { key: "VU", value: "Vanuatu" },
  { key: "VA", value: "Vaticaanstad" },
  { key: "VE", value: "Venezuela" },
  { key: "AE", value: "Verenigde Arabische Emiraten" },
  { key: "US", value: "Verenigde Staten" },
  { key: "GB", value: "Verenigd Koninkrijk" },
  { key: "VN", value: "Vietnam" },
  { key: "WF", value: "Wallis en Futuna" },
  { key: "CX", value: "Christmaseiland" },
  { key: "EH", value: "Westelijke Sahara" },
  { key: "CF", value: "Centraal-Afrikaanse Republiek" },
  { key: "CY", value: "Cyprus" },
  { key: "EG", value: "Egypte" },
  { key: "GQ", value: "Equatoriaal-Guinea" },
  { key: "ET", value: "Ethiopië" },
  { key: "AX", value: "Ålandeilanden" },
  { key: "AT", value: "Oostenrijk" },
];
