import React, {useEffect, useRef, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import HeaderAI from "../../../components/AI/Header/Header";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import api from "../../../services/api";
import {CheckCircleIcon} from "@heroicons/react/outline";
import toast from "react-hot-toast";
import SettingGroupOrder from "../../../components/AI/TeacherEdition/SettingGroupOrder";

export default function TeacherScreen() {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {teacherEdition, calcusoAI} = globalState;
    const {school_id, shipping_methods, group_order_data} = teacherEdition;
    const {items, schools} = calcusoAI;
    const [schoolList, setSchoolList] = useState([]);
    const recaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowGo, setIsShowGo] = useState(false);

    useEffect(() => {
        let schoolList = [{
            key: '',
            value: t('Select your school')
        }];

        schools.map((school) => {
            schoolList.push({
                key: school.m2_id,
                value: school.name
            });
        });

        setSchoolList(schoolList);

        if (group_order_data) {
            setIsShowGo(true);
        }
    }, []);

    const handleCreateGO = async () => {
        setIsLoading(true)
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        let productPayload = buildProductPayload();

        try {
            let response = await api.post('/group-orders/ai/creation', {},
                {
                    'g-recaptcha-response': token,
                    'school_id': school_id,
                    'shipping_methods': shipping_methods,
                    'items': productPayload,
                });

            if (response.status === 200) {
                globalActions.setTeacherState({
                    ...teacherEdition,
                    group_order_data: response.data
                });

                setIsShowGo(true);
            }
        } catch (e) {
            console.log(e);
        }

        setIsLoading(false);
    };

    const buildProductPayload = () => {
        let payload = [];
        items.forEach((item) => {
            if (item?.main_product) {
                let alternatives = [];

                if (item?.alternative_products) {
                    item.alternative_products.forEach((alternative) => {
                        alternatives.push(alternative?.product_id)
                    });
                }

                payload.push({
                    main: item?.main_product?.product_id,
                    alternatives: alternatives
                });
            }
        });

        return payload;
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(teacherEdition.group_order_data?.school_code);
        toast.success(t('School code copied'));
    };

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            <div className="sticky top-0 bg-cal-primary-celadon-green-E5F8F7 z-20">
                <HeaderAI confirm={false} title={('CALCUSO AI')} subTitle={t('Easy school order')}>
                </HeaderAI>
            </div>
            {isShowGo ? (
                <div className="flex flex-col items-center justify-center h-full pt-12">
                    <div className="flex flex-col items-center justify-center space-y-2 px-4 md:px-0 md:w-1/3">
                        <CheckCircleIcon className="h-20 w-20 text-celadon-green-600"/>
                        <h2 className="text-2xl font-bold text-center">
                            {t("Your group order has been created.")}
                        </h2>
                        <p className="text-lg text-center">
                            {t("Your CALCUSO group order has been successfully created. You can now share the school code.")}
                        </p>

                        <div class="flex space-x-1 pt-1">
                            <span class="text-xl font-bold">
                                {t('School code')}:
                            </span>
                            <div class="text-xl font-bold cursor-pointer" onClick={copyToClipboard}>
                                {teacherEdition.group_order_data?.school_code}
                            </div>
                        </div>
                        <div class="pt-10">
                            <a href={teacherEdition.group_order_data?.parent_letter_url} target="_blank" className="text-xl px-4 py-2 bg-cal-primary-celadon-green-01A7A3 font-bold text-cal-primary-floral-white-FEFCF6 rounded-md cursor-pointer">
                                {t("Show parent letter")}
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <SettingGroupOrder setIsShowGo={setIsShowGo}/>
            )}
        </AppLayout>
    );
};
