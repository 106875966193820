import useGlobal from "../../../../state/useGlobal";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import api from "../../../../services/api";
import {checkPaymentStatusAdyen, getDefaultConfigAdyen} from "../adyenHelper";
import {HandleAPICartError} from "../../../../helpers";
import AdyenCheckout from "@adyen/adyen-web";
import Button from "../../../../components/Button";
import {HandleSuccessOrder} from "../../../../services/helpers";

export default function KlarnaPayLaterPayment({recaptchaRef}) {
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [adyenKlarnaPaylater, setAdyenKlarnaPaylater] = useState(null);

    const _placeOrder = async () => {
        setLoading(true);
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        const {checkout} = globalState;
        const {quote_id, email, school_info} = checkout;

        try {
            const payload = {
                email: email,
                paymentMethod: {
                    method: "adyen_hpp",
                    additional_data: {
                        stateData: JSON.stringify(adyenKlarnaPaylater.data),
                        brand_code: "klarna",
                        calcuso_ai_request_hash: globalState.calcusoAI.uuid,
                        refer_code: globalState.calcusoAIShare?.refer_code,
                        "g-recaptcha-response": token,
                        school_name: school_info?.school_name,
                        school_zip_code: school_info?.school_zip_code,
                    }
                },
            };

            let response = await api.post(
                `/guest-carts/${quote_id}/payment-information`,
                {},
                payload
            );

            const data = JSON.parse(response.data);

            globalActions.setCalcusoAIShare({
                ...globalState.calcusoAIShare,
                order_id: data?.order_id,
                order_id_hash: data?.order_id_hash,
            });

            let responseStatus = await checkPaymentStatusAdyen(data?.order_id);

            if (responseStatus.isFinal) {
                HandleSuccessOrder(globalActions);
            } else {
                window.location.assign(responseStatus.action.url);
            }

        } catch (error) {
            HandleAPICartError(error, globalState);
        }
    };


    useEffect(() => {
        const createCheckout = async () => {
            let adyenCheckout = await (new AdyenCheckout(getDefaultConfigAdyen(globalState, {
                showPayButton: false,
            })));

            const klarnaComponent = adyenCheckout.create('klarna').mount('#klarna-paylater-container');
            setAdyenKlarnaPaylater(klarnaComponent);
        }
        createCheckout();
    }, []);


    return (
        <div className="pt-4 space-x-8">
            <div id="klarna-paylater-container"></div>
            <div className="flex items-center justify-end space-x-8">
                <Button
                    type="button"
                    onClick={_placeOrder}
                    color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                    size={window.innerWidth < 640 ? "base" : "xl"}
                    loading={loading}
                >
                    <span>{t("Place your order")}</span>
                </Button>
            </div>
        </div>
    );
}
