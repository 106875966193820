import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import {useTranslation} from "react-i18next";
import apiCRM from "../../../services/apiCRM";
import useGlobal from "../../../state/useGlobal";
import Button from "../../../components/Button";
import history from "../../../routes/history";
import LanguageSwitcher from "../../../components/AI/LanguageSwitcher";
import {findReferCode} from "../../../services/helpers";
import mixpanel from "mixpanel-browser";

export default function WelcomeShareScreen(props) {
    const url = new URL(window.location.href);
    const uuid = url.hash.split('/')[2];
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [loading, setLoading] = useState(true);
    const {calcusoAI} = globalState;
    const {documents} = calcusoAI;

    useEffect(async () => {
        setLoading(true);

        let response = await apiCRM.get('/ai/request/' + uuid);

        await globalActions.initFakeCart();
        await globalActions.reStructureCalcusoAI(response.data);
        await globalActions.setCalcusoAIShare({
            uuid: uuid,
            refer_code: findReferCode(document.URL),
        });

        mixpanel.track_pageview({
            "page": "welcome_share",
            "uuid": uuid ?? null,
            "refer_code": findReferCode(document.URL) ?? null
        })

        globalActions.setStep('product_tag_detect');

        // let referCodeValidate = await api.get('/calcuso-cashback/valid/' + referCode);
        setLoading(false);
    }, []);

    const handleNext = () => {
        history.push('/product');
    }

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            <Helmet>
                <title>{('Calcuso AI')}</title>
            </Helmet>
            <div className="flex flex-col h-full min-h-screen">
                <HeaderAI confirm={false} title={('CALCUSO AI')} subTitle={t('Easy school order')}>
                </HeaderAI>
                <div>
                    <div class="pt-14 md:w-2/3 lg:w-full mx-auto xl:w-1/2">
                        <div
                            class="text-xl font-bold tracking-wide text-center md:text-2xl text-cal-primary-gunmetal-black-202833 px-6">
                            {t('Your parents’ letter')}
                        </div>
                        <div class="h-2/3 md:h-full md:max-w-3xl mx-auto lg:max-w-7xl">
                            <div>
                                <div className={`${documents.length === 1 ? `justify-center` : documents.length < 3 ? `md:justify-center` : `md:grid md:grid-cols-2 lg:grid lg:grid-cols-3`} overflow-x-auto md:overflow-x-hidden flex flex-row px-2`}>
                                    {documents.map((file, index) => (
                                        <div class="inline-block">
                                            <div class="relative px-1.5 pb-2 pt-4 md:py-4 w-60 md:w-60 lg:w-64 mx-auto">
                                                <div class="w-full h-full bg-white rounded-md shadow-md">
                                                    <img className="w-full" key={index}
                                                         src={file}
                                                         alt={`Image ${index}`}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div class="space-y-4">
                            <div class="md:w-2/3 mx-auto pt-10 space-y-6 px-10">
                                <Button
                                    loading={loading}
                                    type="button"
                                    color="bg-cal-primary-celadon-green-00B5B1 hover:bg-cal-primary-celadon-green-00B5B1 focus:bg-cal-primary-celadon-green-00B5B1 font-bold text-cal-primary-floral-white-FEFCF6 focus:ring-cal-primary-celadon-green-00B5B1"
                                    size={window.innerWidth < 640 ? "base" : "xl"}
                                    full={true}
                                    onClick={handleNext}
                                >
                                    {t('Start your order')}
                                </Button>
                            </div>
                        </div>
                        <div class="md:w-2/3 pt-14 md:pt-20 mx-auto px-10">
                            <LanguageSwitcher/>
                        </div>
                    </div>
                </div>
            </div>

        </AppLayout>
    );
};
