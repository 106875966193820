import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import Select from "../../../components/Forms/Select";
import {RadioGroup} from "@headlessui/react";
import classNames from "classnames";
import {SHIPPING_METHOD_DESCRIPTION, SHIPPING_METHOD_LABEL, SHIPPING_METHOD_TO_SCHOOL, SHIPPING_METHODS} from "../../../constants/shippingMethod";
import Button from "../../../components/Button";
import ReCAPTCHA from "react-google-recaptcha";
import Config from "../../../config";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {XCircleIcon} from "@heroicons/react/solid";
import {PlusIcon} from "@heroicons/react/outline";
import NumStudentSlider from "./NumStudentSlider";
import "./../../../styles/datepicker.css"
import api from "../../../services/api";
import {format} from 'date-fns';
import toast from "react-hot-toast";

export default function SettingGroupOrder({setIsShowGo}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {teacherEdition, calcusoAI, fakeCart} = globalState;
    const {school_id, shipping_methods, class_list, deadline, number_of_student} = teacherEdition;
    const {schools} = calcusoAI;
    const [schoolList, setSchoolList] = useState([]);
    const recaptchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [nameClass, setNameClass] = useState("");

    useEffect(() => {
        let schoolList = [{
            key: '',
            value: t('Select your school')
        }];

        schools.map((school) => {
            schoolList.push({
                key: school.m2_id,
                value: school.name
            });
        });

        setSchoolList(schoolList);
    }, []);

    const handleCreateGO = async (values) => {
        setIsLoading(true)
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        let productPayload = buildProductPayload();

        try {
            let response = await api.post('/group-orders/ai/creation', {},
                {
                    'g-recaptcha-response': token,
                    'school_id': values?.school_id,
                    'shipping_methods': values?.shipping_methods,
                    'deadline': format(values?.deadline, 'yyyy-MM-dd'),
                    'classes': values?.listClass,
                    'number_students': values?.numberOfStudent,
                    'items': productPayload,
                });

            if (response.status === 200) {
                globalActions.setTeacherEdition({
                    ...teacherEdition,
                    is_teacher: false,
                    group_order_data: response.data
                });

                setIsShowGo(true);

                globalActions.initFakeCart();
                globalActions.initCalcusoAI();
            }
        } catch (e) {
            globalActions.setTeacherEdition({
                ...teacherEdition,
                is_teacher: false,
            });
            toast.error(t('Error while creating the group order'));
        }

        setIsLoading(false);
    };

    const buildProductPayload = () => {
        let payload = [];
        const {items} = fakeCart

        Object.keys(items).map((key) => {
            payload.push({
                main: items[key]?.id,
                alternatives: []
            })
        });

        return payload;
    };

    return (
        <div class="pt-2 pb-4 flex flex-col items-center flex-1 h-full px-6">
            <div>
                <Formik initialValues={{
                    school_id: school_id,
                    shipping_methods: shipping_methods,
                    listClass: class_list ?? 0,
                    deadline: deadline ? new Date(deadline) : new Date(),
                }}
                        validationSchema={
                                Yup.object().shape({
                                    school_id: Yup.string().nullable().required(t('Please select your school')),
                                    deadline: Yup.string().nullable().required(t('Select order deadline')),
                                    listClass: Yup.array().when('shipping_methods', {
                                        is: (shipping_methods) => shipping_methods === 'toSchool',
                                        then: Yup.array().min(1, t('Please select at least one class')),
                                        otherwise: Yup.array().notRequired()
                                    }),
                                    numberOfStudent: Yup.number().when('shipping_methods', {
                                        is: (shipping_methods) => shipping_methods === 'toSchool',
                                        then: Yup.number().min(1, t('Please enter number of students')),
                                        otherwise: Yup.number().notRequired()
                                    }),
                                })
                        }
                        onSubmit={values => handleCreateGO(values)}>
                    {({
                          values,
                          errors,
                          touched,
                          setFieldValue,
                      }) => {
                        return (
                            <Form>
                                <div class="pt-4 space-y-2">
                                    <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                                        {t("Your school")}
                                    </h3>
                                    <Select
                                        name="school_id"
                                        label={t("Select your school")}
                                        options={schoolList}
                                        onChange={(e) => {
                                            setFieldValue('school_id', e.target.value);
                                        }}
                                        required
                                    />
                                    {
                                        errors.school_id && touched.school_id && (
                                            <div className="text-red-500 text-sm">{errors.school_id}</div>
                                        )
                                    }
                                </div>
                                <div class="hidden">
                                    <ReCAPTCHA
                                        theme="dark"
                                        style={{display: "inline-block"}}
                                        ref={recaptchaRef}
                                        sitekey={Config.siteCaptchaKey}
                                        size="invisible"
                                    />
                                </div>
                                <div class="pt-4 space-y-2">
                                    <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                                        {t("Where should we send the orders?")}
                                    </h3>
                                    <RadioGroup
                                        value={values.shipping_methods}
                                        className="border-t-2 border-celadon-green-light-500"
                                        onChange={(e) => {
                                            setFieldValue('shipping_methods', e);
                                        }}
                                    >
                                        <div className="relative -space-y-px divide-y-2 divide-celadon-green-light-500">
                                            {SHIPPING_METHODS
                                                .map((shippingMethod, key) => (
                                                    <RadioGroup.Option
                                                        key={shippingMethod}
                                                        value={shippingMethod}
                                                        className={({checked}) =>
                                                            classNames({
                                                                "border-gray-200": !checked,
                                                                "relative p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 focus:outline-none": true,
                                                            })
                                                        }
                                                    >
                                                        {({active, checked}) => (
                                                            <div className="flex space-x-4">
                                                                <div>
                                                        <span
                                                            className={classNames({
                                                                " border-gunmetal-black-500": checked,
                                                                " border-gunmetal-black-500 ": !checked,
                                                                "ring-2 ring-offset-2 ring-celadon-green-500": active,
                                                                "h-8 w-8 bg-floral-white-500 rounded-full border-2 flex items-center justify-center": true,
                                                            })}
                                                            aria-hidden="true"
                                                        >
                                                            <span
                                                                className={classNames({
                                                                    "bg-celadon-green-600": checked,
                                                                    "w-5 h-5 rounded-full ": true,
                                                                })}
                                                            />
                                                          </span>
                                                                </div>
                                                                <div className="flex flex-col space-y-2">
                                                                    <RadioGroup.Label
                                                                        as="span"
                                                                        className={classNames({
                                                                            "text-lg font-medium text-gunmetal-black-500": true,
                                                                        })}
                                                                    >
                                                                        {t(SHIPPING_METHOD_LABEL[shippingMethod])}
                                                                    </RadioGroup.Label>
                                                                    <RadioGroup.Description
                                                                        className={classNames({
                                                                            "text-sm md:ml-0 md:pl-0  text-gunmetal-black-500": true,
                                                                        })}
                                                                    >
                                                                        {t(
                                                                            SHIPPING_METHOD_DESCRIPTION[
                                                                                shippingMethod
                                                                                ]
                                                                        )}
                                                                    </RadioGroup.Description>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </RadioGroup.Option>
                                                ))}
                                        </div>
                                    </RadioGroup>
                                </div>
                                <div style={{maxHeight: values.shipping_methods === SHIPPING_METHOD_TO_SCHOOL ? '1000px' : '0px', transition: 'max-height 0.5s ease'}} className="overflow-hidden">
                                    <div className="col-span-6">
                                        <div className="flex items-center justify-between">
                                            <label
                                                className="block text-sm font-medium text-gunmetal-black"
                                            >
                                                {t("Preferred order deadline:")}
                                            </label>
                                        </div>
                                        <div class="flex mt-1 rounded-md shadow-sm">
                                            <DatePicker dateFormat="yyyy-MM-dd"
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            setStartDate(date);
                                                            setFieldValue('deadline', date);
                                                        }}
                                                        minDate={new Date()}
                                            />
                                        </div>

                                        {errors.deadline && (
                                            <div className="flex items-center mt-4">
                                                        <span>
                                                              <XCircleIcon
                                                                  className="w-5 h-5 text-red-500"
                                                                  aria-hidden="true"
                                                              />
                                                        </span>
                                                <span className="ml-2 text-sm text-red-600 ">
                                                          {errors.deadline}
                                                        </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-span-6 mt-4">
                                        <div className="flex items-center justify-between">
                                            <label
                                                className="block text-sm font-medium text-gunmetal-black"
                                            >
                                                {t("For which classes is the group order?")}
                                            </label>
                                        </div>
                                        <div className="flex mt-1 rounded-md shadow-sm">
                                            <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                                <input
                                                    type="text"
                                                    name="nameClass"
                                                    id="nameClass"
                                                    value={nameClass}
                                                    onChange={(e) => setNameClass(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (event.charCode === 13) {
                                                            event.preventDefault();
                                                            if (event.target.value.trim()) {
                                                                setNameClass(event.target.value.trim());
                                                                setFieldValue(
                                                                    "listClass",
                                                                    [
                                                                        ...new Set([
                                                                            ...values.listClass,
                                                                            nameClass.trim(),
                                                                        ]),
                                                                    ],
                                                                    true
                                                                );
                                                                setNameClass("");
                                                            }
                                                        }
                                                    }}
                                                    className="block w-full text-sm border-2 border-gray-300 rounded-none bg-cal-primary-floral-white-FFFEFC focus:ring-cal-primary-celadon-green-00B5B1 focus:border-cal-primary-celadon-green-00B5B1 rounded-l-md md:text-base"
                                                    placeholder={t("Enter name and press ENTER")}
                                                    autoComplete="off"
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    if (nameClass.trim()) {
                                                        setFieldValue(
                                                            "listClass",
                                                            [
                                                                ...new Set([
                                                                    ...values.listClass,
                                                                    nameClass.trim(),
                                                                ]),
                                                            ],
                                                            true
                                                        );
                                                        setNameClass("");
                                                    }
                                                }}
                                                className={classNames(
                                                    "relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium border-2 md:text-base rounded-r-md bg-gray-50 hover:bg-gray-100 focus:outline-none ",
                                                    {
                                                        "text-cal-primary-celadon-green-00B5B1 border-cal-primary-celadon-green-00B5B1 ":
                                                        nameClass,
                                                        "text-cal-primary-gunmetal-black-989898 border-gray-300":
                                                            !nameClass,
                                                    }
                                                )}
                                            >
                                                <PlusIcon
                                                    className={classNames("w-5 h-5 ", {
                                                        "text-cal-primary-celadon-green-00B5B1 ":
                                                        nameClass,
                                                        "text-gray-400": !nameClass,
                                                    })}
                                                    aria-hidden="true"
                                                />
                                                <span>{t("add")}</span>
                                            </button>
                                        </div>
                                        <div className="mt-4 space-x-2">
                                            {values.listClass.map((nameClass) => (
                                                <span
                                                    key={nameClass}
                                                    className="inline-flex rounded-full items-center py-0.5 mt-2 pl-2.5 pr-2 text-sm  bg-cal-secondary-fiery-rose-f74e6c text-cal-primary-floral-white-FEFCF6"
                                                >
                                              {nameClass}
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            const filteredItems = values.listClass.filter(
                                                                (item) => item !== nameClass
                                                            );
                                                            setFieldValue(
                                                                "listClass",
                                                                filteredItems,
                                                                true
                                                            );
                                                        }}
                                                        className="inline-flex items-center justify-center flex-shrink-0 w-4 h-4 ml-1 rounded-full text-cal-primary-floral-white-FEFCF6 hover:bg-cal-primary-floral-white-FEFCF6 hover:text-cal-secondary-fiery-rose-f74e6c focus:outline-none focus:bg-cal-primary-floral-white-FEFCF6 focus:text-cal-secondary-fiery-rose-f74e6c"
                                                    >
                                            <svg
                                                className="w-2 h-2"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 8 8"
                                            >
                                              <path
                                                  strokeLinecap="round"
                                                  strokeWidth="1.5"
                                                  d="M1 1l6 6m0-6L1 7"
                                              />
                                            </svg>
                                          </button>
                                        </span>
                                            ))}
                                        </div>
                                        {errors.listClass && (
                                            <div className="flex items-center mt-4">
                                        <span>
                                          <XCircleIcon
                                              className="w-5 h-5 text-red-500"
                                              aria-hidden="true"
                                          />
                                        </span>
                                                <span className="ml-2 text-sm text-red-600 ">
                                          {errors.listClass}
                                        </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-span-6 mt-4">
                                        <div className="flex items-center justify-between">
                                            <label
                                                className="block text-sm font-medium text-gunmetal-black"
                                            >
                                                {t("How many students are expected to order?")}
                                            </label>
                                        </div>
                                        <div className={"mt-4 px-4 text-center"}>
                                            <NumStudentSlider
                                                defaultValue={values.numberStudents}
                                                onChange={function (value) {
                                                    setFieldValue("numberOfStudent", value, true);
                                                }}
                                            />
                                            <div
                                                className={"rounded-md border-gray-300 inline-block p-2 mt-6 bg-cal-primary-floral-white-FFFEFC border-2"}>
                                            <span className={"text-cal-primary-gunmetal-black-202833"}>
                                              {values.numberOfStudent}
                                            </span>
                                            </div>
                                        </div>
                                        {errors.numberOfStudent && (
                                            <div className="flex items-center mt-2">
                                        <span>
                                          <XCircleIcon
                                              className="w-5 h-5 text-red-500"
                                              aria-hidden="true"
                                          />
                                        </span>
                                                <span className="ml-2 text-sm text-red-600 ">
                                          {errors.numberOfStudent}
                                        </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div class="flex justify-end">
                                    <Button
                                        type="submit"
                                        id="shipping-step-next-button"
                                        size={window.innerWidth < 640 ? "base" : "xl"}
                                        loading={isLoading}
                                    >
                                        <span>{t("Create group order")}</span>
                                    </Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </div>

    );
}
