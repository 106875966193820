import api from "../services/api";
import * as Sentry from "@sentry/react";
import history from "../routes/history";
import {HandleAPICartError} from "../helpers";

export const STATUS_NOT_FOUND = "STATUS_NOT_FOUND";
export const STATUS_EXPIRED = "expired";

const state = {
  classes: [],
  items: [],
  school: null,
  status: false,
  name: null,
  store: null,
  configs: null,
  up_sell: null,
  deadline: null,
  school_code: null,
  teacher: null,
  categories: [],
  calculator: null,
  shipping_init: [],
  isEstimatedShipping: false,
};

export const initialState = localStorage.getItem("groupOrder")
  ? JSON.parse(localStorage.getItem("groupOrder"))
  : state;

export const setGroupOrder = (store, groupOrder) => {
  store.setState(
    {
      groupOrder: {
        ...store.state.groupOrder,
        ...groupOrder,
      },
    },
    () =>
      localStorage.setItem("groupOrder", JSON.stringify(store.state.groupOrder))
  );
};

export const initGroupOrder = (store) => {
  store.setState(
    {
      groupOrder: {
        classes: [],
        items: [],
        school: null,
        status: false,
        name: null,
        store: null,
        configs: null,
        up_sell: null,
        deadline: null,
        school_code: null,
        teacher: null,
        categories: [],
        calculator: null,
        shipping_init: [],
        isEstimatedShipping: false,
      },
    },
    () =>
      localStorage.setItem("groupOrder", JSON.stringify(store.state.groupOrder))
  );
};

export const loadGroupOrder = async (store, schoolCode) => {
  try {
    // Load async data.
    schoolCode = schoolCode.toLowerCase();
    let groupOrderData = await api.get(`/calcuso-group-order/${schoolCode}`);

    const { data } = groupOrderData;
    const { payload, success } = data;

    if (success) {
      store.setState(
        {
          groupOrder: {
            ...store.state.groupOrder,
            ...payload,
            isEstimatedShipping: false,
          },
        },
        () => {
          localStorage.setItem(
            "groupOrder",
            JSON.stringify(store.state.groupOrder)
          );
        }
      );
    } else {
      store.setState(
        {
          groupOrder: {
            ...store.state.groupOrder,
            status: STATUS_NOT_FOUND,
          },
        },
        () => {
          history.push("/");
        }
      );
    }
  } catch (error) {
    Sentry.setUser(store);
    Sentry.captureException(error);
    throw error;
  }
};

export const loadGroupOrderWithProduct = async (
  store,
  schoolCode,
  productId,
  callback
) => {
  try {
    // Load async data.
    schoolCode = schoolCode.toLowerCase();
    let groupOrderData = await api.get(
      `/calcuso-group-order/${schoolCode}/product/${productId}`
    );

    const { data } = groupOrderData;
    const { payload, success } = data;

    if (success) {
      store.setState(
        {
          groupOrder: {
            ...store.state.groupOrder,
            ...payload,
          },
        },
        callback
      );
    } else {
      store.setState(
        {
          groupOrder: {
            ...store.state.groupOrder,
            status: STATUS_NOT_FOUND,
          },
        },
        () => {
          history.push("/");
        }
      );
    }
  } catch (error) {
    Sentry.setUser(store);
    Sentry.captureException(error);
    throw error;
  }
};

export const loadShippingMethods = async (store, payload) => {
  try {
    const { checkout } = store.state;
    const { quote_id } = checkout;
    // Load async data.
    let shippingMethodsData = await api.post(
      `/guest-carts/${quote_id}/estimate-shipping-methods`,
      {},
      payload
    );

    const { data } = shippingMethodsData;

    store.setState(
      {
        checkout: {
          ...store.state.checkout,
          shipping_init: data,
          isEstimatedShipping: true,
        },
      },
      () => {
        localStorage.setItem(
          "checkout",
          JSON.stringify(store.state.checkout)
        );
      }
    );

    return data;
  } catch (error) {
    HandleAPICartError(error, store);
    // history.push("/");
  }
};
