// const prod = {
//   apiURL: "https://www.calcuso.de",
//   stores: {
//     1: "https://www.calcuso.de",
//     4: "https://www.calcuso.com/fr",
//     2: "https://www.calcuso.com/pt",
//   },
// };

const prod = {
  adyenURL: "https://checkoutshopper-live.adyen.com",
  apiURL: "https://www.calcuso.de",
  apiCRM: "https://crm.calcuso.dev",
  apiGO: "https://grouporder.calcuso.com",

  sentryURl: "https://ae5dd9b3544f83157e4b8bf9a633ade6@o94011.ingest.us.sentry.io/4507252261519360",
  cdn: "https://image.calcuso.com/webp/",
  baseUrlMedia: "https://www.calcuso.de/media/catalog/product",
  gt4: "G-RLPYVEMLP4",
  stores: {
    1: "https://www.calcuso.de",
    4: "https://www.calcuso.com/fr",
    2: "https://www.calcuso.com/pt",
  },
  defaultCountry: {
    1: "DE",
    4: "FR",
    2: "PT",
    6: "NL",
  },
  paypalMode: "live",
  links: {
    // DE
    1: {
      LEGAL_NOTICE: "https://www.calcuso.de/legal-notice",
      PRIVACY: "https://www.calcuso.de/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.de/terms",
      CANCEL_POLICY: "https://www.calcuso.de/terms",
    },
    // FR
    4: {
      LEGAL_NOTICE: "https://www.calcuso.com/fr/legal-notice",
      PRIVACY: "https://www.calcuso.com/fr/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.com/fr/terms",
      CANCEL_POLICY: "https://www.calcuso.com/fr/terms",
    },
    // PT
    2: {
      LEGAL_NOTICE: "https://www.calcuso.com/pt/legal-notice",
      PRIVACY: "https://www.calcuso.com/pt/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.com/pt/terms",
      CANCEL_POLICY: "https://www.calcuso.com/pt/terms",
    },
    // IT
    3: {
      LEGAL_NOTICE: "https://www.calcuso.com/it/legal-notice",
      PRIVACY: "https://www.calcuso.com/it/privacy-policy",
      TERMS_OF_USE: "https://www.calcuso.com/it/terms",
      CANCEL_POLICY: "https://www.calcuso.com/it/terms",
    },
  },
  siteCaptchaKey: "6LdA9bQZAAAAAJilvmhYsgho5VgmflbYV4UUJmm3",
  maxWaitingTime: 5000,
  mixpanel: "ef71b2c87c1a825ad32caa88cf307fed",
};

const dev = {
  adyenURL: "https://checkoutshopper-test.adyen.com",
  apiURL: "https://staging.calcuso.com",
  apiCRM: "https://stg-crm.calcuso.dev",
  apiGO: "https://stg-student.calcuso.dev",

  sentryURl: "https://8f06769b6d074cf89a6e62189b021834@o94011.ingest.sentry.io/4504806368673792",
  gt4: "G-RLPYVEMLP4444",
  cdn: "",
  baseUrlMedia: "https://staging.calcuso.com/media/catalog/product",
  stores: {
    1: "https://staging.calcuso.com",
    4: "https://staging.calcuso.com/fr",
    2: "https://staging.calcuso.com/pt",
  },
  defaultCountry: {
    1: "DE",
    4: "FR",
    2: "PT",
    6: "NL",
  },
  paypalMode: "sandbox",
  links: {
    // DE
    1: {
      LEGAL_NOTICE: "https://www.calcuso.de/impressum/",
      PRIVACY: "https://www.calcuso.de/datenschutz",
      TERMS_OF_USE: "https://www.calcuso.de/agb",
      CANCEL_POLICY: "https://www.calcuso.de/widerruf",
    },
    // FR
    4: {
      LEGAL_NOTICE: "https://www.calcuso.com/fr/calcuso-mentions-legales",
      PRIVACY: "https://www.calcuso.com/fr/calcuso-protection-donnees",
      TERMS_OF_USE: "https://www.calcuso.com/fr/calcuso-cgv",
      CANCEL_POLICY: "https://www.calcuso.com/fr/retour-and-remboursement",
    },
    // PT
    2: {
      LEGAL_NOTICE: "https://www.calcuso.com/pt/aviso-legal",
      PRIVACY: "https://www.calcuso.com/pt/protecao-dados",
      TERMS_OF_USE: "https://www.calcuso.com/pt/tcg",
      CANCEL_POLICY: "https://www.calcuso.com/pt/revogacao",
    },
    // IT
    3: {
      LEGAL_NOTICE: "https://www.calcuso.com/it/chi-siamo",
      PRIVACY: "https://www.calcuso.com/it/informativa-sulla-privacy",
      TERMS_OF_USE: "https://www.calcuso.com/it/condizioni-generali-di-vendita",
      CANCEL_POLICY: "https://www.calcuso.com/it/diritto-di-recesso",
    },
  },
  siteCaptchaKey: "6LdA9bQZAAAAAJilvmhYsgho5VgmflbYV4UUJmm3",
  maxWaitingTime: 5000,
  mixpanel: "ddd51c74d89ce432e2b11c48a5ebb587",
};

export default process.env.REACT_APP_STAGE === "prod" ? prod : dev;
