import React, { useState, useEffect } from "react";
import { ShoppingBagIcon } from "@heroicons/react/outline";
import useGlobal from "../state/useGlobal";
import BigNumber from "bignumber.js";

export default function Cart({ schoolCode }) {
  const [loading, setLoading] = useState(true);
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { cart } = globalState;

  useEffect(() => {
    (async () => {
      await globalActions.loadCart();
      setLoading(false);
    })();
  }, [globalActions]);

  return (
    <div className="hidden md:w-48 md:flex ">
      {loading ? (
        <div className="flex-1 py-1 space-y-1 animate-pulse">
          <div className="w-full h-2 rounded bg-floral-white-dark-600"></div>
          <div className="w-full h-2 rounded bg-floral-white-dark-600"></div>
          <div className="w-full h-2 rounded bg-floral-white-dark-600"></div>
          <div className="w-full h-2 rounded bg-floral-white-dark-600"></div>
        </div>
      ) : (
        <div className="relative inline-block">
          <button
            type="button"
            onClick={() => globalActions.openCartModal(cart, schoolCode)}
            className="inline-flex items-center px-4 py-1 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-celadon-green-600 hover:bg-celadon-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-celadon-green-500"
          >
            <ShoppingBagIcon
              className="w-8 h-8 mr-2 -ml-1"
              aria-hidden="true"
            />
            <span className="mr-2">{new BigNumber(cart.subtotal_incl_tax).toFormat(2).toString()} €</span>
          </button>
          <div className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 m-1 transform translate-x-1/2 -translate-y-1/2 rounded-full bg-celadon-green-600 ring-2 ring-white">
            <span className="font-medium text-white">{cart.items_qty}</span>
          </div>
        </div>
      )}
    </div>
  );
}
