import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button";
import useGlobal from "../../../../state/useGlobal";
import api from "../../../../services/api";
import {HandleAPICartError} from "../../../../helpers";
import {HandleSuccessOrder} from "../../../../services/helpers";

export default function CashPayment({recaptchaRef}) {
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const _placeOrder = async () => {
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        setLoading(true);
        const {checkout} = globalState;
        const {quote_id, email, school_info} = checkout;
        try {
            const payload = {
                cartId: quote_id,
                email: email,
                paymentMethod: {
                    method: "calcuso_cash",
                    additional_data: {
                        "calcuso_ai_request_hash": globalState.calcusoAI.uuid,
                        "refer_code": globalState.calcusoAIShare?.refer_code,
                        "g-recaptcha-response": token,
                        "school_name": school_info?.school_name,
                        "school_zip_code": school_info?.school_zip_code
                    }
                },
            };
            // Load async data.
            const response = await api.post(
                `/guest-carts/${quote_id}/payment-information`,
                {},
                payload
            );

            const data = JSON.parse(response.data);

            globalActions.setCalcusoAIShare({
                ...globalState.calcusoAIShare,
                order_id: data?.order_id,
                order_id_hash: data?.order_id_hash,
            });
            HandleSuccessOrder(globalActions);
        } catch (error) {
            HandleAPICartError(error, globalState);
        }
        setLoading(false);
    };

    return (
        <div className="pt-6 space-y-4" id="calcuso-cash-payment">
            <h2 className="md:text-base text-sm">
                {t(
                    "Transfer the invoice amount. Your goods will be shipped after receipt of payment."
                )}
            </h2>
            <div className="flex items-center justify-end space-x-8">
                {/* <Link
        to={`/group-orders/${school_code.trim()}/checkout/address`}
        className="font-medium cursor-pointer text-gunmetal-black-500"
      >
        {t("back")}
      </Link> */}
                <Button
                    id="calcuso-cash-place-order-button"
                    type="button"
                    onClick={_placeOrder}
                    color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                    size={window.innerWidth < 640 ? "base" : "xl"}
                    loading={loading}
                >
                    <span>{t("Place your order")}</span>
                </Button>
            </div>
        </div>
    );
}
