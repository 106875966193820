import React, {useState} from "react";
import {MinusIcon, PlusIcon, TrashIcon} from "@heroicons/react/outline";
import useGlobal from "../../state/useGlobal";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import config from "../../config";

const Image = ({src, alt}) => {
    const [errored, setErrored] = useState(false);

    if (errored) {
        return (<svg
            className="w-full h-full text-gray-300 bg-white border border-gray-200 rounded-md"
            preserveAspectRatio="none"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 200 200"
            aria-hidden="true"
        >
            <path
                vectorEffect="non-scaling-stroke"
                strokeWidth="1"
                d="M0 0l200 200M0 200L200 0"
            ></path>
        </svg>);
    } else {
        return (<img
            className="flex-shrink-0 object-contain w-full h-full mx-auto"
            src={`${config.cdn}${config.baseUrlMedia}${src}`}
            alt={alt}
            onError={setErrored}
        />);
    }
};

export default function FakeCartItem({item}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();

    const {product, price, qty} = item;

    const total = new BigNumber(price).multipliedBy(new BigNumber(qty));

    const handleOnRemove = async () => {
        globalActions.minusFromCart(item);
    };

    // const handleOnRemoveAllItem = async () => {
    //   globalActions.removeFromCart(item);
    //   toast(
    //     t(`"{{product_name}}" has been removed from your cart.`, {
    //       product_name: item.name,
    //     }),
    //     {
    //       id: item.id  + "removeAll",
    //       icon: <TrashIcon className="w-8 h-8 text-fiery-rose-600" />,
    //     }
    //   );
    // };

    const handleOnAdd = async () => {
        globalActions.addToCart(item);
    };

    return (<li className="flex py-6">
        <div className="flex-shrink-0 w-16 overflow-hidden md:w-24 md:h-24">
            <Image src={product?.product_image} alt={product?.name}/>
        </div>

        <div className="flex flex-col flex-1 ml-4 space-y-4">
            <div>
                <h3 className="md:text-base text-sm font-medium text-gray-900 overflow-ellipsis">
                    {product?.name}
                </h3>
            </div>
            <div className="flex items-end justify-between flex-1 text-sm">
                <div className="flex items-end space-x-4 ">
                    <div className="relative z-0 inline-flex rounded-lg shadow-sm bg-celadon-green-500 text-white">
                        {qty === 1 ? (
                            <button
                                type="button"
                                onClick={handleOnRemove}
                                className={classNames({
                                    "relative inline-flex items-center md:px-3 px-1.5 md:py-2 py-1 text-sm font-medium rounded-l-lg  border-r-2 border-white text-white focus:z-10 focus:outline-none ": true,
                                })}
                            >
                                <TrashIcon className="w-4 h-4"/>
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={handleOnRemove}
                                className={classNames({
                                    "relative inline-flex items-center md:px-3 px-1.5 md:py-2 py-1 -ml-px text-sm font-medium border-r-2 border-white  focus:z-10 focus:outline-none ": true,
                                })}
                            >
                                <MinusIcon className="w-4 h-4"/>
                            </button>
                        )}

                        <button
                            type="button"
                            className="relative inline-flex items-center md:px-4 px-2.5 md:py-2 py-1.5 -ml-px text-xs font-medium border-celadon-green-500 focus:z-10 focus:outline-none "
                        >
                            {item?.qty}
                        </button>
                        <button
                            type="button"
                            onClick={handleOnAdd}
                            className={classNames({
                                "relative inline-flex items-center md:px-3 px-1.5 md:py-2 py-1 -ml-px text-sm font-medium border-l-2 border-white rounded-r-lg focus:z-10 focus:outline-none ": true,
                            })}
                        >
                            <PlusIcon className="w-4 h-4"/>
                        </button>
                    </div>
                    <p className="hidden text-base text-gray-900 md:block">
                        x {new BigNumber(price).toFormat(2).toString()} €
                    </p>
                    {/* <button
              type="button"
              onClick={handleOnRemoveAllItem}
              className="inline-flex items-center p-1 text-fiery-rose-600 hover:text-fiery-rose-700"
            >
              <TrashIcon className="w-6 h-6" />
            </button> */}
                </div>

                <p className="md:text-base text-sm font-medium text-gray-900">
                    {total.toFormat(2).toString()} €
                </p>
            </div>
        </div>
    </li>);
}
