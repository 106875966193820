import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../../state/useGlobal";
import api from "../../../../services/api";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";
import Config from "../../../../config";
import {HandleAPICartError, HandleError} from "../../../../helpers";

export default function PaypalPlusPayment({recaptchaRef}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {checkout} = globalState;
    const {quote_id, email, billing, school_info} = checkout;
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [enable, setEnable] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
        async function loadPPP() {
            setLoading(true);
            const token = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();

            try {
                // Load async data.
                const pppData = await api.post(
                    `/guest-carts/${quote_id}/set-ppp-payment-information-v2`,
                    {},
                    {
                        cartId: quote_id,
                        email: email,
                        paymentMethod: {
                            method: "iways_paypalplus_payment",
                            po_number: null,
                            additional_data: {
                                "calcuso_ai_request_hash": globalState.calcusoAI.uuid,
                                "g-recaptcha-response": token,
                                "refer_code": globalState.calcusoAIShare?.refer_code,
                                "school_name": school_info?.school_name,
                                "school_zip_code": school_info?.school_zip_code
                            },
                        },
                        billingAddress: {
                            ...billing,
                            street: [billing.street],
                        },
                    }
                );
                const {data} = pppData;
                const {payload} = data;
                setLoading(false);

                window.CALCUSO_PPP = window.PAYPAL.apps.PPP({
                    approvalUrl: payload,
                    placeholder: "ppplus",
                    mode: Config.paypalMode,
                    country: billing.country_id,
                    buttonLocation: "outside",
                    language: "de_DE",
                    enableContinue: function () {
                        setEnable(true);
                    },
                    disableContinue: function () {
                        setEnable(false);
                    },
                });
            } catch (error) {
                HandleError(error);
            }
        }

        loadPPP();
    }, [billing, email, globalState, quote_id]);

    const _placeOrder = async () => {
        setLoadingSubmit(true);
        try {
            window.CALCUSO_PPP.doCheckout();
        } catch (error) {
            HandleAPICartError(error, globalState);
            setLoadingSubmit(false);
        }
    };

    return (
        <>
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <div id="ppplus"></div>
                    <div className="flex items-center justify-end pt-8 space-x-8">
                        <Button
                            type="button"
                            onClick={_placeOrder}
                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                            size={window.innerWidth < 640 ? "base" : "xl"}
                            disabled={!enable}
                            loading={loadingSubmit}
                        >
                            <span>{t("Place your order")}</span>
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}
