const state = {
    uuid: null,
    status: null,
    step: null,
    is_analyzed: false,
    apiData: [],
    documents: [],
    items: [],
};

export const initialState = localStorage.getItem("calcusoAI")
    ? JSON.parse(localStorage.getItem("calcusoAI"))
    : state;

export const setCalcusoAI = (store, calcusoAI) => {
    store.setState(
        {
            calcusoAI: {
                ...store.state.calcusoAI,
                ...calcusoAI,
            },
        },
        () =>
            localStorage.setItem("calcusoAI", JSON.stringify(store.state.calcusoAI))
    );
};

export const initCalcusoAI = (store) => {
    store.setState(
        {
            calcusoAI: {
                uuid: null,
                status: null,
                step: null,
                is_analyzed: false,
                hasText: false,
                apiData: [],
                documents: [],
                items: []
            },
        },
        () =>
            localStorage.setItem("calcusoAI", JSON.stringify(store.state.calcusoAI))
    );
};

export const reStructureCalcusoAI = (store, data) => {
    const {apiData} = data;
    const {documents, schools} = apiData;
    const documentUrls = [];
    const items = [];
    const simpleItems = [];

    const groupOrderSchoolCode = documents.find(document => document.calcuso_school_code !== null)?.calcuso_school_code;

    documents.map((document) => {
        documentUrls.push(document.document_url);
        if (document.is_parent_letter) {
            document.items.forEach((item) => {
                const mainProduct = item.main_product;
                if (mainProduct !== null) {
                    const alternativeProducts = item.alternative_products?.filter(altProduct => altProduct.product_saleable) || [];

                    item = {
                        ...item,
                        ...{
                            alternative_products: alternativeProducts
                        }
                    };

                    if (item.main_product?.product_saleable === false) {
                        item = {
                            ...item,
                            ...{
                                main_product: alternativeProducts[0] ?? null,
                                alternative_products: alternativeProducts.slice(1)
                            }
                        };
                    }

                    item = {
                        ...item,
                        extract_qty: extractQty(store, item.text)
                    };
                    items.push(item);
                } else {
                    items.push(item);
                }
            });
        }
    });

    let sortedItems = sortItems(items);

    sortedItems.map((item) => {
        let productIds = [];
        if (item.main_product) {
            productIds = [item.main_product?.product_id, ...item?.alternative_products.map(altProduct => altProduct?.product_id)];
        }

        simpleItems.push({
            item_id: item.item_id,
            productIds,
            text: item.text,
            product_tag_name: item.product_tag_name,
            is_display: true,
        });
    });

    store.setState(
        {
            calcusoAI: {
                ...store.state.calcusoAI,
                uuid: data.uuid,
                status: data.status,
                step: data.step,
                apiData: data,
                documents: documentUrls,
                items: sortedItems,
                simpleItems: simpleItems,
                is_not_found_product: items.length === 0,
                group_order_school_code: groupOrderSchoolCode,
                is_parent_letter: true,
                schools: schools
            },
        },
        () => localStorage.setItem("calcusoAI", JSON.stringify(store.state.calcusoAI))
    );

    return store.state.calcusoAI;
};

export const setIsDisplay = (store, item_id, is_display) => {
    let simpleItems = store.state.calcusoAI.simpleItems;

    simpleItems.map((item, index) => {
        if (item.item_id === item_id) {
            if (is_display === undefined) {
                is_display = !item.is_display;
            }

            simpleItems[index] = {
                ...simpleItems[index],
                ...{
                    is_display: is_display
                }
            };
        }
    });

    store.setState(
        {
            calcusoAI: {
                ...store.state.calcusoAI,
                ...{
                    simpleItems: simpleItems
                }
            },
        },
        () =>
            localStorage.setItem("calcusoAI", JSON.stringify(store.state.calcusoAI))
    );
};

export const switchMainItem = (store, alternativeProductId, itemId) => {
    let items = store.state.calcusoAI.items;

    items.map((item, index) => {
        if (item.item_id === itemId) {
            let mainProduct = item.main_product;
            let alternativeProducts = item.alternative_products;
            if (!alternativeProducts) {
                return;
            }

            alternativeProducts.map((altProduct, altIndex) => {
                if (altProduct.product_id === alternativeProductId) {
                    items[index].main_product = altProduct;
                    items[index].alternative_products[altIndex] = mainProduct;
                }
            });
        }
    });

    store.setState(
        {
            calcusoAI: {
                ...store.state.calcusoAI,
                ...{
                    items: items,
                    changeKey: new Date().getTime(),
                }
            },
        },
        () =>
            localStorage.setItem("calcusoAI", JSON.stringify(store.state.calcusoAI))
    );
}

export const setIsAnalyzed = (store, isAnalyzed) => {
    store.setState(
        {
            calcusoAI: {
                ...store.state.calcusoAI,
                ...{
                    is_analyzed: isAnalyzed,
                }
            },
        },
        () =>
            localStorage.setItem("calcusoAI", JSON.stringify(store.state.calcusoAI))
    );
}

export const extractQty = (store, text) => {
    const regex = /^\d+/;
    const match = text.match(regex);

    if (match === null) {
        return 1;
    }

    return parseInt(match[0]);
}

export const setStep = (store, step) => {
    store.setState(
        {
            calcusoAI: {
                ...store.state.calcusoAI,
                ...{
                    step: step,
                }
            },
        },
    );
}

export const sortItems = (items) => {
    return items.sort((a, b) => {
        if (a.product_tag_detected === 'isbn' && a.status === 'pending' && (b.product_tag_detected !== 'isbn' || b.status !== 'pending')) {
            return 1;
        } else if ((a.product_tag_detected !== 'isbn' || a.status !== 'pending') && b.product_tag_detected === 'isbn' && b.status === 'pending') {
            return -1;
        } else {
            return 0;
        }
    });
}

export const handleIsbnItem = (store, data) => {
    const currentItems = store.state.calcusoAI.items;
    const {apiData} = data;
    const {documents} = apiData;

    documents.map((document) => {
        if (document.is_parent_letter) {
            document.items.forEach((item) => {
                currentItems.map((currentItem, index) => {
                    if (currentItem.item_id === item.item_id && currentItem.product_tag_detected === 'isbn' && item.status !== 'pending') {
                        const alternativeProducts = item.alternative_products?.filter(altProduct => altProduct.product_saleable) || [];

                        item = {
                            ...item,
                            ...{
                                alternative_products: alternativeProducts
                            }
                        };

                        if (item.main_product?.product_saleable === false) {
                            item = {
                                ...item,
                                ...{
                                    main_product: alternativeProducts[0] ?? null,
                                    alternative_products: alternativeProducts.slice(1)
                                }
                            };
                        }

                        item = {
                            ...item,
                            extract_qty: extractQty(store, item.text)
                        }

                        currentItems[index] = item;
                    }
                });
            });
        }
    });

    store.setState(
        {
            calcusoAI: {
                ...store.state.calcusoAI,
                items: currentItems,
            },
        },
        () =>
            localStorage.setItem("calcusoAI", JSON.stringify(store.state.calcusoAI))
    );

    return store.state.calcusoAI;
}

export const isCallIsbnApi = (store, data) => {
    const {apiData, status} = data;
    const {documents} = apiData;
    let isCallIsbnApi = false;

    if (status === 'error') {
        return isCallIsbnApi;
    }

    documents.map((document) => {
        if (document.is_parent_letter) {
            document.items.forEach((item) => {
                if (item.product_tag_detected === 'isbn' && item.status === 'pending') {
                    isCallIsbnApi = true;
                }
            });
        }
    });

    return isCallIsbnApi;
}