import React from "react";
import {ATTRIBUTE_CODE_COLOR, ATTRIBUTE_CODE_IS_GRAVIUR, ATTRIBUTE_CODE_SIZE, ATTRIBUTE_WARRANTY_DURATION} from "../../../constants/common";
import Color from "./Options/Color";
import SelectOption from "./Options/SelectOption";
import SelectStyleTag from "./Options/SelectStyleTag";

export default function RecursiveOptionsContainer({ options, formik, item }) {
  return (
    <>
      {options.map((optionConfig) => {
        switch (optionConfig.attribute_input) {
          case "select":
            switch (optionConfig.attribute_code) {
              case ATTRIBUTE_CODE_IS_GRAVIUR:
                  return "";
              case ATTRIBUTE_CODE_SIZE:
              case ATTRIBUTE_WARRANTY_DURATION:
                return (
                  <SelectStyleTag
                    key={optionConfig.id}
                    option={optionConfig}
                    formik={formik}
                    item={item}
                  />
                );
              case ATTRIBUTE_CODE_COLOR:
                return (
                  <Color
                    key={optionConfig.id}
                    option={optionConfig}
                    formik={formik}
                    item={item}
                  />
                );
              default:
                return (
                  <SelectOption
                    key={optionConfig.id}
                    option={optionConfig}
                    formik={formik}
                    item={item}
                  />
                );
            }
          default:
            return <div key={optionConfig.id}>Unsupported</div>;
        }
      })}
    </>
  );
}
