import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {ChevronDownIcon, ChevronUpIcon, InformationCircleIcon} from "@heroicons/react/outline";
import BADGE_CONSTANTS from "../../../constants/badge";
import EmptyIcon from "../../../components/Icons/EmptyIcon";
import Badge from "../../../components/Badge";
import ProductImage from "../../ProductImage";
import useGlobal from "../../../state/useGlobal";
import {IS_GRAVIUR, IS_NOT_GRAVIUR, PRODUCT_TYPE_CONFIGURABLE} from "../../../constants/common";
import BigNumber from "bignumber.js";
import {Formik} from "formik";
import AlterativeProduct from "../CardProduct/AlterativeProduct";
import ButtonAddCart from "../Cart/ButtonAddCart";
import Gravur from "../../DetailListView/ConfProduct/Options/Gravur";
import {GetFinalPrice, GetLowestColor, InitCartFormValue} from "../../../services/helpers";
import PriceContainer from "../../DetailListView/ConfProduct/PriceContainer";
import RecursiveOptionsContainer from "../../DetailListView/ConfProduct/RecursiveOptionsContainer";
import classNames from "classnames";
import Loading from "../../Loading";

export default function MainConfigProduct({item, alterativeItems, aiItem, indexItem}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [globalState, globalActions] = useGlobal();
    const [isSwitching, setIsSwitching] = useState(false);
    const {fakeCart} = globalState;
    const {items} = fakeCart;
    const selectedItem = items[item.product_id];

    const lowestColor = GetLowestColor(item);

    const initialValues = InitCartFormValue(item, selectedItem);

    /**
     * Stop enter submitting the form.
     * @param keyEvent Event triggered when the user presses a key.
     */
    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
            const {target} = keyEvent;
            target.blur();
        }
    }

    const handleSwitchProduct = (productId) => {
        setOpen(false);
        setIsSwitching(true);

        setTimeout(() => {
            globalActions.switchMainItem(productId, aiItem.item_id);
        }, 500);

        setTimeout(() => {
            setIsSwitching(false);
        }, 1000);

        const element = document.getElementById(`product-card-${indexItem}`);
        element.scrollIntoView({behavior: "smooth", block: "end"});
    };

    const onSubmit = async (values) => {
        const price = GetFinalPrice(item, values, selectedItem);
        const payload = {
            id: item.product_id,
            name: item.name,
            sku: item.product_sku,
            type: item.product_type,
            qty: parseInt(aiItem.quantity),
            price: new BigNumber(price).toFormat(2).toString(),
            product: item,
            options: {
                ...values,
            },
            created_at: new Date().getTime(),
        };

        globalActions.addToCart(payload);
        globalActions.setIsDisplay(aiItem.item_id, false);
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...initialValues,
                    color: selectedItem?.options?.color
                        ? selectedItem?.options?.color
                        : lowestColor?.value,
                    gravur: selectedItem?.options?.gravur
                        ? selectedItem?.options?.gravur
                        : "",
                    is_graviur: selectedItem?.options?.gravur
                        ? IS_GRAVIUR
                        : IS_NOT_GRAVIUR,
                }}
                onSubmit={onSubmit}>
                {
                    (formik) => {
                        const {handleSubmit} = formik;
                        return (
                            <div class="relative">
                                <div className={classNames({
                                    'absolute': true,
                                    'transition-opacity duration-300 opacity-0 -z-10': !isSwitching,
                                    'top-0 left-0 w-full h-full transition-opacity duration-200 opacity-100': isSwitching,
                                })}>
                                    <Loading/>
                                </div>
                                <form
                                    className={classNames({
                                        'flex flex-col space-y-4': true,
                                        'transition-opacity duration-100 opacity-0': isSwitching,
                                        'transition-opacity duration-300 opacity-100': !isSwitching,
                                    })}
                                    onSubmit={handleSubmit} onKeyDown={onKeyDown}
                                >
                                    <div className="grid grid-cols-2 md:flex md:flex-row w-full px-4">
                                        <div class="flex justify-center items-center relative md:w-4/12">
                                            <div
                                                class="flex justify-center items-center"
                                                onClick={() => globalActions.openProductModal(item)}>
                                                <ProductImage
                                                    src={item?.product_image}
                                                    alt={item?.name}
                                                    size="w-auto h-40 md:my-auto md:w-auto md:h-40"/>
                                            </div>
                                        </div>
                                        <div
                                            class="flex flex-col text-cal-primary-gunmetal-black-202833 space-y-2 md:w-3/12">
                                            <div class="md:absolute md:-top-6">
                                                {item?.product_tags?.map((product_tag) => (
                                                    <Badge
                                                        key={product_tag.tag_value}
                                                        color={BADGE_CONSTANTS.color[product_tag.tag_value]}
                                                        colorText={
                                                            BADGE_CONSTANTS.colorText[product_tag.tag_value]
                                                        }
                                                    >
                                                        {BADGE_CONSTANTS.icon[product_tag.tag_value] ? BADGE_CONSTANTS.icon[product_tag.tag_value] :
                                                            <EmptyIcon className="h-6"/>}
                                                        <span>{product_tag.tag_name}</span>
                                                    </Badge>
                                                ))}
                                            </div>
                                            <div class="flex flex-col pt-4">
                                                <span
                                                    onClick={() => globalActions.openProductModal(item)}
                                                    class="font-bold text-lg">
                                                    {item?.name}
                                                </span>
                                                <span class="text-xs">
                                                    {t('from')} {item?.brand}
                                                </span>
                                                <span class="font-bold text-xl pt-1 hidden md:block">
                                                  <PriceContainer formik={formik} item={item}/>
                                                </span>
                                                <div
                                                    className="text-sm prose text-left hidden md:block"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.short_description,
                                                    }}
                                                />
                                            </div>

                                            <span class="font-bold text-xl pt-1 md:hidden">
                                              <PriceContainer formik={formik} item={item}/>
                                            </span>
                                        </div>
                                        <div class="pt-2 md:w-5/12 hidden md:block">
                                            {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                <>
                                                    {item?.configurable_product_options ? (
                                                        <RecursiveOptionsContainer
                                                            options={item?.configurable_product_options}
                                                            formik={formik}
                                                            item={item}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                <>
                                                    {item?.product_custom_options &&
                                                        item?.product_custom_options.map((custom_option) =>
                                                            custom_option.type === "field" ? (
                                                                <div
                                                                    className="w-full lg:flex-1"
                                                                    key={custom_option.option_id}
                                                                >
                                                                    <Gravur
                                                                        option={custom_option}
                                                                        formik={formik}
                                                                        item={item}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )
                                                        )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div class="flex justify-center items-center">
                                        <div class="pt-2 w-full block md:hidden">
                                            {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                <>
                                                    {item?.configurable_product_options ? (
                                                        <RecursiveOptionsContainer
                                                            options={item?.configurable_product_options}
                                                            formik={formik}
                                                            item={item}
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                <>
                                                    {item?.product_custom_options &&
                                                        item?.product_custom_options.map((custom_option) =>
                                                            custom_option.type === "field" ? (
                                                                <div
                                                                    className="w-full lg:flex-1"
                                                                    key={custom_option.option_id}
                                                                >
                                                                    <Gravur
                                                                        option={custom_option}
                                                                        formik={formik}
                                                                        item={item}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )
                                                        )}
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div class="flex justify-between md:flex-none pt-2 py-2">
                                        <div
                                            className="flex justify-center items-center w-1/2 md:w-1/3"
                                        >
                                            {alterativeItems?.length > 0 && (
                                                <span
                                                    onClick={() => setOpen(!open)}
                                                    class="font-medium cursor-pointer text-cal-primary-celadon-green-01A7A3 flex justify-center items-center space-x-2 text-sm md:text-base">
                                                     <span>
                                                         {t('Alternatives')}
                                                     </span>
                                                    {open ? <ChevronUpIcon className="w-5 h-5"/> : <ChevronDownIcon className="w-5 h-5"/>}
                                                </span>
                                            )}
                                        </div>
                                        <div class="flex justify-end items-center space-x-4">
                                            <ButtonAddCart item={item}/>
                                        </div>

                                        <div>
                                        </div>
                                    </div>
                                </form>

                                <AlterativeProduct alternativeItems={alterativeItems} open={open} handleSwitchProduct={handleSwitchProduct} isSwitching={isSwitching}/>
                            </div>
                        )
                    }
                }
            </Formik>

        </>
    );
};
