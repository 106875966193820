import React, {useEffect, useState} from 'react';
import useGlobal from "../../../state/useGlobal";
import classNames from "classnames";

const Step = () => {
    const [globalState, globalActions] = useGlobal();
    const [step, setStep] = useState('is_parent_letter_detect');

    useEffect(() => {
        if (globalState.calcusoAI.step === 'pending') {
            setStep('is_parent_letter_detect');
        } else {
            setStep(globalState.calcusoAI.step);
        }

    }, [globalState.calcusoAI.step]);

    const steps = ["is_parent_letter_detect", "text_extract", "product_tag_detect"];

    const getStepClass = (currentStep) => {
        const currentStepIndex = steps.indexOf(step);
        const stepIndex = steps.indexOf(currentStep);

        return classNames({
            "relative inline-flex rounded-full h-3 w-3": true,
            "bg-cal-primary-celadon-green-01A7A3": stepIndex <= currentStepIndex,
            "bg-gray-300": stepIndex > currentStepIndex
        });
    };

    return (
        <nav aria-label="Progress">
            <ol role="list" className="flex items-center justify-between space-x-7">
                {steps.map((currentStep, index) => (
                    <li key={index} className="relative">
                        <span className="relative flex h-3 w-3">
                            <span className={classNames({
                                "absolute inline-flex h-full w-full rounded-full opacity-75": true,
                                "animate-ping": step === currentStep,
                                "bg-cal-primary-celadon-green-01A7A3": step === currentStep
                            })}></span>
                            <span className={getStepClass(currentStep)}></span>
                        </span>
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Step;
