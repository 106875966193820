import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button";
import useGlobal from "../../../../state/useGlobal";
import toast from "react-hot-toast";
import api from "../../../../services/api";
import {HandleAPICartError} from "../../../../helpers";

export default function PaypalExpressPayment({recaptchaRef}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);

    const _placeOrder = async () => {
        setLoading(true);
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();

        const {checkout} = globalState;
        const {quote_id, email, school_info} = checkout;
        try {
            const payload = {
                cartId: quote_id,
                email: email,
                paymentMethod: {
                    method: "paypal_express",
                    additional_data: {
                        "calcuso_ai_request_hash": globalState.calcusoAI.uuid,
                        "g-recaptcha-response": token,
                        "refer_code": globalState.calcusoAIShare?.refer_code,
                        "school_name": school_info?.school_name,
                        "school_zip_code": school_info?.school_zip_code
                    }
                },
            };
            // Load async data.
            const responsePaypalExpress = await api.post(
                `/guest-carts/${quote_id}/set-payment-information`,
                {},
                payload
            );
            const {data} = responsePaypalExpress;
            if (data) {
                const responseRedirectTransaction = await api.post(
                    `/guest-carts/${quote_id}/paypal-express`,
                    {},
                    {}
                );

                const {payload} = responseRedirectTransaction.data;

                window.location = payload;
            } else {
                toast.error(
                    "We can not create payment information for the paypal express."
                );
            }
        } catch (error) {
            HandleAPICartError(error, globalState);
        }
        setLoading(false);
    };

    return (
        <div className="flex items-center justify-end pt-8 space-x-8">
            <Button
                type="button"
                onClick={_placeOrder}
                color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                size={window.innerWidth < 640 ? "base" : "xl"}
                loading={loading}
            >
                <span>{t("Place your order")}</span>
            </Button>
        </div>
    );
}
