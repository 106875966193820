import api from "../services/api";
import {STEP_WELCOME} from "../constants/cartSteps";
import {STEP_CHECKOUT_ADDRESS} from "../constants/checkoutSteps";
import {SHIPPING_METHOD_TO_SCHOOL} from "../constants/shippingMethod";
import {HandleAPICartError} from "../helpers";
import history from "../routes/history";

const state = {
    quote_id: false,
    currentStep: STEP_WELCOME,
    currentCheckoutStep: STEP_CHECKOUT_ADDRESS,
    student: {
        first_name: "",
        last_name: "",
        class_name: "",
    },
    email: "",
    is_same_shipping: true,
    billing: {
        city: "",
        country_id: "",
        firstname: "",
        lastname: "",
        postcode: "",
        street: "",
        company: "",
        vat_id: "",
    },
    shipping: {
        city: "",
        country_id: "",
        firstname: "",
        lastname: "",
        postcode: "",
        street: "",
        company: "",
        vat_id: "",
    },
    shippingMethodCode: "",
    shippingCarrierCode: "",
    payment_methods: [],
    payment_methods_adyen: null,
    payment: "",
    paymentLabel: "",
    totals: {
        items: [],
    },
    store: "",
};

export const initialState = localStorage.getItem("checkout")
    ? JSON.parse(localStorage.getItem("checkout"))
    : state;

export const setCheckout = (store, checkout) => {
    store.setState(
        {
            checkout: {
                ...store.state.checkout,
                ...checkout,
            },
        },
        () => localStorage.setItem("checkout", JSON.stringify(store.state.checkout))
    );
};

export const initCheckout = (store) => {
    store.setState(
        {
            checkout: {
                quote_id: false,
                currentStep: STEP_WELCOME,
                student: {
                    first_name: "",
                    last_name: "",
                    class_name: "",
                },
                shippingMethodCode: "",
                store: {
                    "id": 1,
                    "code": "default",
                    "name": "GERMANY Store View",
                    "website_id": 1,
                    "store_group_id": 1,
                    "is_active": 1
                },
            },
        },
        () => localStorage.setItem("checkout", JSON.stringify(store.state.checkout))
    );
};

export const initAddresses = (store) => {
    store.setState(
        {
            checkout: {
                ...store.state.checkout,
                email: "",
                is_same_shipping: true,
                billing: {
                    city: "",
                    country_id: "DE",
                    firstname: "",
                    lastname: "",
                    postcode: "",
                    street: "",
                    company: "",
                    vat_id: "",
                },
                shipping: {
                    city: "",
                    country_id: "DE",
                    firstname: "",
                    lastname: "",
                    postcode: "",
                    street: "",
                    company: "",
                    vat_id: "",
                },
                shippingMethodCode: "",
                shippingCarrierCode: "",
            },
        },
        () => localStorage.setItem("checkout", JSON.stringify(store.state.checkout))
    );
};

export const loadPaymentMethods = async (store) => {
    try {
        const {checkout} = store.state;
        const {
            quote_id,
            shipping,
            billing,
            shippingMethodCode,
            shippingCarrierCode,
        } = checkout;

        const payload = {
            addressInformation: {
                shipping_address: {
                    ...shipping,
                    street: [shipping.street],
                },
                billing_address: {
                    ...billing,
                    street: [billing.street],
                },
                shipping_method_code: shippingMethodCode,
                shipping_carrier_code: shippingCarrierCode,
            },
        };
        // Load async data.
        let paymentData = await api.post(
            `/guest-carts/${quote_id}/shipping-information`,
            {},
            payload
        );

        const {data} = paymentData;

        store.setState(
            {
                checkout: {
                    ...store.state.checkout,
                    ...data,
                },
            },
            () =>
                localStorage.setItem("checkout", JSON.stringify(store.state.checkout))
        );
    } catch (error) {
        if (typeof error === 'object' && 'status' in error) {
            const {status} = error;

            if (status === 404) {
                history.push("/");
            } else {
                HandleAPICartError(error, store.state);
            }
        } else {
            HandleAPICartError(error, store.state);
        }
    }
};

export const loadPaymentMethodsAdyen = async (store) => {
    try {
        const {checkout} = store.state;
        const {
            quote_id,
        } = checkout;
        // Load async data.
        let paymentData = await api.post(`/guest-carts/${quote_id}/adyen/retrieve-adyen-payment-methods`);
        let {data} = paymentData;
        data = JSON.parse(data);
        return data;
    } catch (error) {
        if (typeof error === 'object' && 'status' in error) {
            const {status} = error;

            if (status === 404) {
                history.push("/");
            } else {
                HandleAPICartError(error, store.state);
            }
        } else {
            HandleAPICartError(error, store.state);
        }
    }
};
