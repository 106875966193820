import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import MainConfigProduct from "../ConfigProduct/MainConfigProduct";
import MainSimpleProduct from "../SimpleProduct/MainSimpleProduct";
import useGlobal from "../../../state/useGlobal";

export default function CardProduct({item, indexItem}) {
    const {t} = useTranslation();
    const [mainProduct, setMainProduct] = useState(null)
    const [alternativeProducts, setAlternativeProducts] = useState([])
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        const {main_product} = item;
        const {alternative_products} = item;

        setMainProduct(main_product);
        setAlternativeProducts(alternative_products);
    }, [globalState.calcusoAI.changeKey]);


    return (
        <div>
            <div>
                {mainProduct?.product_type === 'configurable' ? (
                    <MainConfigProduct
                        item={mainProduct} alterativeItems={alternativeProducts} aiItem={item} indexItem={indexItem}/>
                ) : (
                    <MainSimpleProduct item={mainProduct} alterativeItems={alternativeProducts} aiItem={item} indexItem={indexItem}/>
                )}
            </div>
        </div>
    );
};
