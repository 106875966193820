import React from "react";
import CashPayment from "./Payments/CashPayment";
import Button from "../../components/Button";
import useGlobal from "../../state/useGlobal";
import PaypalPlusPayment from "./Payments/PaypalPlusPayment/PaypalPlusPayment";
import PaypalExpressPayment from "./Payments/PaypalExpressPayment";
import {useTranslation} from "react-i18next";
import CreditCard from "./AdyenPayments/CreditCard";
import SofortPayment from "./AdyenPayments/SofortPayment";
import {KlarnaPayLaterPayment, KlarnaPayOvertimePayment} from "./AdyenPayments/KlarnaPayment";
import MBWayPayment from "./AdyenPayments/MBWayPayment";
import IdealPayment from "./AdyenPayments/IdealPayment";
import EPSPayment from "./AdyenPayments/EPSPayment";

export default function PaymentMethod({payment, recaptchaRef}) {
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();

    switch (payment) {
        case "calcuso_cash":
            return <CashPayment recaptchaRef={recaptchaRef}/>;

        // case "stripe_payments":
        //   return <StripePayment />;
        //
        // case "stripe_payments_sofort":
        //   return <SoftPayment />;

        case "iways_paypalplus_payment":
            return <PaypalPlusPayment recaptchaRef={recaptchaRef}/>;

        case "paypal_express":
            return <PaypalExpressPayment recaptchaRef={recaptchaRef}/>;

        // case "stripe_payments_multibanco":
        //   return <MultibancoPayment />;

        case "adyen_cc":
            return <CreditCard globalState={globalState} globalActions={globalActions} recaptchaRef={recaptchaRef}/>;

        // case "adyen_multibanco":
        //   return <MultiBancoPayment />

        case "adyen_directEbanking":
            return <SofortPayment recaptchaRef={recaptchaRef}/>

        case "adyen_klarna":
            return <KlarnaPayLaterPayment recaptchaRef={recaptchaRef}/>

        case "adyen_klarna_account":
            return <KlarnaPayOvertimePayment recaptchaRef={recaptchaRef}/>

        case "adyen_mbway":
            return <MBWayPayment recaptchaRef={recaptchaRef}/>

        case "adyen_ideal":
            return <IdealPayment recaptchaRef={recaptchaRef}/>

        case "adyen_eps":
            return <EPSPayment recaptchaRef={recaptchaRef}/>

        default:
            return (
                <>
                    <div className="my-2">{t("Not Support Yet.")}</div>
                    <div className="flex items-center justify-end pt-8 space-x-8">
                        <Button
                            type="button"
                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                            size={window.innerWidth < 640 ? "base" : "xl"}
                            disabled={true}
                        >
                            <span>{t("Place your order")}</span>
                        </Button>
                    </div>
                </>
            );
    }
}
