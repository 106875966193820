import React from 'react';
import {useTranslation} from "react-i18next";
import ProductImage from "../../ProductImage";
import useGlobal from "../../../state/useGlobal";
import {IS_GRAVIUR, IS_NOT_GRAVIUR, PRODUCT_TYPE_CONFIGURABLE} from "../../../constants/common";
import BigNumber from "bignumber.js";
import {Formik} from "formik";
import {GetFinalPrice, GetLowestColor, InitCartFormValue} from "../../../services/helpers";
import PriceContainer from "../../DetailListView/ConfProduct/PriceContainer";
import RecursiveOptionsContainer from "../../DetailListView/ConfProduct/RecursiveOptionsContainer";

export default function ConfigProduct({item, handleSwitchProduct}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();

    const {fakeCart} = globalState;
    const {items} = fakeCart;
    const selectedItem = items[item.product_id];

    const lowestColor = GetLowestColor(item);

    const initialValues = InitCartFormValue(item, selectedItem);

    /**
     * Stop enter submitting the form.
     * @param keyEvent Event triggered when the user presses a key.
     */
    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
            const {target} = keyEvent;
            target.blur();
        }
    }

    const onSubmit = async (values) => {
        const price = GetFinalPrice(item, values, selectedItem);
        const payload = {
            id: item.product_id,
            name: item.name,
            sku: item.product_sku,
            type: item.product_type,
            qty: 1,
            price: new BigNumber(price).toFormat(2).toString(),
            product: item,
            options: {
                ...values,
            },
            created_at: new Date().getTime(),
        };

        globalActions.addToCart(payload);
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...initialValues,
                    color: selectedItem?.options?.color
                        ? selectedItem?.options?.color
                        : lowestColor?.value,
                    gravur: selectedItem?.options?.gravur
                        ? selectedItem?.options?.gravur
                        : "",
                    is_graviur: selectedItem?.options?.gravur
                        ? IS_GRAVIUR
                        : IS_NOT_GRAVIUR,
                }}
                onSubmit={onSubmit}>
                {
                    (formik) => {
                        const {handleSubmit} = formik;
                        return (
                            <>
                                <form
                                    onSubmit={handleSubmit} onKeyDown={onKeyDown}
                                >
                                    <>
                                        <div
                                            className="flex flex-col items-center border border-2 px-2 py-4 rounded-md w-72 md:w-auto">
                                            <div
                                                onClick={() => globalActions.openProductModal(item)}
                                                class="h-32 flex justify-center items-center">
                                                <ProductImage
                                                    src={item?.product_image}
                                                    alt={item?.name}
                                                    size="h-32 w-32"
                                                />
                                            </div>
                                            <div class="flex flex-col justify-center items-center pt-4 px-2">
                                                <div
                                                    onClick={() => globalActions.openProductModal(item)}
                                                    class="font-bold line-clamp-2 text-center text-base h-12 max-h-12">
                                                    {item?.name}
                                                </div>
                                                <span class="text-xs md:pt-2">
                                                    {t("from")} {item?.brand}
                                                </span>
                                            </div>
                                            <div class="md:w-2/3 md:pt-2">
                                                {item.product_type === PRODUCT_TYPE_CONFIGURABLE ? (
                                                    <>
                                                        {item?.configurable_product_options ? (
                                                            <RecursiveOptionsContainer
                                                                options={item?.configurable_product_options}
                                                                formik={formik}
                                                                item={item}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <span class="font-bold text-xl pt-2">
                                              <PriceContainer formik={formik} item={item}/>
                                            </span>
                                            <button onClick={() => handleSwitchProduct(item.product_id)} type="button" className="px-4 py-2 rounded-md text-sm text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500 font-medium inline-flex items-center border-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2">
                                                <span>{t('switch')}</span>
                                            </button>
                                        </div>
                                    </>
                                </form>
                            </>
                        )
                    }
                }
            </Formik>

        </>
    );
};
