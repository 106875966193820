const state = {};
export const initialState = localStorage.getItem("adyenConfig")
    ? JSON.parse(localStorage.getItem("adyenConfig"))
    : state;

export const setAdyenConfig = (store, adyenConfig) => {
    store.setState(
        {
            adyenConfig: {
                ...store.state.adyenConfig,
                ...adyenConfig,
            },
        },
        () =>
            localStorage.setItem("adyenConfig", JSON.stringify(store.state.adyenConfig))
    );
};

export const initAdyenConfig = (store) => {
    store.setState(
        {
            adyenConfig: {},
        },
        () =>
            localStorage.setItem("adyenConfig", JSON.stringify(store.state.adyenConfig))
    );
};
