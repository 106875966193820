import BigNumber from "bignumber.js";
import { IS_GRAVIUR, PRODUCT_TYPE_CONFIGURABLE } from "../constants/common";

const state = {
  items: {},
};

export const initialState = localStorage.getItem("fakeCart")
  ? JSON.parse(localStorage.getItem("fakeCart"))
  : state;

export const setFakeCart = (store, fakeCart) => {
  store.setState(
    {
      fakeCart: {
        ...store.state.fakeCart,
        ...fakeCart,
      },
    },
    () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
  );
};

export const initFakeCart = (store) => {
  store.setState(
    {
      fakeCart: { items: {} },
    },
    () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
  );
};

function calculatePriceTier(qty, tier_prices) {
  let selectedTier;
  for (let index = 0; index < tier_prices.length; index++) {
    const tier_price = tier_prices[index];
    if (qty >= Math.floor(tier_price.price_qty)) {
      selectedTier = tier_price;
    }
  }
  return selectedTier;
}

const getFinalPrice = (item, values, qty) => {
  const product = item.product_simples.find((product_simple) => {
    let find = true;
    for (
      let index = 0;
      index < product_simple.product_custom_attributes.length;
      index++
    ) {
      const element = product_simple.product_custom_attributes[index];
      if (element.value !== values[element.attribute_code]) {
        return false;
      }
    }

    return find;
  });

  if (!product) {
    return false;
  }

  // Handle Tier Prices
  const tierPrice = calculatePriceTier(qty, product.tier_prices);
  if (tierPrice) {
    if (values.is_graviur === IS_GRAVIUR) {
      // Gravur
      const gravurOption = item.product_custom_options.find(
        (custom_option) => custom_option.type === "field"
      );
      const price = new BigNumber(tierPrice.price).plus(
        new BigNumber(gravurOption.price)
      );
      return price.toFormat(2).toString();
    }

    const price = new BigNumber(tierPrice.price);
    return price.toFormat(2).toString();
  }

  if (values.is_graviur === IS_GRAVIUR) {
    // Gravur
    const gravurOption = item.product_custom_options.find(
      (custom_option) => custom_option.type === "field"
    );
    const price = new BigNumber(product.product_price).plus(
      new BigNumber(gravurOption.price)
    );
    return price.toFormat(2).toString();
  }

  const price = new BigNumber(product.product_price);
  return price.toFormat(2).toString();
};

export const addToCart = (store, item) => {
  const { fakeCart } = store.state;
  const { items } = fakeCart;

  if (items[item.id]) {
    items[item.id].qty = items[item.id].qty + 1;
    if (items[item.id].type === PRODUCT_TYPE_CONFIGURABLE) {
      const finalPrice = getFinalPrice(
        item.product,
        items[item.id].options,
        items[item.id].qty
      );
      if (finalPrice) {
        items[item.id].price = finalPrice;
      }
    } else {
      // Handle Tier Price
      const tierPrice = calculatePriceTier(
        items[item.id].qty,
        item.product.tier_prices
      );
      if (false) {
        items[item.id].price = new BigNumber(tierPrice.price).toFormat(2).toString();
      } else {
        items[item.id].price = new BigNumber(
          item.product.product_price
        ).toFormat(2).toString();
      }
    }
  } else {
    items[item.id] = { ...item };
  }

  store.setState(
    {
      fakeCart: {
        ...store.state.fakeCart,
        items,
      },
    },
    () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
  );
};

export const addOptionsFromCart = (store, itemId, price, selectedOptions) => {
  const { fakeCart } = store.state;
  const { items } = fakeCart;

  if (items[itemId]) {
    if (items[itemId].type === PRODUCT_TYPE_CONFIGURABLE) {
      const { options } = items[itemId];
      items[itemId].options = {
        ...options,
        ...selectedOptions,
      };
      const finalPrice = getFinalPrice(
        items[itemId].product,
        items[itemId].options,
        items[itemId].qty
      );
      if (finalPrice) {
        items[itemId].price = finalPrice;
      } else {
        items[itemId].price = price;
      }
    }
  }

  store.setState(
    {
      fakeCart: {
        ...store.state.fakeCart,
        items,
      },
    },
    () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
  );
};

export const changeOptionsFromCart = (store, itemId, selectedOptions) => {
  const { fakeCart } = store.state;
  const { items } = fakeCart;

  if (items[itemId]) {
    if (items[itemId].type === PRODUCT_TYPE_CONFIGURABLE) {
      const { options } = items[itemId];
      items[itemId].options = {
        ...options,
        ...selectedOptions,
      };
    }
  }

  store.setState(
    {
      fakeCart: {
        ...store.state.fakeCart,
        items,
      },
    },
    () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
  );
};

export const minusFromCart = (store, item) => {
  const { calcusoAI, fakeCart } = store.state;
  const { items } = fakeCart;
  const { simpleItems } = calcusoAI;

  if (items[item.id]) {
    if (items[item.id].qty <= 1) {
      delete items[item.id];
      for (let index = 0; index < simpleItems.length; index++) {
        const simpleItem = simpleItems[index];
        const { productIds } = simpleItem;

        productIds.map((productId) => {
          if (productId === item.id) {
            simpleItems[index]['is_display'] = true;
          }
        });
      }

    } else {
      items[item.id].qty = items[item.id].qty - 1;
      if (items[item.id].type === PRODUCT_TYPE_CONFIGURABLE) {
        const finalPrice = getFinalPrice(
          item.product,
          items[item.id].options,
          items[item.id].qty
        );
        if (finalPrice) {
          items[item.id].price = finalPrice;
        }
      } else {
        // Handle Tier Price
        const tierPrice = calculatePriceTier(
          items[item.id].qty,
          item.product.tier_prices
        );
        if (tierPrice) {
          items[item.id].price = new BigNumber(tierPrice.price).toFormat(2).toString();
        } else {
          items[item.id].price = new BigNumber(
            item.product.product_price
          ).toFormat(2).toString();
        }
      }
    }
  }

  store.setState(
    {
      fakeCart: {
        ...store.state.fakeCart,
        items,
      },
      calcusoAI: {
        ...store.state.calcusoAI,
        simpleItems,
      },
    },
    () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
  );
};

export const removeFromCart = (store, item) => {
  const { fakeCart } = store.state;
  const { items } = fakeCart;

  delete items[item.id];

  store.setState(
    {
      fakeCart: {
        ...store.state.fakeCart,
        items,
      },
    },
    () => localStorage.setItem("fakeCart", JSON.stringify(store.state.fakeCart))
  );
};
