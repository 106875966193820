import React from "react";
import { useTranslation } from "react-i18next";

export default function Gravur({ value }) {
  const { t } = useTranslation();
  return (
    <p className="mt-1 text-xs text-gunmetal-black-500">
      <span className="uppercase ">{t("Engraving")}: </span>{" "}
      <span className="font-medium">{value}</span>
    </p>
  );
}
