import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import Loading from "../Loading";
import classNames from "classnames";
import PLACEHOLDER from "../../images/calcuso-ai/placeholder_image.png";
import {ChevronDownIcon, PlusSmIcon} from "@heroicons/react/outline";
import SecondaryButton from "../SecondaryButton";
import useGlobal from "../../state/useGlobal";

export default function NotFoundProduct({aiItem}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [isSwitching, setIsSwitching] = useState(false);

    return (<>
            <div className="flex flex-col space-y-1 relative">
                <div className={classNames({
                    'absolute': true, 'transition-opacity duration-300 opacity-0 -z-10': !isSwitching, 'top-0 left-0 w-full h-full transition-opacity duration-200 opacity-100': isSwitching,
                })}>
                    <Loading/>
                </div>
                <div className={classNames({
                    'grid grid-cols-3': true, 'transition-opacity duration-100 opacity-0': isSwitching, 'transition-opacity duration-300 opacity-100': !isSwitching,
                })}>
                    <div class="col-span-1 flex justify-center items-center relative">
                        <div
                            class="flex justify-center items-center"
                        >
                            <img src={PLACEHOLDER} alt="image" className={classNames({
                                "flex-shrink-0 object-contain sentry-unblock cursor-pointer text-gray-300 w-28": true,
                            })}/>
                        </div>
                    </div>
                    <div class="pl-2 col-span-2 flex flex-col text-cal-primary-gunmetal-black-202833 space-y-1 md:relative">
                        <span class="font-bold text-base">
                            {t("No product available")}
                        </span>
                        <div class="text-sm">
                            {t(`We do not currently carry "{{text}}"`, {
                                text: aiItem?.text,
                            })}
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-3">
                    <div
                        className="col-span-1 flex justify-center items-center cursor-pointer"
                    >
                        <span
                            class="disabled font-medium text-gray-600 flex justify-center items-center space-x-2 relative text-sm md:text-base">
                                 <span>
                                    {t('Alternatives')}
                                 </span>
                             <ChevronDownIcon className="absolute w-5 h-5 -right-6"/>
                            </span>
                    </div>
                    <div class="col-span-2 flex justify-end md:pr-4 pr-2 md:justify-center items-center">
                        {globalState?.teacherEdition?.is_teacher ? (
                                <SecondaryButton
                                    type="button"
                                    color="w-40 flex justify-center disable text-gray-600 bg-transparent border-gray-600"
                                    size="base"
                                >
                                    <span>{t("add")}</span>
                                </SecondaryButton>
                            ) :
                            (
                                <SecondaryButton
                                    disabled={true}
                                    type="button"
                                    color="disable text-gray-600 bg-transparent border-gray-600"
                                    size={window.innerWidth > 640 ? "base" : "xs"}
                                >
                                    <PlusSmIcon
                                        className="w-5 h-5 mr-3 -ml-1 text-gray-600"
                                        aria-hidden="true"
                                    />
                                    <span>{t("add")}</span>
                                </SecondaryButton>)
                        }
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </>

    );
};
