import React from 'react';
import classNames from "classnames";
import Loading from "../../PlaceHolderLoading";
import {IsAddItemToCart} from "../../../services/helpers";
import {CheckIcon} from "@heroicons/react/solid";
import NotFoundProduct from "../NotFoundProduct";
import CardProduct from "../CardProduct/CardProduct";
import ISBNPlaceHolderLoading from "../../PlaceHolderLoading/ISBNPlaceHolderLoading";
import {useTranslation} from "react-i18next";

export default function CardItem({isLoadingISBN, isLoading, index, currentCardIndex, item, toggleCardVisibility, displayProductCard, cartItem}) {
    const {t} = useTranslation();

    return (
        <div key={index}
             className="px-4 py-2 w-full rounded-lg md:px-8 md:pt-2 md:max-w-7xl relative">
            <div>
                <div className={classNames({
                    "rounded-md py-2 relative": true,
                    "bg-white": item?.main_product !== null,
                    "bg-gray-100": item?.main_product === null
                })}>
                    <div className={classNames({
                        "absolute inset-y-0 inset-x-0 w-full h-5/6": true,
                    })} style={{visibility: currentCardIndex < index || isLoading ? "visible" : "hidden"}}>
                        <Loading item={item}/>
                    </div>
                    <div className={currentCardIndex >= index ? "animate-fade-in-top-to-bottom" : ""}
                         style={{visibility: currentCardIndex >= index && !isLoading ? "visible" : "hidden"}}>
                        <div
                            onClick={() => toggleCardVisibility(item.item_id)}
                            id={displayProductCard(index) ? null : `product-card-${index}`}
                        >
                            <div class="flex justify-between ">
                                <div class="flex space-x-2">
                                    <div>
                                        <div class="pl-2 flex md:h-full items-center">
                                            <div className={classNames({
                                                "flex justify-center items-center w-9 h-9 rounded-full font-medium": true,
                                                "bg-cal-primary-celadon-green-E5F8F7": item?.main_product !== null,
                                                "bg-gray-100": item?.main_product === null
                                            })}>
                                                <span
                                                    className="font-bold text-xl">{index + 1}
                                                </span>
                                            </div>
                                            <div className={classNames({
                                                "w-80 md:w-full truncate pl-2": true,
                                                "absolute": !isLoading,
                                            })} style={{display: isLoading ? "block" : "none"}}>
                                                <span className="md:text-base text-sm font-bold"> {item.text}</span>
                                            </div>
                                            <div class="pl-2" style={{display: !isLoading ? "block" : "none"}}>
                                                <span className="md:text-base text-sm font-bold">
                                                    {item.main_product === null && item?.status === 'pending' && isLoadingISBN ? (
                                                        <div class="animate-pulse">{t('Finding book')}...</div>
                                                    ) : (item.product_tag_name)}

                                                    {item.product_tag_detected === 'isbn' && (
                                                        <span className={"ml-1"}>
                                                            - {item.isbn}
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="flex justify-center items-center h-full">
                                        <div className={classNames({
                                            "p-1 rounded-md bg-cal-primary-celadon-green-01A7A3 text-cal-primary-floral-white-FEF9EF md:w-8 md:h-8 w-6 h-6 mx-2": true,
                                            "transition-opacity duration-700 opacity-100 block": IsAddItemToCart(item?.main_product?.product_id, cartItem) && !displayProductCard(index),
                                            "transition-opacity duration-500 opacity-0 hidden": !(IsAddItemToCart(item?.main_product?.product_id, cartItem) && !displayProductCard(index)),
                                        })}>
                                            <CheckIcon
                                                class="md:w-6 md:h-6 w-4 h-4"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={classNames({
                                "max-h-screen": displayProductCard(index),
                                "max-h-0 overflow-hidden": !displayProductCard(index)
                            })}
                                 style={{transition: 'max-height 0.6s ease-in-out'}}>
                                <div class="h-full pl-12 flex pb-2">
                                    <span className="text-xs italic text-cal-primary-gunmetal-black-989898">
                                        "{item.text}"
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className={classNames({
                                    "absolute inset-y-0 inset-x-0 w-full h-5/6 pt-4 md:pt-14": true,
                                })} style={{visibility: item.product_tag_detected === 'isbn' && item?.status === 'pending' && !isLoading && item.main_product === null && isLoadingISBN ? "visible" : "hidden"}}>
                                    <ISBNPlaceHolderLoading item={item}/>
                                </div>
                                <div className={currentCardIndex >= index ? "animate-fade-in-top-to-bottom" : ""}
                                     style={{visibility: (item.product_tag_detected !== 'isbn' || item?.status !== 'pending' || item.main_product !== null ||!isLoadingISBN) && (currentCardIndex >= index && !isLoading) ? "visible" : "hidden"}}>
                                    <div className={classNames({
                                        "max-h-screen": displayProductCard(index),
                                        "max-h-0 overflow-hidden": !displayProductCard(index),
                                    })}
                                         style={{transition: 'max-height 0.6s ease-in-out'}}
                                         id={'product-card-' + index}>
                                        <div class="mx-2 pb-2">
                                            {!isLoading && item?.main_product !== null ? (
                                                <CardProduct item={item} indexItem={index}/>
                                            ) : (
                                                <NotFoundProduct aiItem={item}/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
