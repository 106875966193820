import React, {useEffect, useRef, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Trans, useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import Loading from "../../../components/Loading";
import {Helmet} from "react-helmet";
import HeaderAI from "../../../components/AI/Header/Header";
import CheckoutSteps from "../../../components/CheckoutSteps";
import PaymentMethods from "../../ConfirmPaymentScreen/PaymentMethods";
import Config from "../../../config";
import {ChevronUpIcon} from "@heroicons/react/solid";
import classNames from "classnames";
import {Transition} from "@headlessui/react";
import BigNumber from "bignumber.js";
import {Link} from "react-router-dom";
import {STEP_CHECKOUT_PAYMENT} from "../../../constants/checkoutSteps";
import toast from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";
import mixpanel from "mixpanel-browser";

export default function PaymentScreen() {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();

    const {checkout} = globalState;
    const {quote_id} = checkout;
    const {totals} = checkout;
    const {shipping, billing} = checkout;

    const [isLoading, setIsLoading] = useState(true);
    const [openOverView, setOpenOverView] = useState(false);
    const recaptchaRef = useRef(null);

    useEffect(async () => {
        const calcusoAI = globalState.calcusoAI;
        const calcusoAIShare = globalState.calcusoAIShare;
        mixpanel.track_pageview({
            "page": "payment",
            "uuid": calcusoAI.uuid ?? null,
            "refer_code": calcusoAIShare.refer_code ?? null
        })

        await globalActions.loadPaymentMethods();

        globalActions.setCheckout({
            currentCheckoutStep: STEP_CHECKOUT_PAYMENT,
        });

        setIsLoading(false);

        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(currentUrl);
        const maskedId = urlParams.get('masked-id');
        const toErrorPage = urlParams.get('to-error-page');
        const oldPaymentFailed = urlParams.get('payment-method');

        if (toErrorPage === '1') {
            window.location.href = '/checkout/error';
            return;
        }

        if (maskedId !== null) {
            if (maskedId !== quote_id) {
                window.location.href = '/checkout/error';
                return;
            } else {
                if (oldPaymentFailed !== null && oldPaymentFailed !== '') {
                    toast.error(t('{{payment}} has rejected the purchase. Please select another payment method.', {payment: oldPaymentFailed}));
                } else {
                    toast.error(t('Your payment failed, Please try again later.'));
                }
            }
        }

        const isMobile = window.innerWidth <= 768;
        setOpenOverView(!isMobile);
    }, []);

    let links = Config.links[1] || {
        LEGAL_NOTICE: "https://www.calcuso.de/impressum/",
        PRIVACY: "https://www.calcuso.de/datenschutz",
        TERMS_OF_USE: "https://www.calcuso.de/agb",
        CANCEL_POLICY: "https://www.calcuso.de/widerruf",
    };

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Helmet>
                        <title>
                            {t("Payments")}
                        </title>
                        <script
                            src="https://www.paypalobjects.com/webstatic/ppplus/ppplus.min.js"
                            type="text/javascript"
                        ></script>
                    </Helmet>
                    <div className="flex flex-col h-full min-h-screen">
                        <HeaderAI confirm={true} title={('CALCUSO AI')} subTitle={t('Easy school order')}>
                            <CheckoutSteps/>
                        </HeaderAI>
                        <div className="flex flex-col justify-between flex-1 h-full">
                            <div className="flex justify-center flex-1">
                                <div className="w-full px-2 md:px-4 lg:w-5/6 xl:w-3/4 xl:px-8">
                                    <div className="px-4 pt-6 lg:pt-12">
                                        <div
                                            className="flex flex-col-reverse lg:grid lg:grid-cols-3 lg:gap-8 xl:gap-16">
                                            <div className="flex flex-col col-span-2 space-y-4">
                                                <PaymentMethods recaptchaRef={recaptchaRef}/>
                                                <div class="hidden">
                                                    <ReCAPTCHA
                                                        theme="dark"
                                                        style={{display: "inline-block"}}
                                                        ref={recaptchaRef}
                                                        sitekey={Config.siteCaptchaKey}
                                                        size="invisible"
                                                    />
                                                </div>

                                                <div className="border-t-2 border-celadon-green-light-500">
                                                    <p className="py-4 text-xs">
                                                        <Trans
                                                            i18nKey="checkoutUserMessagesUnread"
                                                            components={{
                                                                // eslint-disable-next-line
                                                                span1: <a href={links.PRIVACY}
                                                                          target="_blank"
                                                                          rel="noreferrer"/>,
                                                                // eslint-disable-next-line
                                                                span2: <a href={links.TERMS_OF_USE}
                                                                          target="_blank"
                                                                          rel="noreferrer"/>,
                                                                // eslint-disable-next-line
                                                                span3: <a href={links.CANCEL_POLICY}
                                                                          target="_blank"
                                                                          rel="noreferrer"/>,
                                                            }}
                                                        >
                                                            By placing an order at calcuso.com you're
                                                            agreeing to our{" "}
                                                            <span1 className="font-bold underline">
                                                                Privacy Policy
                                                            </span1>
                                                            ,{" "}
                                                            <span2 className="font-bold underline">
                                                                Terms and Conditions
                                                            </span2>
                                                            {" "}
                                                            and{" "}
                                                            <span3 className="font-bold underline">
                                                                Cancellation policy
                                                            </span3>
                                                            .
                                                        </Trans>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col pb-8 space-y-8">
                                                <div
                                                    className="flex items-center justify-between"
                                                    onClick={() => setOpenOverView(!openOverView)}
                                                >
                                                    <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                                                        {t("Order summary")}:
                                                    </h3>
                                                    <button
                                                        type="button"
                                                        className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"
                                                    >
                                                        <ChevronUpIcon
                                                            className={classNames({
                                                                "w-6 h-6": true,
                                                                "transform rotate-180": openOverView,
                                                            })}
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                </div>
                                                <Transition
                                                    show={openOverView}
                                                    enter="transition duration-100 ease-out"
                                                    enterFrom="transform scale-95 opacity-0"
                                                    enterTo="transform scale-100 opacity-100"
                                                    leave="transition duration-75 ease-out"
                                                    leaveFrom="transform scale-100 opacity-100"
                                                    leaveTo="transform scale-95 opacity-0"
                                                    className="space-y-8"
                                                >
                                                    <div class="space-y-4">
                                                        <ul className={classNames({
                                                            "border-t-2 divide-y-2 divide-celadon-green-light-500 border-celadon-green-light-500": true,
                                                            "overflow-y-auto h-96 md:h-128": totals?.items.length > 5
                                                        })} >
                                                            {totals &&
                                                            totals?.items &&
                                                            totals?.items.length > 0 ? (
                                                                totals?.items.map((item) => (
                                                                    <li
                                                                        key={item.item_id}
                                                                        className="py-4 md:text-sm text-xs mr-1 sm:mr-2"
                                                                    >
                                                                        <div
                                                                            className="flex flex-col space-y-2">
                                                                            <p className="font-medium truncate">
                                                                                {item.name}
                                                                            </p>
                                                                            <div
                                                                                className="flex items-center justify-between">
                                                                                <p>
                                                                                    {t("{{qty}} piece", {
                                                                                        qty: item.qty,
                                                                                    })}
                                                                                </p>
                                                                                <p className="font-medium ">
                                                                                    {new BigNumber(
                                                                                        item.row_total_incl_tax
                                                                                    )
                                                                                        .toFormat(2)
                                                                                        .toString()}{" "}
                                                                                    €
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <li className="py-4 text-center">
                                                                    {t("No items")}
                                                                </li>
                                                            )}
                                                        </ul>
                                                        <ul class="border-t-2 divide-y-2 divide-celadon-green-light-500 border-celadon-green-light-500 mr-1 sm:mr-4">
                                                            <li className="py-4 md:text-sm text-xs text-center">
                                                                <div
                                                                    className="flex items-center justify-between space-x-4 ">
                                                                    <p className="font-medium ">
                                                                        {t("Shipping")}
                                                                    </p>
                                                                    {totals?.base_shipping_incl_tax === 0 ? (
                                                                        <p className="font-medium text-right">
                                                                            {t("free")}
                                                                        </p>
                                                                    ) : (
                                                                        <p className="font-medium text-right">
                                                                            {new BigNumber(
                                                                                totals?.base_shipping_incl_tax
                                                                                    ? totals?.base_shipping_incl_tax
                                                                                    : 0
                                                                            )
                                                                                .toFormat(2)
                                                                                .toString()}{" "}
                                                                            €
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </li>
                                                            <li className="py-4 text-center">
                                                                <div
                                                                    className="flex items-center justify-between space-x-4 ">
                                                                    <p className="font-medium ">
                                                                        {t("Total")}
                                                                    </p>
                                                                    <p className="font-medium text-right">
                                                                        {new BigNumber(
                                                                            totals?.base_grand_total
                                                                                ? totals?.base_grand_total
                                                                                : 0
                                                                        ).toFormat(2).toString()}{" "}
                                                                        €
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="space-y-2">
                                                        <div className="flex items-center justify-between ">
                                                            <p className="font-medium md:text-lg text-gunmetal-black-500">
                                                                {t("Delivery address")}
                                                            </p>
                                                            <Link
                                                                className="md:text-sm text-xs font-medium underline text-gunmetal-black-500"
                                                                to={`/checkout/address`}
                                                            >
                                                                {t("edit")}
                                                            </Link>
                                                        </div>
                                                        <div className="flex flex-col ">
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                                  {shipping?.street}
                                                                </span>
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                                {shipping?.company}
                                                              </span>
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                              {shipping?.postcode} {shipping?.city}
                                                            </span>
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                              {shipping?.country_id}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-2 ">
                                                        <div className="flex items-center justify-between ">
                                                            <p className="font-medium md:text-lg text-gunmetal-black-500">
                                                                {t("Billing address")}
                                                            </p>
                                                            <Link
                                                                className="md:text-sm text-xs font-medium underline text-gunmetal-black-500"
                                                                to={`/checkout/address`}
                                                            >
                                                                {t("edit")}
                                                            </Link>
                                                        </div>
                                                        <div className="flex flex-col ">
                                                            <span className="md:text-sm text-xs text-gunmetal-black-500">
                                                              {billing?.firstname} {billing?.lastname}
                                                            </span>
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                              {billing?.street}
                                                            </span>
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                              {billing?.company}
                                                            </span>
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                              {billing?.postcode} {billing?.city}
                                                            </span>
                                                            <span
                                                                className="md:text-sm text-xs text-gunmetal-black-500">
                                                              {billing?.country_id}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Transition>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AppLayout>
    )
};
