import React, {useEffect, useState} from "react";
import {RadioGroup} from "@headlessui/react";
import classNames from "classnames";
import {PAYMENT_METHOD_ICON} from "../../constants/paymentMethod";
import {useTranslation} from "react-i18next";
import useGlobal from "../../state/useGlobal";
import PaymentMethod from "./PaymentMethod";
import {HPP_PAYMENTS, HPP_PAYMENTS_NAME,} from "../../constants/adyenPayment";
import AdyenGoogleApplePayment from "./AdyenPayments/GoogleApplePayment";

const sorter = (a, b, arr) => {
    if (arr.some((item) => item.code === a.code)) {
        return -1;
    }
    if (arr.some((item) => item.code === b.code)) {
        return 1;
    }
    return a - b;
};

const prioritySort = (arr1, arr2) => {
    if (
        typeof arr1 != "undefined" &&
        arr1 != null &&
        arr1.length != null &&
        arr1.length > 0
    ) {
        arr1.sort((a, b) => sorter(a, b, arr2));
    }
};

export default function PaymentMethods({recaptchaRef}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {checkout} = globalState;
    const {payment_methods, payment, payment_methods_adyen} = checkout;

    const [newPaymentMethods, setNewPaymentMethods] = useState(payment_methods);

    useEffect(() => {
        const paymentMethods = payment_methods_adyen?.paymentMethodsResponse?.paymentMethods ?? [];

        // eslint-disable-next-line
        newPaymentMethods.map((payment_method) => {
            if (payment_method.code === 'adyen_hpp') {
                setNewPaymentMethods(newPaymentMethods.filter((payment_method) => (payment_method.code !== 'adyen_hpp')));
                // eslint-disable-next-line
                paymentMethods.map((payment) => {
                    if (HPP_PAYMENTS.includes(payment.type)) {
                        let paymentCofig = {
                            code: 'adyen_' + payment.type,
                            title: HPP_PAYMENTS_NAME[payment.type],
                        }
                        setNewPaymentMethods(newPaymentMethods => newPaymentMethods.concat(paymentCofig));
                    }
                });
            }
        })
    }, []) // eslint-disable-line

    // Sort Payments
    prioritySort(newPaymentMethods, [{code: "stripe_payments"}, {code: "adyen_cc"}]);

    // handle HPP Payments
    useEffect(() => {
        if (newPaymentMethods) {
            if (!payment || !newPaymentMethods.some((e) => e.code === payment)) {
                const [firstPaymentMethod] = newPaymentMethods;

                globalActions.setCheckout({
                    payment: firstPaymentMethod?.code,
                    paymentLabel: firstPaymentMethod?.title,
                });
            }
        }
    }, [globalActions, payment, newPaymentMethods]);

    return (
        <div className="flex flex-col space-y-4">
            <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                {t("Payment method")}:
            </h3>
            {<AdyenGoogleApplePayment recaptchaRef={recaptchaRef}/>}
            <RadioGroup
                className="border-t-2 border-celadon-green-light-500"
                value={payment}
                onChange={(selected) => {
                    const paymentMethod = newPaymentMethods.find(
                        (e) => e.code === selected
                    );

                    globalActions.setCheckout({
                        payment: selected,
                        paymentLabel: paymentMethod?.title,
                    });
                }}
            >
                <div className="relative -space-y-px divide-y-2 divide-celadon-green-light-500">
                    {newPaymentMethods &&
                        newPaymentMethods.map((payment_method) => (
                            <RadioGroup.Option
                                key={payment_method.code}
                                value={payment_method.code}
                                className={({checked}) =>
                                    classNames({
                                        "border-gray-200": !checked,
                                        "relative py-4 md:py-6 flex flex-col cursor-pointer focus:outline-none": true,
                                    })
                                }
                            >
                                {({active, checked}) => (
                                    <>
                                        <div className="flex items-center space-x-4">
                                            <div>
                        <span
                            className={classNames({
                                " border-gunmetal-black-500": checked,
                                " border-gunmetal-black-500 ": !checked,
                                "ring-2 ring-offset-2 ring-celadon-green-500": active,
                                "h-6 w-6 md:h-8 md:w-8 bg-floral-white-500 rounded-full border-2 flex items-center justify-center": true,
                            })}
                            aria-hidden="true"
                        >
                          <span
                              className={classNames({
                                  "bg-celadon-green-600": checked,
                                  "h-3 w-3 md:w-5 md:h-5 rounded-full ": true,
                              })}
                          />
                        </span>
                                            </div>
                                            <div className="flex items-center justify-between flex-1 ">
                                                <div className="md:flex md:text-lg font-medium text-gunmetal-black-500">
                                                    <RadioGroup.Label
                                                        as="span"
                                                        id={payment_method.code}
                                                        className={classNames({
                                                            "md:text-lg font-medium text-gunmetal-black-500": true,
                                                        })}
                                                    >
                                                        {t(payment_method.title)}
                                                    </RadioGroup.Label>
                                                </div>
                                                <RadioGroup.Description
                                                    className={classNames({
                                                        "text-sm text-gunmetal-black-500 text-right": true,
                                                    })}
                                                >
                                                    {PAYMENT_METHOD_ICON[payment_method.code]}
                                                </RadioGroup.Description>
                                            </div>
                                        </div>
                                        {checked ? (
                                            <PaymentMethod payment={payment_method.code} recaptchaRef={recaptchaRef}/>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                )}
                            </RadioGroup.Option>
                        ))}
                </div>
            </RadioGroup>
        </div>
    );
}
