import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, SelectorIcon} from "@heroicons/react/solid";
import classNames from "classnames";
import DE_LOGO from "../../countries/Deutschland.png";
import FR_LOGO from "../../countries/France.png";
import PT_LOGO from "../../countries/Portugal.png";
import NL_LOGO from "../../countries/Netherlands.png";
import IT_LOGO from "../../countries/Italia.png";
import ARABIC_LOGO from "../../countries/Arabic.png";
import EN_LOGO from "../../countries/English.png";
import UA_LOGO from "../../countries/Ukraine.png";

const languages = [
    {
        code: "de",
        name: "German",
        avatar: DE_LOGO,
    },
    {
        code: "fr",
        name: "French",
        avatar: FR_LOGO,
    },
    {
        code: "pt",
        name: "Portuguese",
        avatar: PT_LOGO,
    },
    {
        code: "nl",
        name: "Dutch",
        avatar: NL_LOGO,
    },
    {
        code: "it",
        name: "Italian",
        avatar: IT_LOGO,
    },
    {
        code: "ar",
        name: "Arabic",
        avatar: ARABIC_LOGO,
    },
    {
        code: "ua",
        name: "Ukrainian",
        avatar: UA_LOGO,
    },
    {
        code: "uk",
        name: "English",
        avatar: EN_LOGO,
    },
];

export default function LanguageSwitcher() {
    const {i18n} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage.code);
    }, [i18n, selectedLanguage]);

    const handleOnChange = (e) => {
        setSelectedLanguage(e);
        i18n.changeLanguage(e.code);
    };

    return (
        <Listbox value={selectedLanguage} onChange={handleOnChange}>
            {({open}) => (
                <div className="relative w-full">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-cal-primary-celadon-green-E5F8F7 border-2 border-cal-primary-celadon-green-00B5B1 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm">
            <span className="flex items-center">
              <img
                  src={selectedLanguage?.avatar}
                  alt=""
                  className="flex-shrink-0 w-6 h-6 rounded-full"
              />
              <span className="block ml-3 font-bold truncate">{selectedLanguage?.name}</span>
            </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
              />
            </span>
                    </Listbox.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            static
                            className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                        >
                            {languages.map((language) => (
                                <Listbox.Option
                                    key={language.code}
                                    className={({active}) =>
                                        classNames(
                                            "cursor-default select-none relative py-2 pl-3 pr-9",
                                            {
                                                "text-white bg-teal-600": active,
                                                "text-gray-900": !active,
                                            }
                                        )
                                    }
                                    value={language}
                                >
                                    {({selected, active}) => (
                                        <>
                                            <div className="flex items-center">
                                                <img
                                                    src={language.avatar}
                                                    alt=""
                                                    className="flex-shrink-0 w-6 h-6 rounded-full"
                                                />
                                                <span
                                                    className={classNames("ml-3 block truncate", {
                                                        "font-bold": selected,
                                                        "font-normal": !selected,
                                                    })}
                                                >
                                              {language.name}
                                            </span>
                                            </div>

                                            {selected ? (
                                                <span
                                                    className={classNames(
                                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                                        {
                                                            "text-white": active,
                                                            "text-teal-600": !active,
                                                        }
                                                    )}
                                                >
                          <CheckIcon className="w-5 h-5" aria-hidden="true"/>
                        </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            )}
        </Listbox>
    );
}
