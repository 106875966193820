import BigNumber from "bignumber.js";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";

export default function ShippingFee({values}) {
    const {t} = useTranslation();
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {checkout} = globalState;
    const [loading, setLoading] = useState(true);
    const [selectedShippingMethod, setSelectedShippingMethod] = useState({});
    const [availableShippingMethods, setAvailableShippingMethods] = useState([]);

    useEffect(async () => {
        async function fetchData() {
            setLoading(true);
            const payload = {
                address: {
                    ...values.shipping,
                    street: [values.shipping.street],
                    same_as_billing: 1,
                },
            };
            let data = await globalActions.loadShippingMethods(payload);
            setLoading(false);

            return data;
        }

        fetchData().then((data) => {
            setAvailableShippingMethods(data);

            let shippingCarrierCode = checkout.shippingCarrierCode;
            let shippingMethodCode = checkout.shippingMethodCode;

            let shippingMethod;
            if (shippingCarrierCode && shippingMethodCode) {
                shippingMethod = data.find(
                    (shipping) =>
                        shipping.carrier_code === shippingCarrierCode &&
                        shipping.method_code === shippingMethodCode
                );
            }

            if (!shippingMethod) {
                shippingMethod = data[0];
            }

            changeShippingMethod(shippingMethod);
        });
    }, [values.shipping]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeShippingMethod = (method) => {
        globalActions.setCheckout({
            ...checkout,
            shippingMethodCode: method.method_code,
            shippingCarrierCode: method.carrier_code,
        })
        setSelectedShippingMethod(method);
    };
    return (
        <>
            {loading ? (
                <div className="w-full pb-4 border-b-2 border-celadon-green-light-500">
                    <div className="flex space-x-4 animate-pulse">
                        <div className="flex-1 py-1 space-y-2">
                            <div className="h-4 rounded bg-floral-white-dark-500 "></div>
                            <div className="h-4 rounded bg-floral-white-dark-500"></div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div>
                        <div className="pb-4 border-b-2 border-celadon-green-light-500 ">
                            <div className="text-lg font-medium text-gunmetal-black-500">{t("Shipping Methods")}</div>
                            <div className="space-y-2 pt-4">
                                {availableShippingMethods?.length > 0 && availableShippingMethods.map((method, index) => (
                                    <div key={method.method_code} className="flex items-center">
                                        <input
                                            onClick={() => changeShippingMethod(method)}
                                            id={method.method_code}
                                            name="notification-method"
                                            type="radio"
                                            checked={selectedShippingMethod?.method_code === method.method_code}
                                            defaultChecked={index === 0}
                                            className="h-6 w-6 border-gray-300 text-teal-600 focus:ring-teal-600"
                                        />
                                        <label htmlFor={method.method_code}
                                               className="ml-3 block text-sm font-medium leading-6 text-gray-900 text-base">
                                            {method.method_title} {new BigNumber(method.amount).toFormat(2).toString()} €
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-between pb-4 border-b-2 border-celadon-green-light-500 ">
                        <p className="font-medium ">{t("Shipping costs")}</p>
                        <p className="font-medium text-right">
                            {selectedShippingMethod?.amount > 0
                                ? "+ " +
                                new BigNumber(selectedShippingMethod.amount).toFormat(2).toString() +
                                " €"
                                : t("free")}
                        </p>
                    </div>
                </>

            )}
        </>
    );
}
