export const initialState = {
  open: false,
  product: false,
  gravurText: "",
};

export const setGravurModal = (store, gravurModal) => {
  store.setState({
    gravurModal: {
      ...gravurModal,
    },
  });
};

export const openGravurModal = (store, product) => {
  store.setState({
    gravurModal: {
      ...store.state.gravurModal,
      open: true,
      product,
    },
  });
};

export const closeGravurModal = (store) => {
  store.setState({
    gravurModal: {
      ...store.state.gravurModal,
      open: false,
      gravurText: "",
    },
  });
};

export const setGravurTextGravurModal = (store, gravurText) => {
  store.setState({
    gravurModal: {
      ...store.state.gravurModal,
      gravurText,
    },
  });
};
