import React from "react";
import globalHook from "use-global-hook";

import {initGroupOrder, initialState as groupOrder, loadGroupOrder, loadGroupOrderWithProduct, loadShippingMethods, setGroupOrder,} from "./groupOrder";
import {initialState as store, setStore} from "./store";
import {initAddresses, initCheckout, initialState as checkout, loadPaymentMethods, loadPaymentMethodsAdyen, setCheckout} from "./checkout";
import {addOptionsFromCart, addToCart, changeOptionsFromCart, initFakeCart, initialState as fakeCart, minusFromCart, removeFromCart, setFakeCart,} from "./fakeCart";
import {closeProductModal, initialState as productModal, openProductModal, setProductModal,} from "./productModal";
import {closeCartModal, initialState as cartModal, openCartModal, setCartDataModal, setCartModal,} from "./cartModal";
import {closeFakeCartModal, initialState as fakeCartModal, openFakeCartModal, setFakeCartDataModal, setFakeCartModal,} from "./fakeCartModal";
import {closeGravurModal, initialState as gravurModal, openGravurModal, setGravurModal, setGravurTextGravurModal,} from "./gravurModal";
import {initialState as cart, loadCart, setCart} from "./cart";
import {closeConfirmModal, initialState as confirmModal, openConfirmModal, setConfirmModal,} from "./confirmModal";
import {initialState as multiBancoResponse, setResponseForMultiBanco} from "./multiBanco";
import {handleIsbnItem, initCalcusoAI, initialState as calcusoAI, isCallIsbnApi, reStructureCalcusoAI, setCalcusoAI, setIsAnalyzed, setIsDisplay, setStep, switchMainItem} from "./calcusoAiData";
import {initCalcusoAIShare, initialState as calcusoAIShare, setCalcusoAIShare} from "./calcusoAiShare";
import {initAdyenConfig, initialState as adyenConfig, setAdyenConfig} from "./aydenConfig";
import {initialState as teacherEdition, setTeacherState, initTeacherEdition, setTeacherEdition} from "./teacherEdition";
import {initialState as oldData, addPreviousUuid, initOldData, getPreviousUuid} from "./oldData";

const initialState = {
    groupOrder,
    store,
    checkout,
    productModal,
    cartModal,
    fakeCart,
    gravurModal,
    fakeCartModal,
    cart,
    confirmModal,
    multiBancoResponse,
    calcusoAI,
    adyenConfig,
    calcusoAIShare,
    teacherEdition,
    oldData
};

const actions = {
    loadGroupOrder,
    setGroupOrder,
    setStore,
    setCheckout,
    initCheckout,
    setProductModal,
    openProductModal,
    closeProductModal,
    setCartModal,
    openCartModal,
    closeCartModal,
    setCartDataModal,
    loadGroupOrderWithProduct,
    initGroupOrder,
    setFakeCart,
    initFakeCart,
    addToCart,
    removeFromCart,
    minusFromCart,
    addOptionsFromCart,
    setGravurModal,
    openGravurModal,
    closeGravurModal,
    setFakeCartModal,
    openFakeCartModal,
    closeFakeCartModal,
    setFakeCartDataModal,
    setCart,
    loadCart,
    loadShippingMethods,
    initAddresses,
    loadPaymentMethods,
    loadPaymentMethodsAdyen,
    changeOptionsFromCart,
    setConfirmModal,
    openConfirmModal,
    closeConfirmModal,
    setGravurTextGravurModal,
    setResponseForMultiBanco,
    setCalcusoAI,
    initCalcusoAI,
    reStructureCalcusoAI,
    initAdyenConfig,
    setAdyenConfig,
    setCalcusoAIShare,
    initCalcusoAIShare,
    setIsDisplay,
    switchMainItem,
    setIsAnalyzed,
    setTeacherEdition,
    initTeacherEdition,
    setTeacherState,
    setStep,
    handleIsbnItem,
    isCallIsbnApi,
    initOldData,
    addPreviousUuid,
    getPreviousUuid
};

const useGlobal = globalHook(React, initialState, actions);
export const connect = (Component) => {
    return (props) => {
        let [globalState, globalActions] = useGlobal();
        let _props = {...props, globalState, globalActions};
        return <Component {..._props} />;
    };
};

export default useGlobal;
