import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import useGlobal from "../state/useGlobal";
import ProductImage from "./ProductImage";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Badge from "./Badge";
import BADGE_CONSTANTS from "../constants/badge";
import BigNumber from "bignumber.js";
import EmptyIcon from "./Icons/EmptyIcon";

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
};

export default function ProductModal() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { productModal } = globalState;
  const { open, product } = productModal;

  const {
    name,
    product_price,
    description,
    brand,
    product_custom_options,
    product_images,
    product_image,
    product_tags,
    tier_prices,
  } = product;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50"
        open={open}
        onClose={globalActions.closeProductModal}
      >
        <div className="flex items-end justify-center min-h-screen overflow-hidden text-center md:px-4 md:pt-4 md:pb-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block w-full h-screen overflow-auto text-left align-bottom transition-all transform bg-white md:pb-4 md:px-4 md:rounded-lg md:shadow-xl max-w-prose sm:align-middle">
              <div className="sticky top-0 z-50 block p-4 bg-white md:pt-6 md:pb-4">
                <div className="flex justify-end ">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cal-primary-celadon-green-01A7A3"
                    onClick={globalActions.closeProductModal}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon
                      className="w-8 h-8 text-cal-primary-gunmetal-black-202833"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
              {product ? (
                <div className="min-w-full px-6 py-0 pb-8 m-auto space-y-4 md:py-8 md:p-8 md:pb-12">
                  {product_images && (
                    <>
                      {product_images.length > 1 ? (
                        <Carousel
                          showStatus={false}
                          showThumbs={false}
                          renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                              <button
                                type="button"
                                onClick={onClickHandler}
                                title={label}
                                style={{ ...arrowStyles, left: 15 }}
                              >
                                <ChevronLeftIcon className="w-8 h-8 text-gunmetal-black-500" />
                              </button>
                            )
                          }
                          renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                              <button
                                type="button"
                                onClick={onClickHandler}
                                title={label}
                                style={{ ...arrowStyles, right: 15 }}
                              >
                                <ChevronRightIcon className="w-8 h-8 text-gunmetal-black-500" />
                              </button>
                            )
                          }
                          renderIndicator={(
                            onClickHandler,
                            isSelected,
                            index,
                            label
                          ) => {
                            if (isSelected) {
                              return (
                                <li
                                  key={index}
                                  className="inline-block w-4 h-4 mx-2 border-2 rounded-full bg-celadon-green-600 border-celadon-green-600"
                                />
                              );
                            }
                            return (
                              <li
                                className="inline-block w-4 h-4 mx-2 border-2 rounded-full border-celadon-green-600"
                                onClick={onClickHandler}
                                onKeyDown={onClickHandler}
                                value={index}
                                key={index}
                              />
                            );
                          }}
                        >
                          {product_images.map((product_image, index) => (
                            <div key={index}>
                              <ProductImage
                                size="h-96"
                                src={product_image}
                                alt={name}
                              />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <div className="flex items-center justify-center w-full mb-4">
                          <ProductImage
                            size="h-96"
                            src={product_image}
                            alt={name}
                          />
                        </div>
                      )}
                    </>
                  )}

                  <div className="space-x-2 flex justify-center">
                    {product_tags.map((product_tag) => (
                      <Badge
                        key={product_tag.tag_value}
                        color={BADGE_CONSTANTS.color[product_tag.tag_value]}
                        colorText={
                          BADGE_CONSTANTS.colorText[product_tag.tag_value]
                        }
                      >
                        {BADGE_CONSTANTS.icon[product_tag.tag_value] ? BADGE_CONSTANTS.icon[product_tag.tag_value] : <EmptyIcon className="h-6"/>}
                        <span>{product_tag.tag_name}</span>
                      </Badge>
                    ))}
                  </div>

                  <div className="space-y-2 text-center">
                    <h2 className="text-base font-medium text-gray-900 md:text-lg">
                      {name}
                    </h2>
                    {brand ? (
                      <p className="text-xs">
                        {t("from")} {brand}
                      </p>
                    ) : (
                      ""
                    )}
                    <h2 className="text-base font-medium md:text-lg text-cal-primary-gunmetal-black-202833">
                      {new BigNumber(product_price).toFormat(2).toString()} €
                    </h2>
                    {tier_prices &&
                      tier_prices.map((tier_price) => (
                        <p
                          key={tier_price.price_id}
                          className="text-xs text-cal-primary-gunmetal-black-989898"
                        >
                          {t("from {{qty}} piece {{price}} €", {
                            qty: Math.floor(tier_price.price_qty),
                            price: new BigNumber(tier_price.price)
                              .toFormat(2)
                              .toString(),
                          })}
                        </p>
                      ))}
                  </div>

                  <div className="mt-8">
                    <div className="flex items-center">
                      <h4 className="flex-shrink-0 pr-4 text-xs font-semibold tracking-wider uppercase bg-white md:text-sm text-cal-primary-celadon-green-01A7A3">
                        {t("Description")}
                      </h4>
                      <div className="flex-1 border-t-2 border-gray-200" />
                    </div>
                    <div
                      className="mt-4 text-xs md:text-sm text-cal-primary-gunmetal-black-202833"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
