import BigNumber from "bignumber.js";
import React, {useEffect, useState} from "react";
import {IS_GRAVIUR, LIMIT_CART_CALCULATOR} from "../../../constants/common";
import useGlobal from "../../../state/useGlobal";
import {MinusIcon} from "@heroicons/react/outline";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import * as Sentry from "@sentry/react";

export default function PriceContainer({formik, item}) {
    /* eslint-disable no-unused-vars */
    const [globalState, globalActions] = useGlobal();
    const {t} = useTranslation();
    const {fakeCart, groupOrder} = globalState;
    const {calculator} = groupOrder;
    const {values} = formik;
    const {product_simples} = item;
    // get From Cart
    const {items} = fakeCart;
    const selectedItem = items[item.product_id];

    const [price, setPrice] = useState("");
    const [unSupported, setUnSupported] = useState(false);
    const [simpleProduct, setSimpleProduct] = useState(null);

    useEffect(() => {
        const getFinalPrice = (lastValues) => {
            const product = product_simples.find((product_simple) => {
                let find = true;
                for (
                    let index = 0;
                    index < product_simple.product_custom_attributes.length;
                    index++
                ) {
                    const element = product_simple.product_custom_attributes[index];
                    if (element.value !== lastValues[element.attribute_code]) {
                        return false;
                    }
                }

                return find;
            });

            if (!product) {
                // If Product In Cart
                if (selectedItem) {
                    return new BigNumber(selectedItem.price).toFormat(2).toString();
                }

                setUnSupported(true);
                Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
                Sentry.setUser({item, globalState});
                Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
                return;
            }

            setSimpleProduct(product);

            // If Product In Cart
            if (selectedItem) {
                return new BigNumber(selectedItem.price).toFormat(2).toString();
            }

            if (lastValues.is_graviur === IS_GRAVIUR) {
                // Gravur
                const gravurOption = item.product_custom_options.find(
                    (custom_option) => custom_option.type === "field"
                );
                const price = new BigNumber(product.product_price).plus(
                    new BigNumber(gravurOption.price)
                );
                return price.toFormat(2).toString();
            }

            const price = new BigNumber(product.product_price);
            return price.toFormat(2).toString();
        };

        setPrice(getFinalPrice(values));
    }, [values, selectedItem?.price]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnAdd = async () => {
        if (selectedItem?.id === calculator?.product_id) {
            if (selectedItem?.qty < LIMIT_CART_CALCULATOR) {
                globalActions.addToCart(selectedItem);
                toast.success(
                    t(`"{{product_name}}" has been added to your cart.`, {
                        product_name: selectedItem.name,
                    }),
                    {
                        id: selectedItem.id + "add",
                    }
                );
            }
        } else {
            globalActions.addToCart(selectedItem);
            toast.success(
                t(`"{{product_name}}" has been added to your cart.`, {
                    product_name: selectedItem.name,
                }),
                {
                    id: selectedItem.id + "add",
                }
            );
        }
    };

    const handleOnRemove = async () => {
        globalActions.minusFromCart(selectedItem);
        toast(
            t(`"{{product_name}}" has been removed from your cart.`, {
                product_name: selectedItem.name,
            }),
            {
                id: selectedItem.id + "remove",
                icon: <MinusIcon className="w-8 h-8 text-fiery-rose-600"/>,
            }
        );
    };
    return (
        <>
            {unSupported ? (
                <p>{t("Unsupported")}</p>
            ) : (
                <div className="">
                    <h2 className="text-lg font-medium md:text-xl text-cal-primary-gunmetal-black-202833">
                        {new BigNumber(price).toFormat(2).toString()} €
                    </h2>
                </div>
            )}
        </>
    );
}
