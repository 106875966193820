import * as Sentry from "@sentry/react";
import {STORE as M2_STOTE} from "../../../constants/storeCode";
import api from "../../../services/api";
import apiAdyen from "../../../services/apiAdyen";

export const getDefaultConfigAdyen = (globalState, config) => {
    try {
        const {adyenConfig, checkout} = globalState;
        const {store} = checkout;

        let locate = 'en-US';

        if (M2_STOTE[store.id] ?? null) {
            locate = M2_STOTE[store.id] + '-' + M2_STOTE[store.id].toUpperCase();
        }

        return {
            environment: adyenConfig.adyen_env,
            clientKey: adyenConfig.public_adyen_token,
            paymentMethodsResponse: checkout.payment_methods_adyen.paymentMethodsResponse,
            locale: locate,
            ...config
        };
    } catch (e) {
        Sentry.captureException(e);
    }
}

export const checkPaymentStatusAdyen = async (orderId) => {
    let formData = new FormData();
    formData.append('order_id', orderId);

    let response = await api.post(
        `/calcuso-core-order/adyen/orders/payment-status`,
        {},
        formData
    );

    return JSON.parse(response.data);
}


export const checkPaymentDetailsAdyen = async (globalState, paymentData) => {
    const {adyenConfig} = globalState;

    return await apiAdyen.post(
        `/checkoutshopper/services/PaymentInitiation/v1/status`,
        {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        {
            paymentData: paymentData
        }, {
            'clientKey': adyenConfig.public_adyen_token
        }
    );
}
