import React, {Component} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../../tailwind.output.css";
import * as Sentry from "@sentry/react";
import config from "../../config";

class ProductImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errored: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.src !== this.props.src) {
            this.setState({errored: false});
        }
    }

    handleError = () => {
        this.setState({errored: true});
        try {
            Sentry.captureMessage('Image not found at: ' + window.location.href);
        } catch (e) {
        }
    };

    render() {
        const {size} = this.props;

        if (this.state.errored) {
            return (
                <svg
                    className={classNames({
                        "w-full mx-auto text-gray-300 bg-white border border-gray-300": true,
                        "h-32": !size,
                        [`${size}`]: size,
                    })}
                    preserveAspectRatio="none"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 200 200"
                    aria-hidden="true"
                >
                    <path
                        vectorEffect="non-scaling-stroke"
                        strokeWidth="1"
                        d="M0 0l200 200M0 200L200 0"
                    ></path>
                </svg>
            );
        } else {
            return (
                <img
                    className={classNames({
                        "flex-shrink-0 object-contain sentry-unblock": true,
                        "h-32 mx-auto": !size,
                        [`${size}`]: size,
                    })}
                    src={`${config.cdn}${config.baseUrlMedia}${this.props.src}`}
                    alt={this.props.alt}
                    onError={this.handleError}
                />
            );
        }
    }
}

ProductImage.protoTypes = {
    size: PropTypes.string,
};
export default ProductImage;
