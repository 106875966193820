import React from 'react';
import {useTranslation} from "react-i18next";
import ProductImage from "../../ProductImage";
import useGlobal from "../../../state/useGlobal";
import BigNumber from "bignumber.js";

export default function SimpleProduct({item, handleSwitchProduct}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();

    return (
        <div>
            <div
                className="flex flex-col justify-between h-full border border-2 items-center px-2 py-4 rounded-md w-72 md:w-auto">
                <div>
                    <div
                        onClick={() => globalActions.openProductModal(item)}
                        class="h-32 flex justify-center items-center">
                        <ProductImage
                            src={item?.product_image}
                            alt={item?.name}
                            size="h-32 w-32"
                        />
                    </div>
                    <div class="flex flex-col justify-center items-center pt-4 px-2">
                        <div
                            onClick={() => globalActions.openProductModal(item)}
                            class="font-bold line-clamp-2 text-center md:text-base text-sm h-12 max-h-12">
                            {item?.name}
                        </div>
                        <span class="text-xs md:pt-4 mt-0">
                        {t('from')} {item?.brand}
                    </span>
                    </div>
                </div>

                <div class="flex flex-col justify-center items-center">
                     <span class="font-bold md:text-xl text-base">
                        {new BigNumber(item?.product_price).toFormat(2).toString()} €
                    </span>
                    <button onClick={() => handleSwitchProduct(item.product_id)} type="button" className="md:px-4 md:py-2 px-3 py-1 md:mt-0 mt-2 rounded-md text-sm text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500 font-medium inline-flex items-center border-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2">
                        <span>{t('switch')}</span>
                    </button>
                </div>

            </div>
        </div>
    );
};
