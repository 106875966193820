import Input from "../../Forms/Input";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";

export default function SchoolInfo({values}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();

    const findSchoolInformation = async (e) => {
        globalActions.setCheckout({
            ...globalState.checkout,
            school_info: {
                ...globalState.checkout.school_info,
                [e.target.name]: e.target.value
            }
        });
    }

    return (
        <div class="grid grid-cols-6 gap-6 pt-2">
            <div class="col-span-6">
                <Input
                    name="school_name"
                    label={t("Name of the school")}
                    placeholder={t("Name of the school")}
                    onChange={e => {
                        findSchoolInformation(e)
                    }}
                    size="lg"
                    value={values?.school_info?.school_name}
                />
            </div>
        </div>
    )
}