import React from "react";
import Input from "../../../components/Forms/Input";
import {Field} from "formik";
import {COUNTRIES, COUNTRIES_NL, OPTIONAL_ZIP_COUNTRIES} from "../../../constants/common";
import Select from "../../../components/Forms/Select/Select";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import ShippingFee from "./ShippingFee";
import {NL, PT} from "../../../constants/storeCode";
import ShipStep from "./ShippingStep";

export default function ShipForm({
  errors,
  touched,
  values,
  handleChange,
  handleBlur,
  isValid,
  setValues,
  isSubmitLoading,
}) {
  const { t } = useTranslation();
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { checkout } = globalState;
  const { store } = checkout;

  const _handleBlur = async (e) => {
    handleBlur(e);
    if (values.is_same_shipping) {
      setValues(
        (prevValues) => ({
          ...prevValues,
          billing: prevValues.shipping,
        }),
        true
      );
    }
  };

  return (
    <>
      <div className="py-4 space-y-2 border-t-2 border-b-2 border-celadon-green-light-500">
        <p className="text-lg font-medium text-gunmetal-black-500">
          {t("Delivery address")}
        </p>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 sm:col-span-3">
            <Input
              name="shipping.firstname"
              label={t("First Name")}
              placeholder={t("First Name")}
              size="lg"
              onChange={handleChange}
              onBlur={_handleBlur}
              value={values.shipping.firstname}
              required
              error={errors?.shipping?.firstname}
              errorMessage={errors?.shipping?.firstname}
            />
          </div>

          <div className="col-span-6 sm:col-span-3">
            <Input
              name="shipping.lastname"
              label={t("Last Name")}
              placeholder={t("Last Name")}
              size="lg"
              onChange={handleChange}
              onBlur={_handleBlur}
              value={values.shipping.lastname}
              required
              error={errors?.shipping?.lastname}
              errorMessage={errors?.shipping?.lastname}
            />
          </div>

          <div className="col-span-6">
            <Input
              name="shipping.street"
              label={t("Street Address")}
              placeholder={t("Street Address")}
              size="lg"
              onChange={handleChange}
              onBlur={_handleBlur}
              value={values.shipping.street}
              required
              error={errors?.shipping?.street}
              errorMessage={errors?.shipping?.street}
            />
          </div>

          <div className="col-span-6">
            <Input
              name="shipping.company"
              label={t("Additional info")}
              placeholder={t("Additional info")}
              size="lg"
              onChange={handleChange}
              onBlur={_handleBlur}
              value={values.shipping.company}
              error={errors?.shipping?.company}
              errorMessage={errors?.shipping?.company}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              name="shipping.postcode"
              label={t("ZIP / Postal")}
              placeholder={t("ZIP / Postal")}
              size="lg"
              onChange={e => {
                  handleChange(e);
                  setValues(
                      (prevValues) => ({
                          ...prevValues,
                          shipping: {
                              ...values.shipping,
                              postcode: e.target.value.trim(),
                          },
                      }),
                      true
                  )
              }}
              onBlur={_handleBlur}
              value={values.shipping.postcode}
              required={
                !OPTIONAL_ZIP_COUNTRIES.includes(values.shipping.country_id)
              }
              error={errors?.shipping?.postcode}
              errorMessage={errors?.shipping?.postcode}
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input
              name="shipping.city"
              label={t("City")}
              placeholder={t("City")}
              size="lg"
              onChange={handleChange}
              onBlur={_handleBlur}
              value={values.shipping.city}
              required
              error={errors?.shipping?.city}
              errorMessage={errors?.shipping?.city}
            />
          </div>



          <div className="col-span-6 sm:col-span-3">
              {store && store?.code === NL ? (
                  <Select
                      name="shipping.country_id"
                      label={t("Country")}
                      options={COUNTRIES_NL}
                      onChange={handleChange}
                      onBlur={_handleBlur}
                      value={values.shipping.country_id}
                      required
                      error={errors?.shipping?.country_id}
                      errorMessage={errors?.shipping?.country_id}
                  />
              ) : (
                  <Select
                      name="shipping.country_id"
                      label={t("Country")}
                      options={COUNTRIES}
                      onChange={handleChange}
                      onBlur={_handleBlur}
                      value={values.shipping.country_id}
                      required
                      error={errors?.shipping?.country_id}
                      errorMessage={errors?.shipping?.country_id}
                  />
              )}
          </div>
          {store && store?.code === PT ? (
            <div className="col-span-6 sm:col-span-3">
              <Input
                name="shipping.vat_id"
                label={t("VAT")}
                placeholder={t("VAT")}
                size="lg"
                onChange={handleChange}
                onBlur={_handleBlur}
                value={values.shipping.vat_id}
                error={errors?.shipping?.vat_id}
                errorMessage={errors?.shipping?.vat_id}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="pb-6 space-y-4 border-b-2 border-celadon-green-light-500">
        <p className="text-lg font-medium text-gunmetal-black-500">
          {t("Billing address")}
        </p>
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <Field
              type="checkbox"
              onChange={(e) => {
                const { value } = e.target;
                
                if (value === "false") {
                  setValues(
                    (prevValues) => ({
                      ...prevValues,
                      billing: values.shipping,
                    }),
                    true
                  );
                } else {
                  setValues(
                    (prevValues) => ({
                      ...prevValues,
                      billing: {
                        firstname: "",
                        lastname: "",
                        street: "",
                        city: "",
                        postcode: "",
                        country_id: "DE",
                        vat_id: "",
                      },
                    }),
                    true
                  );
                }
                handleChange(e);
              }}
              name="is_same_shipping"
              id="is_same_shipping"
              aria-describedby="is_same_shipping-description"
              className="w-4 h-4 border-gray-300 rounded text-celadon-green-600 focus:ring-celadon-green-500"
            />
          </div>
          <div className="ml-3 ">
            <label
              htmlFor="is_same_shipping"
              className=" text-gunmetal-black-700"
            >
              {t("Same as shipping information")}
            </label>
          </div>
        </div>
        {!values.is_same_shipping ? (
          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                name="billing.firstname"
                label={t("First Name")}
                placeholder={t("First Name")}
                size="lg"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billing.firstname}
                required
                error={errors?.billing?.firstname}
                errorMessage={errors?.billing?.firstname}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Input
                name="billing.lastname"
                label={t("Last Name")}
                placeholder={t("Last Name")}
                size="lg"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billing.lastname}
                required
                error={errors?.billing?.lastname}
                errorMessage={errors?.billing?.lastname}
              />
            </div>

            <div className="col-span-6">
              <Input
                name="billing.street"
                label={t("Street Address")}
                placeholder={t("Street Address")}
                size="lg"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billing.street}
                required
                error={errors?.billing?.street}
                errorMessage={errors?.billing?.street}
              />
            </div>

            <div className="col-span-6">
              <Input
                name="billing.company"
                label={t("Additional info")}
                placeholder={t("Additional info")}
                size="lg"
                onChange={handleChange}
                onBlur={_handleBlur}
                value={values.billing.company}
                error={errors?.billing?.company}
                errorMessage={errors?.billing?.company}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                name="billing.postcode"
                label={t("ZIP / Postal")}
                placeholder={t("ZIP / Postal")}
                size="lg"
                onChange={e => {
                    handleChange(e);
                    setValues(
                        (prevValues) => ({
                            ...prevValues,
                            billing: {
                                ...values.billing,
                                postcode: e.target.value.trim(),
                            },
                        }),
                        true
                    )
                }}
                onBlur={handleBlur}
                value={values.billing.postcode}
                required={
                  !OPTIONAL_ZIP_COUNTRIES.includes(values.billing.country_id)
                }
                error={errors?.billing?.postcode}
                errorMessage={errors?.billing?.postcode}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Input
                name="billing.city"
                label={t("City")}
                placeholder={t("City")}
                size="lg"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billing.city}
                required
                error={errors?.billing?.city}
                errorMessage={errors?.billing?.city}
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <Select
                name="billing.country_id"
                label={t("Country")}
                options={COUNTRIES}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billing.country_id}
                required
                error={errors?.billing?.country_id}
                errorMessage={errors?.billing?.country_id}
              />
            </div>
            {store && store?.code === PT ? (
              <div className="col-span-6 sm:col-span-3">
                <Input
                  name="billing.vat_id"
                  label={t("VAT")}
                  placeholder={t("VAT")}
                  size="lg"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billing.vat_id}
                  error={errors?.billing?.vat_id}
                  errorMessage={errors?.billing?.vat_id}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <ShippingFee values={values} />
      <ShipStep
        isSubmitLoading={isSubmitLoading}
        isValid={isValid}
      />
    </>
  );
}
