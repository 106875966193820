import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {CheckIcon, XIcon} from "@heroicons/react/outline";
import {Dialog, Transition} from '@headlessui/react'
import useGlobal from "../../../state/useGlobal";
import classNames from "classnames";

export default function SideBarMenu({open, setOpen}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {calcusoAI} = globalState;
    const {simpleItems} = calcusoAI;
    const [cartItem, setCartItem] = useState(false);

    const handleScroll = (index) => {
        const element = document.getElementById(`product-card-${index}`);
        element.scrollIntoView({behavior: "smooth", block: "center"});
        setOpen(false);
    }

    useEffect(() => {
        const {fakeCart} = globalState;
        const {items} = fakeCart;
        setCartItem(items);
    }, []);

    const isAddToCart = (index) => {
        const aiItem = simpleItems[index];
        const {productIds} = aiItem;
        let inCart = false;

        let cartValues = Object.values(cartItem);

        cartValues.map((item) => {
            productIds.map((productId) => {
                if (item.id === productId) {
                    inCart = true;
                }
            });
        });

        return inCart;
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-100 bg-opacity-50 transition-opacity"/>
                </Transition.Child>
                <div className="fixed inset-0"/>
                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-24">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div
                                        className="flex h-full flex-col bg-white py-4 shadow-xl bg-cal-primary-floral-white-FEF9EF relative">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title
                                                    className="text-base font-semibold leading-6 text-gray-900">
                                                    <div class="text-xl font-bold">
                                                        {t('Products on your parents letter')}
                                                    </div>
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center absolute right-1 top-1">
                                                        <span
                                                            className="relative rounded-md text-cal-primary-gunmetal-black-202833 cursor-pointer"
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            <XIcon className="h-8 w-8" aria-hidden="true"/>
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative flex-1 px-4 sm:px-6 overflow-auto">
                                            <ul class="text-base space-y-2 pt-6">
                                                {simpleItems?.length > 0 && simpleItems.map((item, index) => (
                                                    <li key={index}
                                                        onClick={() => handleScroll(index)}
                                                        className={classNames({
                                                            "text-cal-primary-gunmetal-black-989898": isAddToCart(index),
                                                            "flex space-x-2 relative cursor-pointer": true,
                                                            "text-gray-400": item?.productIds?.length === 0
                                                        })}>
                                                        <div class="flex flex-col">
                                                            <span class="font-medium">
                                                                {index + 1}. {item.product_tag_name}
                                                                {
                                                                    isAddToCart(index) && (
                                                                        <CheckIcon
                                                                            className="h-6 w-6 absolute top-0 right-0"/>
                                                                    )
                                                                }
                                                            </span>
                                                            <span className={classNames({
                                                                "text-xs pl-2 italic": true,
                                                                "text-gray-400": item?.productIds?.length === 0,
                                                                "text-cal-primary-gunmetal-black-989898": item?.productIds?.length > 0
                                                            })}>
                                                                "{item.text}"
                                                            </span>
                                                        </div>

                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};
