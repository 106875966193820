import React from "react";
import {
  CHECKOUT_STEPS,
  CHECKOUT_STEPS_LABEL,
  CHECKOUT_STEPS_URL,
  STEP_CHECKOUT_DONE,
} from "../constants/checkoutSteps";
import useGlobal from "../state/useGlobal";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function CheckoutSteps() {
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  const { t } = useTranslation();
  const { checkout } = globalState;
  const { currentCheckoutStep } = checkout;

  const steps = CHECKOUT_STEPS.map((checkoutStep, index) => {
    return {
      key: checkoutStep,
      name: CHECKOUT_STEPS_LABEL[checkoutStep],
      status:
        CHECKOUT_STEPS.indexOf(currentCheckoutStep) > index
          ? "complete"
          : checkoutStep === currentCheckoutStep
          ? "current"
          : "upcoming",
      url:
        currentCheckoutStep === STEP_CHECKOUT_DONE
          ? false
          : `/checkout/${
              CHECKOUT_STEPS_URL[checkoutStep]
            }`,
    };
  });

  return (
    <nav className="flex items-center justify-center">
      <ol className="flex items-center">
        {steps.map((step, stepIdx) => (
          <React.Fragment key={step.key}>
            <li key={step.name} className="w-24  md:w-28">
              {step.status === "complete" ? (
                <>
                  {step.url ? (
                    <Link to={step.url}>
                      <div className="flex flex-col items-center space-y-2">
                        <div className="flex items-center">
                          <div className="relative flex items-center justify-center w-6 h-6 rounded-full md:w-8 md:h-8 bg-celadon-green-600 hover:bg-celadon-green-900">
                            <span className="text-xs text-white">
                              {stepIdx + 1}
                            </span>
                          </div>
                        </div>
                        <span className="text-xs md:text-sm text-celadon-green-600">
                          {t(step.name)}
                        </span>
                      </div>
                    </Link>
                  ) : (
                    <div className="flex flex-col items-center space-y-2">
                      <div className="flex items-center">
                        <div className="relative flex items-center justify-center w-6 h-6 rounded-full md:w-8 md:h-8 bg-celadon-green-600 hover:bg-celadon-green-900">
                          <span className="text-xs text-white">
                            {stepIdx + 1}
                          </span>
                        </div>
                      </div>
                      <span className="text-xs md:text-sm text-celadon-green-600">
                        {t(step.name)}
                      </span>
                    </div>
                  )}
                </>
              ) : step.status === "current" ? (
                <div className="flex flex-col items-center space-y-2">
                  <div>
                    <div
                      className="relative flex items-center justify-center w-6 h-6 border-2 rounded-full md:w-8 md:h-8 border-celadon-green-600"
                      aria-current="step"
                    >
                      <span
                        className="h-2.5 w-2.5 bg-celadon-green-600 rounded-full"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{t(step.name)}</span>
                    </div>
                  </div>
                  <span className="text-xs md:text-sm text-celadon-green-600">
                    {t(step.name)}
                  </span>
                </div>
              ) : (
                <div className="flex flex-col items-center space-y-2">
                  <div>
                    <div className="relative flex items-center justify-center w-6 h-6 border-2 rounded-full border-celadon-green-600 md:w-8 md:h-8 group hover:border-celadon-green-900">
                      <span className="text-xs text-celadon-green-600">
                        {stepIdx + 1}
                      </span>
                    </div>
                  </div>
                  <span className="text-xs md:text-sm text-celadon-green-600">
                    {t(step.name)}
                  </span>
                </div>
              )}
            </li>
            {stepIdx !== steps.length - 1 ? (
              <li className="w-8 h-6 md:w-12 md:h-8">
                <div className="h-0.5 w-full bg-celadon-green-600" />
              </li>
            ) : (
              ""
            )}
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
}
