import React, {useEffect} from 'react';
import AppLayout from "../../../components/AppLayout";
import {Helmet} from "react-helmet";
import {useTranslation} from "react-i18next";
import {ExclamationIcon} from "@heroicons/react/outline";
import Button from "../../../components/Button";
import CheckoutSteps from "../../../components/CheckoutSteps";
import useGlobal from "../../../state/useGlobal";
import Config from "../../../config";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

export default function ErrorScreen() {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {checkout} = globalState;
    const {store} = checkout;

    useEffect(() => {

    }, []);

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            <Helmet>
                <title>
                    {t("Error")} | {t("Group Order")}
                </title>
            </Helmet>
            <div className="flex flex-col h-full min-h-screen">
                <Header title={t("Checkout")}>
                </Header>

                <div className="flex flex-col justify-between flex-1 h-full">
                    <div className="flex items-center justify-center flex-1">
                        <div className="w-full px-4 lg:w-3/4 xl:w-1/2 sm:px-6 lg:px-8">
                            <div className="px-6 pt-6 lg:px-8 lg:pt-12">
                                <div className="flex flex-col space-y-4 ">
                                    <div
                                        className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
                                        <ExclamationIcon
                                            className="w-6 h-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <h3 className="text-lg font-medium leading-6 text-gunmetal-black-500">
                                            Payment failed
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-lg text-gunmetal-black-500">
                                                {t(
                                                    "We were unable to process your payment. If the problem persists, contact us to complete your order."
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center pt-8 space-x-8">
                                        <Button
                                            type="a"
                                            href={Config.stores[store.id]}
                                            color="bg-gunmetal-black-600 hover:bg-gunmetal-black-700 focus:ring-gunmetal-black-500"
                                            size={window.innerWidth < 640 ? "base" : "xl"}
                                        >
                                            <span>{t("back to the shop")}</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <Footer/>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};
