import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import BADGE_CONSTANTS from "../../../constants/badge";
import EmptyIcon from "../../../components/Icons/EmptyIcon";
import Badge from "../../../components/Badge";
import ProductImage from "../../ProductImage";
import useGlobal from "../../../state/useGlobal";
import AlterativeProduct from "../CardProduct/AlterativeProduct";
import ButtonAddCart from "./ButtonAddCart";
import BigNumber from "bignumber.js";
import Loading from "../../Loading";
import classNames from "classnames";
import ButtonAddToGroupOrder from "../TeacherEdition/ButtonAddToGroupOrder";

export default function MainSimpleProduct({item, alterativeItems, aiItem, indexItem}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [globalState, globalActions] = useGlobal();
    const [isSwitching, setIsSwitching] = useState(false);
    const {teacherEdition} = globalState;
    const handleSwitchProduct = (productId) => {
        setOpen(false);
        setIsSwitching(true);

        setTimeout(() => {
            globalActions.switchMainItem(productId, aiItem.item_id);
        }, 500);

        setTimeout(() => {
            setIsSwitching(false);
        }, 1000);

        const element = document.getElementById(`product-card-${indexItem}`);
        element.scrollIntoView({behavior: "smooth", block: "end"});
    };

    return (<>
            <div className="flex flex-col space-y-2 relative">
                <div className={classNames({
                    'absolute': true, 'transition-opacity duration-300 opacity-0 -z-10': !isSwitching, 'top-0 left-0 w-full h-full transition-opacity duration-200 opacity-100': isSwitching,
                })}>
                    <Loading/>
                </div>
                <div className={classNames({
                    'grid grid-cols-3': true, 'transition-opacity duration-100 opacity-0': isSwitching, 'transition-opacity duration-300 opacity-100': !isSwitching,
                })}>
                    <div class="col-span-1 flex justify-center items-center relative">
                        <div
                            class="flex justify-center items-center"
                            onClick={() => globalActions.openProductModal(item)}
                        >
                            <ProductImage
                                src={item?.product_image}
                                alt={item?.name}
                                size="w-auto h-40 md:my-auto md:w-auto md:h-40 cursor-pointer"/>
                        </div>
                    </div>
                    <div class="pl-2 col-span-2 flex flex-col text-cal-primary-gunmetal-black-202833 space-y-1 md:relative">
                        {item?.product_tags?.length > 0 ? (<div>
                            {item?.product_tags.map((product_tag) => (<Badge
                                key={product_tag.tag_value}
                                color={BADGE_CONSTANTS.color[product_tag.tag_value]}
                                colorText={BADGE_CONSTANTS.colorText[product_tag.tag_value]}
                            >
                                {BADGE_CONSTANTS.icon[product_tag.tag_value] ? BADGE_CONSTANTS.icon[product_tag.tag_value] : <EmptyIcon className="h-6"/>}
                                <span>{product_tag.tag_name}</span>
                            </Badge>))}
                        </div>) : null}

                        <div class="flex flex-col">
                            <span
                                onClick={() => globalActions.openProductModal(item)}
                                class="font-bold md:text-base text-sm">
                                {item?.name}
                            </span>
                            <span class="text-xs">
                                {t('from')} {item?.brand}
                            </span>
                        </div>
                        <span class="font-bold md:text-xl text-base">
                            {new BigNumber(item?.product_price).toFormat(2).toString()} €
                        </span>
                    </div>
                </div>
                <div class="grid grid-cols-3 ">
                    <div
                        className="col-span-1 flex justify-center items-center cursor-pointer"
                    >
                        {alterativeItems?.length > 0 && (<span
                            onClick={() => setOpen(!open)}
                            class="font-medium cursor-pointer text-cal-primary-celadon-green-01A7A3 flex justify-center items-center space-x-2 relative text-sm md:text-base">
                                 <span>
                                    {t('Alternatives')}
                                 </span>
                            {open ? <ChevronUpIcon className="absolute w-5 h-5 -right-6"/> : <ChevronDownIcon className="absolute w-5 h-5 -right-6"/>}
                            </span>)}
                    </div>
                    <div class="col-span-2 flex justify-end md:justify-center md:pr-4 pr-2 items-center">
                        {teacherEdition?.is_teacher ? (
                                <ButtonAddToGroupOrder item={item} aiItem={aiItem}/>
                            ) :
                            (
                                <ButtonAddCart item={item} aiItem={aiItem}/>
                            )
                        }
                    </div>
                    <div>
                    </div>
                </div>
            </div>

            <AlterativeProduct alternativeItems={alterativeItems} open={open} handleSwitchProduct={handleSwitchProduct} isSwitching={isSwitching}/>
        </>

    );
};
