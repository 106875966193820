import React from 'react';
import SimpleProduct from "../SimpleProduct/SimpleProduct";
import ConfigProduct from "../ConfigProduct/ConfigProduct";

export default function AlterativeProduct({alternativeItems, open, handleSwitchProduct}) {
    return (
        <div>
            <div style={{maxHeight: open ? '1000px' : '0px', transition: 'max-height 0.5s ease'}} className="overflow-hidden">
                <div className="space-x-3 md:space-x-4 px-2 py-2 sm:p-4 overflow-x-auto flex flex-row">
                    {alternativeItems?.length > 0 && alternativeItems.map((item, index) => (
                        <div key={index} style={{width: '300px'}}>
                            {item.product_type === 'simple' ? (
                                <SimpleProduct item={item} handleSwitchProduct={handleSwitchProduct}/>
                            ) : (
                                <ConfigProduct item={item} handleSwitchProduct={handleSwitchProduct}/>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
