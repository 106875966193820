import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import Input from "../../Forms/Input";
import apiCRM from "../../../services/apiCRM";
import {Dialog, Transition} from '@headlessui/react'
import {CheckIcon} from '@heroicons/react/solid'
import toast from "react-hot-toast";
import history from "../../../routes/history";

const EMAIL_REGX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$|^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[^@\s]+\.[^@\s]+$/;

export default function Email({openEmailModal, setOpenEmailModal, onConfirm}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [email, setEmail] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);
    const [emailError, setEmailError] = useState('');

    const handleSubmitEmail = async () => {
        if (email === '') {
            setEmailError(t('Email is required'));
            return;
        }

        if (!EMAIL_REGX.test(email)) {
            setEmailError(t('Please enter a valid email address'));
            return;
        }

        setEmailError('');
        setButtonLoading(true);

        try {
            let response = await apiCRM.post('/ai/request/' + globalState.calcusoAI.uuid + '/set-email', {}, {
                'email': email
            });

            if (response.status === 200) {
                setButtonLoading(false);
                setOpenEmailModal(false);

                toast.success(t('Your email has been successfully saved. We will contact you shortly.'));
            } else {
                toast.error(t('An error occurred. Please try again later.'));
                setButtonLoading(false);
            }
        } catch (error) {
            toast.error(t('An error occurred. Please try again later.'));
            setButtonLoading(false);
        }
    };

    return (
        <Transition show={openEmailModal}>
            <Dialog className="relative z-50" onClose={() => setOpenEmailModal(false)}>
                <Transition.Child
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                            {t('Get 5€ cashback on your order!')}
                                        </Dialog.Title>
                                        <div className="mt-2 text-sm">
                                            <div>
                                                {t('We want you to get the most out of CALCUSO AI. That\'s why we would be happy if you would give us your email address for a one-time survey.')}
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div class="flex justify-center items-center space-x-2">
                                                <Input
                                                    name="customer_email"
                                                    placeholder={t("example@calcuso.com")}
                                                    onChange={e => {
                                                        setEmail(e.target.value);
                                                        setEmailError('');
                                                    }}
                                                    size="lg"
                                                />

                                                <button onClick={handleSubmitEmail} className="w-24 flex justify-center items-center font-bold text-gunmetal-black bg-cal-primary-celadon-green-01A7A3 text-cal-primary-floral-white-FEFCF6 mt-1 px-3 py-2.5 rounded-md">
                                                    {buttonLoading ? (
                                                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V2.5"></path>
                                                        </svg>
                                                    ) : (
                                                        t('Send')
                                                    )}
                                                </button>
                                            </div>

                                            {emailError && (
                                                <p className="text-red-500 text-xs mt-2">{emailError}</p>
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={() => {
                                            setOpenEmailModal(false);
                                            onConfirm();
                                        }}
                                    >
                                        {t('Still waiting')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
