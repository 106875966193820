const state = {
    uuid: null,
    refer_code: null,
};

export const initialState = localStorage.getItem("calcusoAIShare")
    ? JSON.parse(localStorage.getItem("calcusoAIShare"))
    : state;

export const setCalcusoAIShare = (store, calcusoAIShare) => {
    store.setState(
        {
            calcusoAIShare: {
                ...store.state.calcusoAIShare,
                ...calcusoAIShare,
            },
        },
        () =>
            localStorage.setItem("calcusoAIShare", JSON.stringify(store.state.calcusoAIShare))
    );
};

export const initCalcusoAIShare = (store) => {
    store.setState(
        {
            calcusoAIShare: {
                uuid: null,
                refer_code: null,
            },
        },
        () =>
            localStorage.setItem("calcusoAIShare", JSON.stringify(store.state.calcusoAIShare))
    );
};