import React, {useEffect, useState} from 'react';
import AppLayout from "../../../components/AppLayout";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import Loading from "../../../components/Loading";
import {Helmet} from "react-helmet";
import ScrollToFieldError from "../../../components/ScrollToFieldError";
import Input from "../../../components/Forms/Input";
import {Formik} from "formik";
import HeaderAI from "../../../components/AI/Header/Header";
import * as Yup from "yup";
import {OPTIONAL_ZIP_COUNTRIES, POSTCODE_REGEX, REGEX_NAME, REGEX_STREET} from "../../../constants/common";
import ShipForm from "./ShipForm";
import CheckoutSteps from "../../../components/CheckoutSteps";
import api from "../../../services/api";
import history from "../../../routes/history";
import {HandleAPICartError} from "../../../helpers";
import {STEP_CHECKOUT_ADDRESS} from "../../../constants/checkoutSteps";
import SchoolInfo from "../../../components/AI/SchoolInfo/SchoolInfo";
import mixpanel from "mixpanel-browser";

const EMAIL_REGX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$|^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[^@\s]+\.[^@\s]+$/;

export default function AddressScreen() {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [isLoading, setIsLoading] = useState(true);
    const {checkout} = globalState;
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);

    useEffect(() => {
        const calcusoAI = globalState.calcusoAI;
        const calcusoAIShare = globalState.calcusoAIShare;
        mixpanel.track_pageview({
            "page": "address",
            "uuid": calcusoAI.uuid ?? null,
            "refer_code": calcusoAIShare.refer_code ?? null
        })

        globalActions.setCheckout({
            currentCheckoutStep: STEP_CHECKOUT_ADDRESS
        });
        setIsLoading(false);
    }, []);

    return (
        <AppLayout
            contentBackgroundColor="bg-cal-primary-celadon-green-E5F8F7"
            textColor="text-gunmetal-black"
            border={false}
        >
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Helmet>
                        <title>
                            {t("Address")}
                        </title>
                    </Helmet>
                    <div className="flex flex-col h-full min-h-screen">
                        <HeaderAI confirm={true} title={('CALCUSO AI')} subTitle={t('Easy school order')}>
                            <CheckoutSteps/>
                        </HeaderAI>
                        <div className="flex flex-col justify-between flex-1 h-full">
                            <div className="flex justify-center flex-1 pb-4">
                                <Formik
                                    enableReinitialize
                                    initialValues={{
                                        email: checkout?.email,
                                        is_same_shipping: checkout?.is_same_shipping,
                                        shipping: checkout?.shipping,
                                        billing: checkout?.billing,
                                        school_info: checkout?.school_info,
                                    }}
                                    validationSchema={Yup.lazy((values) => {
                                        // Check Ship To School
                                        let shippingValidationSchema;
                                        const postcodeShippingRegex =
                                            POSTCODE_REGEX[values.shipping.country_id];
                                        let postcodeShippingSchema = Yup.string();
                                        if (postcodeShippingRegex) {
                                            const combineShippingRegex = Object.keys(
                                                postcodeShippingRegex
                                            )
                                                .map((key) => {
                                                    return postcodeShippingRegex[key].pattern;
                                                })
                                                .join("|");
                                            let regexShipping = new RegExp(
                                                combineShippingRegex
                                            );
                                            postcodeShippingSchema = postcodeShippingSchema.matches(
                                                regexShipping,
                                                t("The postcode entered is incorrect.")
                                            );
                                        }
                                        shippingValidationSchema = Yup.object().shape({
                                            firstname: Yup.string()
                                                .required(t("This is a mandatory field"))
                                                .matches(REGEX_NAME, t("The name must not contain any special characters."))
                                            ,
                                            lastname: Yup.string()
                                                .required(t("This is a mandatory field"))
                                                .matches(REGEX_NAME, t("The name must not contain any special characters."))
                                            ,
                                            street: Yup.string()
                                                .required(t("This is a mandatory field"))
                                                .matches(
                                                    REGEX_STREET,
                                                    t("Please enter street and house number.")
                                                ),
                                            city: Yup.string().required(
                                                t("This is a mandatory field")
                                            ),
                                            postcode: !OPTIONAL_ZIP_COUNTRIES.includes(
                                                values.shipping.country_id
                                            )
                                                ? postcodeShippingSchema.required(
                                                    t("This is a mandatory field")
                                                )
                                                : postcodeShippingSchema,
                                            country_id: Yup.string().required(
                                                t("This is a mandatory field")
                                            ),
                                        });
                                        // Billing
                                        const postcodeBillingRegex =
                                            POSTCODE_REGEX[values.billing.country_id];
                                        let postcodeBillingSchema = Yup.string();
                                        if (postcodeBillingRegex) {
                                            const combineBillingRegex = Object.keys(
                                                postcodeBillingRegex
                                            )
                                                .map((key) => {
                                                    return postcodeBillingRegex[key].pattern;
                                                })
                                                .join("|");
                                            let regexBilling = new RegExp(combineBillingRegex);
                                            postcodeBillingSchema = postcodeBillingSchema.matches(
                                                regexBilling,
                                                t("The postcode entered is incorrect.")
                                            );
                                        }
                                        const billingValidationSchema = Yup.object().shape({
                                            firstname: Yup.string()
                                                .required(t("This is a mandatory field"))
                                                .matches(REGEX_NAME, t("The name must not contain any special characters.")),
                                            lastname: Yup.string()
                                                .required(t("This is a mandatory field"))
                                                .matches(REGEX_NAME, t("The name must not contain any special characters.")),
                                            street: Yup.string()
                                                .required(t("This is a mandatory field"))
                                                .matches(
                                                    REGEX_STREET,
                                                    t("Please enter street and house number.")
                                                ),
                                            city: Yup.string().required(
                                                t("This is a mandatory field")
                                            ),
                                            postcode: !OPTIONAL_ZIP_COUNTRIES.includes(
                                                values.billing.country_id
                                            )
                                                ? postcodeBillingSchema.required(
                                                    t("This is a mandatory field")
                                                )
                                                : postcodeBillingSchema,
                                            country_id: Yup.string().required(
                                                t("This is a mandatory field")
                                            ),
                                        });

                                        return Yup.object().shape({
                                            email: Yup.string()
                                                .email(t("The email must be a valid email."))
                                                .matches(EMAIL_REGX, t("The email must be a valid email."))
                                                .required(t("This is a mandatory field"))
                                            ,
                                            shipping: shippingValidationSchema,
                                            billing: billingValidationSchema,
                                        });
                                    })}
                                    onSubmit={async (values) => {
                                        setIsSubmitLoading(true);
                                        try {
                                            const {checkout} = globalState;
                                            const {
                                                quote_id,
                                                shippingMethodCode,
                                                shippingCarrierCode,
                                            } = checkout;
                                            const {shipping, billing} = values;

                                            const payload = {
                                                addressInformation: {
                                                    shipping_address: {
                                                        ...shipping,
                                                        street: [shipping.street],
                                                    },
                                                    billing_address: {
                                                        ...billing,
                                                        street: [billing.street],
                                                    },
                                                    shipping_method_code: shippingMethodCode,
                                                    shipping_carrier_code: shippingCarrierCode,
                                                },
                                            };
                                            // Load async data.
                                            let paymentData = await api.post(
                                                `/guest-carts/${quote_id}/shipping-information`,
                                                {},
                                                payload
                                            );

                                            const {data} = paymentData;
                                            const ayden = await globalActions.loadPaymentMethodsAdyen(globalState);
                                            globalActions.setCheckout({
                                                ...values,
                                                payment_methods: data["payment_methods"],
                                                payment_methods_adyen: ayden,
                                            });

                                            let adyenConfig = await api.post(`/calcuso-core-order/config`);
                                            globalActions.setAdyenConfig(adyenConfig.data);

                                            history.push("/checkout/payment");
                                        } catch (error) {
                                            HandleAPICartError(error, globalState);
                                        }
                                        setIsSubmitLoading(false);
                                    }}
                                    validateOnChange
                                >
                                    {({
                                          errors,
                                          touched,
                                          handleSubmit,
                                          values,
                                          handleChange,
                                          handleBlur,
                                          isValid,
                                          setValues,
                                      }) => (
                                        <form
                                            onSubmit={handleSubmit}
                                            className="w-full px-2 md:px-4 lg:w-2/3 xl:w-1/2 lg:px-8"
                                        >
                                            <ScrollToFieldError/>
                                            <div className="px-4 pt-6 md:px-6 lg:px-8 lg:pt-12">
                                                <div className="flex flex-col space-y-4 ">
                                                    <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                                                        {t("Your personal information")}
                                                    </h3>
                                                    <Input
                                                        name="email"
                                                        label={t("Email")}
                                                        placeholder={t("example@mail.com")}
                                                        size="lg"
                                                        onChange={e => {
                                                            handleChange(e);
                                                            setValues(
                                                                (prevValues) => ({
                                                                    ...prevValues,
                                                                    email: e.target.value.trim(),
                                                                }),
                                                                true
                                                            )
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.email}
                                                        required
                                                        error={touched.email && errors.email}
                                                        errorMessage={errors.email}
                                                    />
                                                    <div class="border-t-2 border-celadon-green-light-500 pt-4">
                                                        <h3 className="text-xl font-medium tracking-wide text-gunmetal-black-500">
                                                            {t("Your school")}
                                                        </h3>
                                                        <SchoolInfo values={values}/>
                                                    </div>

                                                    <ShipForm
                                                        {...{
                                                            errors,
                                                            touched,
                                                            values,
                                                            handleChange,
                                                            handleBlur,
                                                            isValid,
                                                            setValues,
                                                            isSubmitLoading,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </AppLayout>
    )
};
