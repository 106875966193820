import * as Sentry from "@sentry/react";
import {ATTRIBUTE_CODE_COLOR, IS_GRAVIUR} from "../constants/common";
import BigNumber from "bignumber.js";
import history from "../routes/history";


export const GetFinalPrice = (item, values, selectedItem, force = true) => {
    if (item.product_type === 'simple') {
        const price = new BigNumber(item.product_price);
        return price.toFormat(2).toString();
    }

    const {product_simples} = item;
    // If Product In Cart
    if (selectedItem && !force) {
        return selectedItem.price;
    }

    const product = product_simples.find((product_simple) => {
        let find = true;
        for (
            let index = 0;
            index < product_simple.product_custom_attributes.length;
            index++
        ) {
            const element = product_simple.product_custom_attributes[index];
            if (element.value !== values[element.attribute_code]) {
                return false;
            }
        }

        return find;
    });

    if (!product) {
        Sentry.setTag("TYPE", "PRODUCT_NOT_FOUND");
        Sentry.captureException(new Error("PRODUCT_NOT_FOUND"));
        return;
    }

    if (values.is_graviur === IS_GRAVIUR) {
        // Gravur
        const gravurOption = item.product_custom_options.find(
            (custom_option) => custom_option.type === "field"
        );
        const price = new BigNumber(product.product_price).plus(
            new BigNumber(gravurOption.price)
        );
        return price.toFormat(2).toString();
    }

    const price = new BigNumber(product.product_price);
    return price.toFormat(2).toString();
};


export const GetLowestPrice = (item) => {
    const {product_simples} = item;

    return product_simples.reduce(function (a, b) {
        return a.product_price <= b.product_price ? a : b;
    }, {});
}

export const GetLowestColor = (item) => {
    const lowestPrice = GetLowestPrice(item);

    return lowestPrice.product_custom_attributes.find(
        (product_custom_attribute) =>
            product_custom_attribute.attribute_code === ATTRIBUTE_CODE_COLOR
    );
}

export const InitCartFormValue = (item, selectedItem) => {
    const {configurable_product_options} = item;

    const lowestPrice = GetLowestPrice(item);

    return configurable_product_options.reduce((obj, item) => {
        let lowestAttribute = lowestPrice.product_custom_attributes.find(
            (product_custom_attribute) =>
                product_custom_attribute.attribute_code === item.attribute_code
        );
        return Object.assign(obj, {
            [item.attribute_code]: selectedItem?.options[item.attribute_code]
                ? selectedItem?.options[item.attribute_code]
                : lowestAttribute?.value,
        });
    }, {})
}

export const HandleSuccessOrder = (globalActions) => {
    globalActions.initCheckout();
    globalActions.initCalcusoAI();
    globalActions.initFakeCart();

    history.push(`/checkout/share`);
}

export const IsAddItemToCart = (itemId, cartItem) => {
    let inCart = false;
    let cartValues = Object.values(cartItem);

    cartValues.map((item) => {
        if (item.id === itemId) {
            inCart = true;
        }
    });

    return inCart;
}

export

const findReferCode = function (url) {
    // Some social media platforms encode the URL, so we need to decode it first
    let decoded = decodeURIComponent(url);
    const regex = /refer_code=([A-Za-z0-9]+)/;
    const match = decoded.match(regex);

    if (match) {
        return match[1];
    }
}