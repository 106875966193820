import React, {useEffect, useState} from 'react';
import CHECK_GIF from "../../../images/calcuso-ai/IMG_0975.gif";
import LOADING from "../../../images/calcuso-ai/IMG_0976.gif";
import DONE_GIF from "../../../images/calcuso-ai/IMG_0978.gif";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";
import classNames from "classnames";
import Step from "../Waiting/Step";
import Email from "./Email";

export default function Waiting({openEmailModal, setOpenEmailModal}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const [step, setStep] = useState(globalState.calcusoAI.step);

    useEffect(() => {
        setStep(globalState.calcusoAI.step);
    }, [globalState.calcusoAI.step])

    return (
        <div className="flex justify-center items-center flex-1 h-full">
            <div className="mx-auto w-full md:w-1/2 lg:w-1/4 px-8 space-x-2">
                <div className="relative">
                    <div className={classNames({
                        'absolute text-center w-full space-y-4': true,
                        'transition-opacity duration-500 opacity-0': step === 'text_extract' || step === 'product_tag_detect',
                        'transition-opacity duration-500 opacity-100': step !== 'text_extract' && step !== 'product_tag_detect'
                    })}>
                        <img src={CHECK_GIF} className="w-28 mx-auto"/>
                        <div className="text-4xl font-bold text-cal-primary-celadon-green-01A7A3">
                            {t("Let's go!")}
                        </div>
                        <div className="text-xl font-medium">
                            {t('We are looking for your products. This will take about a minute.')} 😉
                        </div>
                    </div>
                    <div className={classNames({
                        'absolute text-center w-full space-y-4': true,
                        'transition-opacity duration-500 opacity-0': step !== 'text_extract',
                        'transition-opacity duration-500 opacity-100': step === 'text_extract',
                    })}>
                        <img src={LOADING} className="w-32 mx-auto"/>
                        <div className="text-4xl font-bold text-cal-primary-celadon-green-01A7A3">
                            {t('Almost ready!')}
                        </div>
                        <div className="text-xl font-medium text-lg">
                            {t('Just a moment more. We are looking for the last products.')} 😉
                        </div>
                    </div>
                    <div className={classNames({
                        'absolute text-center w-full space-y-4': true,
                        'transition-opacity duration-500 opacity-0': step !== 'product_tag_detect',
                        'transition-opacity duration-500 opacity-100': step === 'product_tag_detect',
                    })}>
                        <img src={DONE_GIF} className="w-20 mx-auto"/>
                        <div className="text-4xl font-bold text-cal-primary-celadon-green-01A7A3">
                            {t('Done!')}
                        </div>
                        <div className="text-xl font-medium text-lg">
                            {t("Your products are now ready. We'll forward them to you immediately.")} 😙
                        </div>
                    </div>
                    <div className="pt-72 w-48 mx-auto">
                        <Step/>
                    </div>
                </div>
            </div>
            <Email openEmailModal={openEmailModal} setOpenEmailModal={setOpenEmailModal} />
        </div>
    )
}
