import axios from "axios";
import qs from "qs";
import Config from "../config";

const instanceCRM = axios.create({
    baseURL: Config.apiCRM,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    transformResponse: (data) => (data ? JSON.parse(data) : undefined),
    paramsSerializer(params) {
        return qs.stringify(params, {arrayFormat: "brackets"});
    },
});

const METHODS = ["get", "put", "post", "patch", "delete", "head", "options"];

const httpMethodCRM = METHODS.map(
    (method) => async (url, headers = {}, data, params) => {
        // const token = localStorage.getItem("token");

        return instanceCRM.request({
            url: '/api' + url,
            method,
            //   headers: { Authorization: `Bearer ${token}`, ...headers },
            headers,
            data,
            params,
        });
    }
);

export const [get, put, post, patch, del, head, options] = httpMethodCRM;

const apiAdyen = {
    get,
    put,
    post,
    patch,
    del,
    head,
    options,
};

export default apiAdyen;
