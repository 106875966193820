import "./App.css";
import {connect} from "./state/useGlobal";

import React, {PureComponent, Suspense} from "react";
import Routes from "./routes";
import GlobalLoading from "./components/GlobalLoading";
import {HashRouter as Router} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {install} from 'resize-observer';

import mixpanel from 'mixpanel-browser';
import Config from "./config";
mixpanel.init(Config.mixpanel, {debug: true, track_pageview: true, persistence: 'localStorage'});

// Polyfill for ResizeObserver on old Safari
if (!window.ResizeObserver) install();

class App extends PureComponent {
    render() {
        return (
            <Router>
                <Suspense fallback={<GlobalLoading/>}>
                    <Routes/>
                    <Toaster position="bottom-center" reverseOrder={false}/>
                </Suspense>
            </Router>
        );
    }
}

export default connect(App);
