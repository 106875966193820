const state = {
    school_id: null,
    shipping_methods: null,
    is_teacher: false,
};

export const initialState = localStorage.getItem("teacherEdition")
    ? JSON.parse(localStorage.getItem("teacherEdition"))
    : state;

export const setTeacherEdition = (store, teacherEdition) => {
    store.setState(
        {
            teacherEdition: {
                ...store.state.teacherEdition,
                ...teacherEdition,
            },
        },
        () =>
            localStorage.setItem("teacherEdition", JSON.stringify(store.state.teacherEdition))
    );
};

export const initTeacherEdition = (store) => {
    store.setState(
        {
            teacherEdition: {
                school_id: null,
                shipping_methods: 'toSchool',
                class_list: [],
                deadline: null,
                number_of_students: null,
                group_order_data: null
            },
        },
        () =>
            localStorage.setItem("teacherEdition", JSON.stringify(store.state.teacherEdition))
    );
};

export const setTeacherState = (store, teacherEdition) => {
    store.setState(
        {
            teacherEdition: {
                ...store.state.teacherEdition,
                ...teacherEdition,
            },
        },
    );
};