const state = {
    previousParentLetter: []
};
export const initialState = localStorage.getItem("oldData")
    ? JSON.parse(localStorage.getItem("oldData"))
    : state;

export const setOldData = (store, oldData) => {
    store.setState(
        {
            oldData: {
                ...store.state.oldData,
                ...oldData,
            },
        },
        () =>
            localStorage.setItem("oldData", JSON.stringify(store.state.oldData))
    );
};

export const initOldData = (store) => {
    store.setState(
        {
            oldData: {
                previousParentLetter: [],
            },
        },
        () =>
            localStorage.setItem("oldData", JSON.stringify(store.state.oldData))
    );
};

export const addPreviousUuid = (store, uuid, imageUrl) => {
    // Check if the uuid already exists in the array
    const exists = store.state.oldData.previousParentLetter.some((item) => item.uuid === uuid);
    if (exists) return;

    let newPreviousParentLetter = [...store.state.oldData.previousParentLetter, {uuid, imageUrl}];

    store.setState(
        {
            oldData: {
                ...store.state.oldData,
                ...{
                    previousParentLetter: newPreviousParentLetter
                }
            },
        },
        () =>
            localStorage.setItem("oldData", JSON.stringify(store.state.oldData))
    );
};

export const getPreviousUuid = (store) => {
    // Get last 2 uuids
    return store.state.oldData.previousParentLetter.slice(-2);
}
