import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import ScrollToTop from "./ScrollToTop";
import {GA4R, GA4React} from "ga-4-react";
import config from "../config";
import WelcomeScreen from "../screens/AI/WelcomeScreen";
import UploadingScreen from "../screens/AI/UploadingScreen";
import ProductScreen from "../screens/AI/ProductScreen";
import CartScreen from "../screens/AI/CartScreen";
import TeacherScreen from "../screens/AI/TeacherScreen";
import ShareScreen from "../screens/AI/ShareScreen";
import AddressScreen from "../screens/AI/AddressScreen";
import PaymentScreen from "../screens/AI/PaymentScreen/PaymentScreen";
import WelcomeShareScreen from "../screens/AI/WelcomeShareScreen";
import ErrorScreen from "../screens/AI/ErrorScreen";
import WelcomeTeacherScreen from "../screens/AI/WelcomeTeacherScreen";
import ThankYouScreen from "../screens/AI/ThankYouScreen";

const screens = [
    {
        path: "/thank-you",
        isPublicRoute: true,
        component: ThankYouScreen,
    },
    {
        path: "/share/:id",
        isPublicRoute: true,
        component: WelcomeShareScreen,
    },
    {
        path: "/checkout/error",
        isPublicRoute: true,
        component: ErrorScreen,
    },
    {
        path: "/upload",
        isPublicRoute: true,
        component: UploadingScreen,
    },
    {
        path: "/product",
        isPublicRoute: true,
        component: ProductScreen,
    },
    {
        path: "/cart",
        isPublicRoute: true,
        component: CartScreen,
    },
    {
        path: "/teacher/config",
        isPublicRoute: true,
        component: TeacherScreen,
    },
    {
        path: "/teacher",
        isPublicRoute: true,
        component: WelcomeTeacherScreen,
    },
    {
        path: "/checkout/share",
        isPublicRoute: true,
        component: ShareScreen,
    },
    {
        path: "/checkout/address",
        isPublicRoute: true,
        component: AddressScreen,
    },
    {
        path: "/checkout/payment",
        isPublicRoute: true,
        component: PaymentScreen,
    },
    {
        path: "/",
        isPublicRoute: true,
        component: WelcomeScreen,
    },
];

export default function Routes() {
    const [ga, setGa] = useState(false);

    useEffect(() => {
        try {
            const ga4react = new GA4React(config.gt4);
            ga4react.initialize().then((ga4) => {
                setGa(true);
            }).catch((err) => {
                setGa(false);
            });
        } catch (e) {
            setGa(false);
        }
    }, []);

    console.info('ga', ga);

    return (
        <ScrollToTop>
            <Switch>
                {screens.map((screen) => {
                    if (screen.isPublicRoute) {
                        const PublicPage = screen.component;
                        return (
                            <Route key={screen.path} path={screen.path}>
                                {ga ? <GA4R code={config.gt4} config={{send_page_view: false}}><PublicPage/></GA4R> :
                                    <PublicPage/>}
                            </Route>
                        );
                    } else {
                        const ProtectedPage = screen.component;
                        return (
                            <PrivateRoute key={screen.path} path={screen.path}>
                                {ga ? <GA4R code={config.gt4} config={{send_page_view: false}}><ProtectedPage/></GA4R> :
                                    <ProtectedPage/>}
                            </PrivateRoute>
                        );
                    }
                })}
            </Switch>
        </ScrollToTop>
    );
}
