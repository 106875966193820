import React from "react";
import SecondaryButton from "../../SecondaryButton";
import {useTranslation} from "react-i18next";
import useGlobal from "../../../state/useGlobal";

export default function ButtonAddToGroupOrder({item, aiItem}) {
    const {t} = useTranslation();
    const [globalState, globalActions] = useGlobal();
    const {fakeCart} = globalState;
    const {items} = fakeCart;
    const selectedItem = items[item?.product_id];
    const handleAddToFakeGo = () => {
        const payload = {
            id: item.product_id,
            name: item.name,
            sku: item.product_sku,
            type: item.product_type,
            price: item.product_price,
            qty: 1,
            product: item,
            options: {},
            created_at: new Date().getTime(),
        };
        globalActions.addToCart(payload);
        globalActions.setIsDisplay(aiItem?.item_id, false);
    };

    const handleRemoveToFakeGo = () => {
        globalActions.minusFromCart(selectedItem);
        globalActions.setIsDisplay(aiItem?.item_id, true);
    };

    return (
        <>
            {
                selectedItem ? (
                    <SecondaryButton
                        type="button"
                        color="w-40 flex justify-center text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500"
                        size="base"
                        onClick={handleRemoveToFakeGo}
                    >
                        <span>{t("remove")}</span>
                    </SecondaryButton>
                ) : (
                    <SecondaryButton
                        type="button"
                        color="w-40 flex justify-center text-celadon-green-700 bg-transparent border-celadon-green-300 hover:border-celadon-green-500 hover:text-celadon-green-900 focus:ring-celadon-green-500"
                        size="base"
                        onClick={handleAddToFakeGo}
                    >
                        <span>{t("add")}</span>
                    </SecondaryButton>
                )
            }
        </>
    );
}
